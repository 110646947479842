import { FC, useMemo } from 'react'
// components
import { FormattedText, Loader, Typography } from 'src/modules/core/components'
import { CalendarList } from 'src/modules/calendar/components'
// hooks
import { useCalendars } from 'src/modules/calendar/hooks/use-calendars'
import { usePlanData } from 'src/modules/payment/hooks/use-plan-data'
import { useProfile } from 'src/modules/user/hooks/use-profile'
// utils
import { getCalendarsLimitType } from 'src/modules/payment/utils/helpers'

import classes from './calendars-tab.module.scss'

export const CalendarsTab: FC = () => {
  // hooks
  const calendars = useCalendars({}, { sync: true })
  const profile = useProfile()
  const subscriptionOptions = {
    enabled: !!profile.data?.billing_subscription_id,
  }
  const { currentPlan } = usePlanData({
    subscriptionOptions,
  })

  // memo
  const isLoading = useMemo(
    () => calendars.isLoading || profile.isLoading,
    [calendars.isLoading, profile.isLoading],
  )

  const calendarsLimitType = getCalendarsLimitType(currentPlan)

  if (isLoading) return <Loader isLoading={isLoading} />

  return (
    <div className={classes.root}>
      <Typography className={classes.title} variant="body">
        <FormattedText id="calendars.title" />
      </Typography>
      <Typography className={classes.description} variant="body">
        <FormattedText
          id="calendars.description"
          values={{
            strong: (chunks) => <strong>{chunks}</strong>,
            limitType: calendarsLimitType,
            limit: currentPlan?.calendars_limit,
            isNew: false,
          }}
        />
      </Typography>
      <div className={classes.listWrapper}>
        <CalendarList itemClassName={classes.itemLabel} />
      </div>
    </div>
  )
}
