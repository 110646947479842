import { useQuery } from 'react-query'
import { useHandleRetry } from 'src/modules/core/hooks/use-handle-retry'
import { ApiService } from 'src/services/api.service'

import type { IDataOptions } from '@grouse-software/types/common'

export const GET_CALENDAR_BY_ID_CACHE_KEY = 'get-calendar-by-id'

export function useCalendarById(id: string, params: IDataOptions = {}) {
  const handleRetry = useHandleRetry()

  return useQuery(
    [GET_CALENDAR_BY_ID_CACHE_KEY, id, params],
    async () => ApiService.Calendars.getCalendarById(id, params),
    {
      enabled: !!id,
      staleTime: 300000,
      retry: handleRetry,
      cacheTime: 0,
    },
  )
}
