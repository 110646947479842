import { useProfile } from '../../hooks/use-profile'
import { ConfigurationFormCustomerMode } from './configuration-form-customer-mode'
import { ConfigurationFormPersonalMode } from './configuration-form-personal-mode'

export function ConfigurationForm() {
  const { isLoading, data } = useProfile()

  if (isLoading) return null

  return data.usage_mode === 'personal' ? <ConfigurationFormPersonalMode /> : <ConfigurationFormCustomerMode />
}
