import classNames from 'classnames'
import { addMinutes, format } from 'date-fns'
import Tooltip from 'rc-tooltip'
import { FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
// components
import { Error, FormattedText, Icon, Typography } from 'src/modules/core/components'
// constants
import { routeNames } from 'src/modules/core/constants/routeNames'
// utils
import { addEllipsisToText, addSuffixToNumber } from 'src/modules/core/utils/helpers'
// types
import { SubscriptionStatus } from 'src/modules/payment/types/payment.types'
import { formatSubscriptionCancellationDate } from 'src/modules/payment/utils/helpers'
import classes from './dashboard-wrapper.module.scss'

import type { PaymentWidgetProps } from './dashboard-wrapper.interface'

const symbolsLimit = 5
const offsetRight = 0
const offsetBottom = -20

export const PaymentWidget: FC<PaymentWidgetProps> = ({ profile, subscription, currentPlan, isRefetching }) => {
  // hooks
  const navigate = useNavigate()
  // state
  const [error, setError] = useState<ReactNode>(null)
  // memo
  const nextPaymentDate = useMemo(() => new Date(subscription?.next_payment?.date), [subscription?.next_payment?.date])

  const subscriptionCancellationDate = useMemo(
    () => formatSubscriptionCancellationDate(profile.billing_cancellation_effective_date),
    [profile.billing_cancellation_effective_date],
  )

  useEffect(() => {
    const limitReached = profile.reminders_sent_during_billing_cycle === currentPlan?.reminders_limit
    const overLimit =
      profile.reminders_sent_during_billing_cycle + profile.include.count_scheduled_reminders >
      currentPlan?.reminders_limit

    if (subscription?.state === SubscriptionStatus.past_due) {
      return setError(
        <FormattedText
          id="payment.error.status"
          values={{
            strong: (chunks) => <strong>{chunks}</strong>,
            nextBillDate: subscription?.next_payment?.date,
          }}
        />,
      )
    }

    if (limitReached) {
      return setError(
        <FormattedText id="payment.error.limitReached" values={{ strong: (chunks) => <strong>{chunks}</strong> }} />,
      )
    }

    if (overLimit) {
      return setError(
        <FormattedText id="payment.error.overLimit" values={{ strong: (chunks) => <strong>{chunks}</strong> }} />,
      )
    }

    setError((prev) => {
      if (prev) return null
    })
  }, [
    currentPlan?.reminders_limit,
    profile.include,
    profile.reminders_sent_during_billing_cycle,
    subscription?.last_payment,
    subscription?.next_payment?.date,
    subscription?.state,
  ])

  const handleChangeSubscription = useCallback(() => {
    navigate(`${routeNames.settings.path}?tab=2`)
  }, [navigate])

  const getTooltipContainer = () =>
    !isRefetching && profile.include.count_scheduled_reminders?.toString().length > symbolsLimit ? document.body : null

  const showDivider = subscription?.next_payment || profile?.billing_cancellation_effective_date

  return (
    <div className={classes.mainWidget}>
      <Typography variant="h2" className={classes.widgetTitle}>
        {currentPlan?.name}
      </Typography>
      <div className={classes.info}>
        <div className={classes.item}>
          <div>
            <Typography variant="span" className={classNames(classes.text, classes.grayText)}>
              <FormattedText id="payment.remindersSent" />
            </Typography>
            <Typography variant="body" className={classes.boldText}>
              {profile.reminders_sent_during_billing_cycle}/{currentPlan?.reminders_limit}
            </Typography>
          </div>
          <div>
            <Typography variant="span" className={classNames(classes.text, classes.grayText)}>
              <FormattedText id="payment.scheduled" />
            </Typography>
            <Tooltip
              placement="top"
              mouseEnterDelay={0.1}
              transitionName="example"
              align={{ offset: [offsetRight, offsetBottom] }}
              overlayClassName={classes.tooltipOverlay}
              getTooltipContainer={getTooltipContainer}
              overlay={
                <Typography variant="body" className={classNames(classes.boldText, classes.tooltipText)}>
                  {profile.include.count_scheduled_reminders}
                </Typography>
              }
            >
              {isRefetching ? (
                <div>
                  <div className={classes.spinnerWrapper}>
                    <div className={classes.spinner}>
                      <div />
                    </div>
                  </div>
                </div>
              ) : (
                <Typography variant="body" className={classes.boldText}>
                  {addEllipsisToText(profile.include.count_scheduled_reminders?.toString(), symbolsLimit)}
                </Typography>
              )}
            </Tooltip>
          </div>
        </div>
        {showDivider && <div className={classes.divider} />}
        {subscription?.next_payment && (
          <div>
            <Typography variant="span" className={classNames(classes.text, classes.grayText)}>
              <FormattedText id="payment.subscriptionRenews" />
            </Typography>
            <Typography data-testid="payment-widget.next-payment.text" variant="body" className={classes.boldText}>
              {format(addMinutes(nextPaymentDate, nextPaymentDate.getTimezoneOffset()), 'MMMM d')}
              {addSuffixToNumber(nextPaymentDate.getUTCDate())}
              {format(nextPaymentDate, ', yyyy')}
            </Typography>
          </div>
        )}
        {profile.billing_cancellation_effective_date && (
          <div>
            <Typography variant="span" className={classNames(classes.text, classes.grayText)}>
              <FormattedText id="payment.subscriptionCancels" />
            </Typography>
            <Typography variant="body" className={classes.boldText}>
              {format(subscriptionCancellationDate, 'MMMM d')}
              {addSuffixToNumber(subscriptionCancellationDate.getDate())}
              {format(subscriptionCancellationDate, ', yyyy')}
            </Typography>
          </div>
        )}
      </div>
      {error && <Error text={error} />}
      <Typography variant="span" className={classes.button} onClick={handleChangeSubscription}>
        <FormattedText id={'payment.button.changeSubscription'} />
        <Icon name="arrowRightSmall" />
      </Typography>
    </div>
  )
}
