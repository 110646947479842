import classNames from 'classnames'
import { addDays, addMilliseconds, format } from 'date-fns'
import { FC, useCallback, useMemo, useState } from 'react'
import { ErrorModal, FormattedText, Icon, Loader, Typography } from 'src/modules/core/components'
import { EventCard } from 'src/modules/event/components'
import { groupEventsByDate } from 'src/modules/event/utils/helpers'
import { useProfile } from 'src/modules/user/hooks/use-profile'
import classes from './reminder-list.module.scss'

import type { ErrorMessage } from 'src/modules/core/types/error.types'
import type { EventPreviewWithReminders } from 'src/modules/event/types/event.types'

import type { EventListProps } from './reminder-list.interface'
export const numberPerPage = 5

export const ReminderList: FC<EventListProps> = ({ events, changeSelectedDate, isLoading }) => {
  const [selectedDate, setSelectedDate] = useState<Date>(new Date())
  const [errorMessage, setErrorMessage] = useState<ErrorMessage>(null)

  const profile = useProfile()

  const reminders = useMemo(() => {
    return listPastRemindersForEvents(events)
  }, [events])

  const filteredEvents = useMemo(() => {
    return groupEventsByDate(reminders, addDays(selectedDate, 0), addDays(selectedDate, numberPerPage))
  }, [reminders, selectedDate])

  const onDateChange = useCallback(
    (number: number) => () => {
      setSelectedDate((prevState) => addDays(prevState, number))
      changeSelectedDate && changeSelectedDate((prevState) => addDays(prevState, number))
    },
    [changeSelectedDate],
  )

  return (
    <div className={classes.root}>
      <div className={classes.heading}>
        <div className={classes.arrowWrapper} onClick={onDateChange(-numberPerPage)}>
          <Icon name="arrowLeftRounded" className={classes.arrow} />
        </div>
        <Typography variant="h2" className={classes.month}>
          {format(selectedDate, 'MMMM uuuu')}
        </Typography>
        <div className={classes.arrowWrapper} onClick={onDateChange(numberPerPage)}>
          <Icon name="arrowLeftRounded" className={classNames(classes.arrow, classes.arrowRight)} />
        </div>
      </div>
      <div className={classes.remindersList}>
        <Loader className={classes.loader} isLoading={isLoading} />
        {!isLoading &&
          filteredEvents.map((dayEvents) => (
            <div key={dayEvents.date} className={classes.remindersListItem}>
              <Typography className={classes.dayTitle} variant="body">
                {dayEvents.date}
              </Typography>
              <div>
                {dayEvents.events.map((event) => (
                  <div key={`${event.id}-${event.event_date}`} className={classes.reminder}>
                    <EventCard
                      showBadge={profile.data?.appointment_confirmation}
                      showSwitch
                      event={event}
                      eventRepresentsReminder={true}
                      variant="finished"
                    />
                  </div>
                ))}
                {dayEvents.events.length === 0 && (
                  <Typography variant="body" className={classes.textEmpty}>
                    <FormattedText id="reminder.text.empty" />
                  </Typography>
                )}
              </div>
            </div>
          ))}
      </div>
      {errorMessage && (
        <ErrorModal
          title={
            <Typography variant="body" className={classes.errorTitle}>
              {errorMessage.title}
            </Typography>
          }
          description={
            <Typography variant="span" className={classes.errorDescription}>
              {errorMessage.description}
            </Typography>
          }
          onClose={() => setErrorMessage(null)}
        />
      )}
    </div>
  )
}

function listPastRemindersForEvents(events: EventPreviewWithReminders[]) {
  const sentReminders: EventPreviewWithReminders[] = []

  events?.forEach((event) => {
    event.reminders.forEach((timeBefore) => {
      const reminderTime = addMilliseconds(new Date(parseInt(event.event_date, 10)), -timeBefore.time_before).getTime()

      // Discard future reminders
      if (reminderTime > new Date().getTime()) {
        return
      }

      sentReminders.push({
        ...event,
        id: `${event.id}-${timeBefore.time_before}`,
        event_date: reminderTime.toString(),
      })
    })
  })

  return sentReminders
}
