/* eslint-disable no-magic-numbers */
// constants
import { millisecondsInDay } from 'src/modules/core/constants/time.constants'

export const changeArrayElementValues = <T extends {}>(
  id: string,
  getData: (el: T) => T,
) => {
  return (prev) => prev?.map((el) => (el.id === id ? getData(el) : el))
}

export const findDuplicates = <T extends any>(arr: T[]): T[] => {
  return arr.filter((item, index) => arr.indexOf(item) !== index)
}

export const removeItemFromArray = <T>(arr: Array<T>, value: T): Array<T> => {
  const index = arr.indexOf(value)

  if (index > -1) {
    arr.splice(index, 1)
  }

  return arr
}

export const daysToMilliseconds = (days: number): number => {
  return days * millisecondsInDay
}

export const addSuffixToNumber = (n: number): string => {
  if (n > 3 && n < 21) return 'th'
  switch (n % 10) {
    case 1:
      return 'st'
    case 2:
      return 'nd'
    case 3:
      return 'rd'
    default:
      return 'th'
  }
}

export const roundNumber = (num: number, dec: number): number =>
  Math.round(num * 10 ** dec) / 10 ** dec

export const isBoolean = <T extends any>(value: T): boolean =>
  typeof value === 'boolean'

export const isString = <T extends any>(value: T): boolean =>
  typeof value === 'string'

export const addEllipsisToText = (text: string, limit: number): string =>
  text.length > limit ? `${text.substring(0, limit)}...` : text
