import classNames from 'classnames'
import { useCallback, useContext, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSynchronizeCalendars } from 'src/modules/calendar/hooks/use-synchronize-calendars'
import { FormattedText, Icon, Typography } from 'src/modules/core/components'
import { routeNames } from 'src/modules/core/constants/routeNames'
import { LoadingWrapperContext } from 'src/modules/core/context'
import { useObservable } from 'src/modules/core/hooks/use-observable'
import { useOutsideClick } from 'src/modules/core/hooks/use-outside-click'
import { useGoogleProfile } from 'src/modules/user/hooks/use-google-profile'
import { TokenService } from 'src/services/token.service'
import { AuthContext } from '../../context'
import classes from './profile-info.module.scss'

import type { ProfileInfoProps } from './profile-info.interface'

export function ProfileInfo({ rootClassName, className }: ProfileInfoProps) {
  const authWrapperRef = useRef<HTMLDivElement>()
  const expandedWrapperRef = useRef<HTMLDivElement>()
  const [isExpanded, setIsExpanded] = useState(false)
  const token = useObservable(TokenService.getInstance().getToken())
  const googleProfile = useGoogleProfile(token !== null)
  const navigate = useNavigate()
  const { enableLoading, disableLoading } = useContext(LoadingWrapperContext)
  const synchronizeCalendars = useSynchronizeCalendars()
  const authContext = useContext(AuthContext)

  useOutsideClick([authWrapperRef, expandedWrapperRef], () => setIsExpanded(false))

  const handleLogout = useCallback(() => {
    authContext.logoutUser()
    enableLoading()
    navigate(routeNames.login.path)
  }, [authContext, enableLoading, navigate])

  const onResync = useCallback(async () => {
    enableLoading()
    await synchronizeCalendars.mutateAsync(null, {
      onSettled: () => {
        disableLoading()
        setIsExpanded(false)
      },
    })
  }, [synchronizeCalendars, enableLoading, disableLoading])

  return (
    <div className={rootClassName}>
      <div
        ref={authWrapperRef}
        className={classNames(classes.authWrapper, className)}
        onClick={() => setIsExpanded((prev) => !prev)}
      >
        <img className={classes.image} src={googleProfile.data?.picture} alt="profile image" />
        <Typography variant="body">{googleProfile.data?.name}</Typography>
        <Icon
          name="arrowLeftRounded"
          className={classNames(classes.expandIcon, {
            [classes.expandIconActive]: isExpanded,
          })}
        />
      </div>

      <div
        className={classNames(classes.expandWrapper, {
          [classes.expandWrapperHidden]: !isExpanded,
        })}
        ref={expandedWrapperRef}
      >
        <Typography variant="body" className={classes.email}>
          <FormattedText
            id="profile.signedAs"
            values={{
              email: googleProfile.data?.email,
              br: () => <br />,
              strong: (chunks) => <strong>{chunks}</strong>,
            }}
          />
        </Typography>
        <div className={classes.divider} />
        <Typography variant="body" onClick={onResync} className={classes.resync}>
          <FormattedText id="profile.resync" />
        </Typography>
        <div className={classes.divider} />
        <Typography variant="body" onClick={handleLogout} className={classes.logoOut}>
          <FormattedText id="profile.logout" />
        </Typography>
      </div>
    </div>
  )
}
