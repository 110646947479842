import { useQuery, UseQueryOptions } from 'react-query'
import { useHandleRetry } from 'src/modules/core/hooks/use-handle-retry'
import { ApiService } from 'src/services/api.service'

import type { BillingPlanDto } from '@grouse-software/types/billing/billing.dto'

export const GET_FREE_PLAN_CACHE_KEY = 'get-free-plan'

export function useFreePlan(params?: {}, options?: UseQueryOptions<BillingPlanDto>) {
  const handleRetry = useHandleRetry()
  return useQuery(GET_FREE_PLAN_CACHE_KEY, () => ApiService.Billing.getFreePlan(), {
    staleTime: 300000,
    retry: handleRetry,
    ...options,
  })
}
