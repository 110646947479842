import { FC } from 'react'
// components
import { FormattedText, Modal, Typography } from 'src/modules/core/components'
import { AppointmentConfirmationSteps } from 'src/modules/confirmation/components'
// constants
import { appointmentConfirmationSteps } from 'src/modules/confirmation/constants/confirmation.constants'

import type { AppointmentConfirmationStepsModalProps } from './appointment-confirmation-steps-modal.interface'
import classes from './appointment-confirmation-steps-modal.module.scss'

export const AppointmentConfirmationStepsModal: FC<
  AppointmentConfirmationStepsModalProps
> = ({ onClose }) => {
  return (
    <Modal
      onClose={onClose}
      title={
        <Typography variant="h3" className={classes.modalTitle}>
          <FormattedText id="settings.appointment.confirmations" />
        </Typography>
      }
    >
      <AppointmentConfirmationSteps steps={appointmentConfirmationSteps} />
    </Modal>
  )
}
