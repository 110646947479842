import { useMemo } from 'react'
import { billingTypes } from 'src/modules/payment/constants/payment.constants'
import { useSubscription } from 'src/modules/payment/hooks/use-subscription'
import { useSubscriptionPlans } from 'src/modules/payment/hooks/use-subscription-plans'
import { isPlanNotForSale } from 'src/modules/payment/utils/helpers'
import { BillingPlanDto } from '@grouse-software/types/billing'
import { useFreePlan } from './use-free-plan'

import type { UsePlanDataOptions } from 'src/modules/payment/types/payment.types'

export function usePlanData(options: UsePlanDataOptions = {}) {
  const subscription = useSubscription({}, options.subscriptionOptions)
  const subscriptionPlans = useSubscriptionPlans(
    {
      type: options.type,
    },
    options.subscriptionPlansOptions,
  )
  const freePlan = useFreePlan()

  const loading = useMemo(
    () => subscription.isLoading || subscriptionPlans.isLoading || freePlan.isLoading,
    [freePlan.isLoading, subscription.isLoading, subscriptionPlans.isLoading],
  )

  const currentPlan = useMemo<BillingPlanDto | null>(
    () => subscriptionPlans.data?.data.find((p) => p.paddle_id === subscription.data?.plan_id) ?? freePlan.data,
    [freePlan.data, subscription.data?.plan_id, subscriptionPlans.data?.data],
  )

  const filteredPlans = useMemo(() => {
    if (Array.isArray(subscriptionPlans.data?.data)) {
      return (
        subscriptionPlans.data?.data
          // filter plans depending on current plan
          .filter((plan) => (isPlanNotForSale(currentPlan) ? plan.paddle_id !== currentPlan?.paddle_id : plan))
          // sort by price ascending
          .sort((a, b) => a.price - b.price)
      )
    }

    return []
  }, [currentPlan, subscriptionPlans.data?.data])

  const availablePlans = useMemo(() => {
    if (options.isYearly === undefined) return []

    return filteredPlans?.filter((plan) =>
      options.isYearly
        ? plan.billing_type === billingTypes.longInterval
        : plan.billing_type === billingTypes.shortInterval,
    )
  }, [filteredPlans, options.isYearly])

  return {
    loading,
    currentPlan,
    filteredPlans,
    availablePlans,
  }
}
