import { makeRequest } from 'src/helpers/request'
import { GetSubscriptionPlansDto } from 'src/modules/billing/types/billing.types'

import type {
  BillingInvoiceDto,
  BillingPlanDto,
  BillingSubscriptionDto,
  UpdateBillingSubscriptionPreviewDto,
} from '@grouse-software/types/billing/billing.dto'
import type { ChangePaddleSubscriptionPlanBody } from '@grouse-software/types/billing'

export const BillingService = {
  getSubscription: (): Promise<BillingSubscriptionDto> => {
    return makeRequest<BillingSubscriptionDto>(`${process.env.REACT_APP_API_URL}/billing/subscription`, 'get')
  },

  getFreePlan: (): Promise<BillingPlanDto> => {
    return makeRequest<BillingPlanDto>(`${process.env.REACT_APP_API_URL}/billing/plans/free`, 'get')
  },

  cancelSubscription: () => {
    return makeRequest(`${process.env.REACT_APP_API_URL}/billing/subscription`, 'delete')
  },

  changeSubscription: (params: ChangePaddleSubscriptionPlanBody) => {
    return makeRequest(`${process.env.REACT_APP_API_URL}/billing/subscription`, 'patch', params)
  },

  getUpdateSubscriptionPreview: (planId: number): Promise<UpdateBillingSubscriptionPreviewDto> => {
    return makeRequest(`${process.env.REACT_APP_API_URL}/billing/subscription/update-preview/${planId}`, 'get')
  },

  getSubscriptionPlans: (params: GetSubscriptionPlansDto): Promise<{ data: BillingPlanDto[] }> => {
    return makeRequest<{ data: BillingPlanDto[] }>(
      `${process.env.REACT_APP_API_URL}/billing/plans/${params.type ?? ''}`,
      'get',
    )
  },

  getInvoices: (): Promise<{ data: BillingInvoiceDto[] }> => {
    return makeRequest<{ data: BillingInvoiceDto[] }>(`${process.env.REACT_APP_API_URL}/billing/invoices`, 'get')
  },
}
