import { useQuery, UseQueryOptions } from 'react-query'
import { useHandleRetry } from 'src/modules/core/hooks/use-handle-retry'
import { ApiService } from 'src/services/api.service'
import { ListEventOptions } from '@grouse-software/types/event/event.api'

export const GET_EVENT_LIST_CACHE_KEY = 'get-event-list'

export function useEventList<T extends {}>(params?: ListEventOptions, options?: UseQueryOptions<T[]>) {
  const handleRetry = useHandleRetry()
  return useQuery([GET_EVENT_LIST_CACHE_KEY, params], () => ApiService.Events.getEventList<T>(params), {
    retry: handleRetry,
    ...options,
  })
}
