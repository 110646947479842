import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useBulkUpdateCalendars } from 'src/modules/calendar/hooks/use-bulk-update-calendars'
import { FormattedText, InfoModal, Loader } from 'src/modules/core/components'
import { routeNames } from 'src/modules/core/constants/routeNames'
import { ConfigurationStepContext } from 'src/modules/core/context'
import { TAG_MANAGER_EVENTS } from 'src/modules/core/types/analytics.types'
import { SubscriptionSelector } from 'src/modules/payment/components'
import { billingTypes, billingTypeSet } from 'src/modules/payment/constants/payment.constants'
import { usePlanData } from 'src/modules/payment/hooks/use-plan-data'
import { useSubscription } from 'src/modules/payment/hooks/use-subscription'
import { isPlanNotForSale, isSubscriptionActive } from 'src/modules/payment/utils/helpers'
import { useProfile } from 'src/modules/user/hooks/use-profile'
import classes from './subscription-screen.module.scss'
import { SubscriptionScreenProps } from './subscription.interface'

import type { BillingPlanDto } from '@grouse-software/types/billing'

export const SubscriptionScreen: FC<SubscriptionScreenProps> = ({ title, description, type }) => {
  const [selectedPlan, setSelectedPlan] = useState<BillingPlanDto>(null)
  const [isTimeoutLoading, setIsTimeoutLoading] = useState(false)

  const navigate = useNavigate()
  const profile = useProfile()
  const subscriptionOptions = {
    enabled: !!profile.data?.billing_subscription_id,
  }

  const subscription = useSubscription({}, subscriptionOptions)
  const bulkUpdateCalendars = useBulkUpdateCalendars()
  const { currentPlan, loading } = usePlanData({ subscriptionOptions })

  const { handleNext } = useContext(ConfigurationStepContext)

  const isLoading = useMemo(
    () => subscription.isLoading || profile.isLoading || bulkUpdateCalendars.isLoading || isTimeoutLoading || loading,
    [subscription.isLoading, profile.isLoading, bulkUpdateCalendars.isLoading, isTimeoutLoading, loading],
  )

  useEffect(() => {
    if (
      currentPlan &&
      !isPlanNotForSale(currentPlan) &&
      isSubscriptionActive(subscription.data?.state, profile.data?.billing_cancellation_effective_date)
    ) {
      handleNext()
    }
  }, [currentPlan, handleNext, profile.data?.billing_cancellation_effective_date, subscription.data?.state])

  const onSelectPlan = useCallback(
    (plan: BillingPlanDto, selectedCalendarIds: string[]) => () => {
      window.dataLayer?.push({
        event: TAG_MANAGER_EVENTS.CHOOSE_SUBSCRIPTION_ONBOARDING,
        user_id: profile.data?.id,
      })
      window.Paddle.Checkout.open({
        product: plan.paddle_id,
        email: profile.data?.email,
        passthrough: JSON.stringify({ user_id: profile.data?.id }),
        successCallback: () => {
          setIsTimeoutLoading(true)
          window.dataLayer?.push({
            event: TAG_MANAGER_EVENTS.PURCHASE_COMPLETE_ONBOARDING,
            user_id: profile.data?.id,
          })
          const timeout = 2000

          setTimeout(() => {
            bulkUpdateCalendars.mutate(
              selectedCalendarIds.map((id) => ({ id, selected: true })),
              {
                onSuccess: () => {
                  setSelectedPlan(plan)
                  setIsTimeoutLoading(false)
                },
              },
            )
          }, timeout)
        },
      })
    },
    [profile.data?.email, profile.data?.id, bulkUpdateCalendars],
  )

  const onSuccess = useCallback(() => {
    // TODO: do it better
    navigate(routeNames.dashboard.path)
    window.location.reload()
  }, [navigate])

  if (isLoading) return <Loader isLoading={isLoading} />

  return (
    <>
      <SubscriptionSelector
        onSelectPlan={onSelectPlan}
        subscriptionOptions={subscriptionOptions}
        title={title}
        type={type}
        description={
          <span>
            {description ?? (
              <FormattedText
                id={
                  profile.data?.has_benefits
                    ? 'configuration.subscription.description.beta'
                    : 'configuration.subscription.description'
                }
              />
            )}
            <span className={classes.space} />
          </span>
        }
      />
      {selectedPlan && (
        <InfoModal
          title={<FormattedText id="payment.subscription.success.title" />}
          description={
            <FormattedText
              id="payment.subscription.success.description"
              values={{
                strong: (chunks) => <strong>{chunks}</strong>,
                plan: selectedPlan.name,
                billingType:
                  billingTypeSet[
                    selectedPlan.billing_type === billingTypes.longInterval
                      ? billingTypes.longInterval
                      : billingTypes.shortInterval
                  ],
              }}
            />
          }
          button={<FormattedText id="common.continue" />}
          onClick={onSuccess}
          onClose={onSuccess}
        />
      )}
    </>
  )
}
