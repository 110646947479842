import { FC, useContext, useEffect } from 'react';
import TagManager, { TagManagerArgs } from 'react-gtm-module';
import { useLocation } from 'react-router-dom';
import { useTggl } from 'react-tggl-client';
import { AuthContext } from 'src/modules/user/context';
import { useProfile } from 'src/modules/user/hooks/use-profile';
import { TokenService } from 'src/services/token.service';
import * as amplitude from '@amplitude/analytics-browser';
import { useObservable } from '../../hooks/use-observable';
import { isRouteWithoutActivePlan } from '../../utils/router.utils';

const tagManagerArgs: TagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID,
}

export const TagManagerWrapper: FC = ({ children }) => {
  const location = useLocation()
  const { isAuthenticated } = useContext(AuthContext)
  const token = useObservable(TokenService.getInstance().getToken())
  const { updateContext } = useTggl()

  const { data } = useProfile(
    {},
    {
      enabled: Boolean(token !== null && isAuthenticated && !isRouteWithoutActivePlan(location.pathname)),
    },
  )

  useEffect(() => {
    // Track authenticated users
    if (isAuthenticated) {
      if (data?.id) {
        // GTM dataLayer context
        TagManager.initialize({
          ...tagManagerArgs,
          dataLayer: {
            user_id: data?.id,
          },
        })

        // Amplitude context to track flag evaluation by users
        amplitude.setUserId(data?.id)

        // Tggl context
        updateContext({
          userId: data?.id,
          email: data?.email,
        })
      }
    } else {
      TagManager.initialize(tagManagerArgs)
      updateContext({
        userId: null,
        email: null,
      })
      amplitude.setUserId(null)
    }
  }, [isAuthenticated, data, updateContext])

  return <>{children}</>
}
