import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ConfirmationForm, ConfirmationMessage } from 'src/modules/confirmation/components'
// components
import { Loader } from 'src/modules/core/components'
import { useConfirmEvent } from 'src/modules/event/hooks/use-confirm-event'
// hooks
import { useEventByUrlCode } from 'src/modules/event/hooks/use-event-by-url-code'
import classes from './confirmation-wrapper.module.scss'

import type { ConfirmationStatus } from './confirmation-wrapper.interface'

export const ConfirmationWrapper = () => {
  // hooks
  const params = useParams()
  const eventByUrlCode = useEventByUrlCode(params.urlCode)
  const confirmEvent = useConfirmEvent()
  // state
  const [status, setStatus] = useState<ConfirmationStatus>('pending')

  const handleConfirmEvent = useCallback(() => {
    confirmEvent.mutate({
      url_code: params.urlCode,
      confirm: true,
    })
    setStatus('success')
  }, [confirmEvent, params.urlCode])

  const handleCancelEvent = useCallback(() => {
    confirmEvent.mutate({
      url_code: params.urlCode,
      confirm: false,
    })
    setStatus('canceled')
  }, [confirmEvent, params.urlCode])

  useEffect(() => {
    if (
      eventByUrlCode.isLoadingError ||
      eventByUrlCode.isError ||
      (eventByUrlCode.data && new Date() > new Date(parseInt(eventByUrlCode.data.event_date, 10))) ||
      !params.urlCode
    ) {
      setStatus('error')
      return
    }

    if (eventByUrlCode.data && eventByUrlCode.data.confirmed === 'cancelled') {
      setStatus('canceled')
    }
  }, [eventByUrlCode.isLoadingError, eventByUrlCode.isError, eventByUrlCode.data, params.urlCode])

  return (
    <div className={classes.root}>
      <Loader isLoading={eventByUrlCode.isFetching} className={classes.loader} full />
      {status !== 'pending' ? (
        <ConfirmationMessage variant={status} />
      ) : (
        <ConfirmationForm event={eventByUrlCode.data} onConfirm={handleConfirmEvent} onCancel={handleCancelEvent} />
      )}
    </div>
  )
}
