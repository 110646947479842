import 'react-phone-input-2/lib/style.css';
import classNames from 'classnames';
import { useContext } from 'react';
import { useIntl } from 'react-intl';
import { useQueryClient } from 'react-query';
import { ReactComponent as GoogleLogo } from 'src/assets/icons/google.svg';
import img from 'src/assets/images/calendar-wifi.png';
import { Modal, Typography } from 'src/modules/core/components';
import { AuthContext } from 'src/modules/user/context';
import { redirectToGoogleAuth } from 'src/modules/user/redirectToGoogleAuth';
import { TokenService } from 'src/services/token.service';
import * as amplitude from '@amplitude/analytics-browser';
import { Box, Grid } from '@material-ui/core';
import classes from './sync-offline-changes-modal.module.scss';

export function SyncOfflineChangesModal() {
  const { formatMessage } = useIntl()
  const { logoutUser, setIsReauthorizingForOfflineSync } = useContext(AuthContext)

  const queryClient = useQueryClient()

  const handleButtonClick = () => {
    amplitude.track('Onboarding_ContinueWithGoogle_button')
    setIsReauthorizingForOfflineSync(true)
    logoutUser()
    TokenService.getInstance().removeToken()
    queryClient.removeQueries()
    redirectToGoogleAuth(true)
  }

  return (
    <Modal className={classNames(classes.modal, 'translate')}>
      <Grid container direction="row" justifyContent="center" alignItems="center" spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h3" className={classes.modalTitle}>
            {formatMessage({ id: 'onboarding.syncOfflineChanges.title' })}
          </Typography>
        </Grid>
        <Grid item>
          <p>{formatMessage({ id: 'onboarding.syncOfflineChanges.text1' })}</p>
        </Grid>
        <Grid item>
          <p>{formatMessage({ id: 'onboarding.syncOfflineChanges.text2' })}</p>
        </Grid>
        <Grid item>
          <img src={img} alt="Calendar icon with wifi signal" aria-hidden="true" className={classes.image} />
        </Grid>
        <Grid item>
          <button onClick={handleButtonClick} className={classes.continueWithGoogle}>
            <Box display="flex" alignItems="center" justifyContent="center">
              <GoogleLogo className={classes.googleLogo} />
              <span>{formatMessage({ id: 'onboarding.syncOfflineChanges.button' })}</span>
            </Box>
          </button>
        </Grid>
      </Grid>
    </Modal>
  )
}
