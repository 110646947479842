import { FC, memo } from 'react'
import PhoneInput from 'react-phone-input-2'
import classNames from 'classnames'

import type { PhoneNumberFieldProps } from './phone-number-field.interface'
import classes from './phone-number-field.module.scss'

export const PhoneNumberField: FC<PhoneNumberFieldProps> = memo(
  ({
    error,
    inputClass,
    buttonClass,
    country = 'us',
    enableSearch = true,
    ...props
  }) => (
    <PhoneInput
      inputClass={classNames(classes.input, inputClass, {
        [classes.inputError]: !!error,
      })}
      buttonClass={classNames(classes.button, buttonClass, {
        [classes.buttonError]: !!error,
        [classes.buttonDisabled]: props.disabled,
      })}
      country={country}
      enableSearch={enableSearch}
      disableSearchIcon
      {...props}
    />
  ),
)

PhoneNumberField.displayName = 'PhoneNumberField'
