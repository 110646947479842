import { useQuery, UseQueryOptions } from 'react-query'
import { useHandleRetry } from 'src/modules/core/hooks/use-handle-retry'

export const GET_PADDLE_PLAN_DATA = 'get-paddle-plan-data-2'

export type GetPaddlePlanDataParams = {
  planId: number | string
}

type PriceData = {
  gross: string
  net: string
  tax: string
  tax_included: boolean
}

type Subscription = {
  trial_days: number
  length: number
  type: 'month' | 'year'
}

type Recurring = {
  price: PriceData
  subscription: Subscription
}

export type PaddlePlanData = {
  id: number
  price: PriceData
  quantity: number
  country: string
  recurring: Recurring
}

function getPaddlePlanData(planId: number | string): Promise<PaddlePlanData> {
  planId = Number(planId)
  return new Promise((resolve) =>
    window.Paddle.Product.Prices(planId, 1, (data: PaddlePlanData) => {
      const planData = { id: planId, ...data }
      resolve(planData)
    }),
  )
}

export const usePaddlePlanData = (params?: GetPaddlePlanDataParams, options?: UseQueryOptions<PaddlePlanData>) => {
  const handleRetry = useHandleRetry()
  return useQuery([GET_PADDLE_PLAN_DATA, params], () => getPaddlePlanData(params.planId), {
    staleTime: 1,
    retry: handleRetry,
    ...options,
  })
}
