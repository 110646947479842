import classNames from 'classnames'
import { addDays, format } from 'date-fns'
import Tooltip from 'rc-tooltip'
import { ChangeEvent, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useQueryClient } from 'react-query'
import { AutoReminderModal } from 'src/modules/auto-reminders/auto-reminder-modal'
import { Button, ErrorModal, FormattedText, Icon, Loader, Typography } from 'src/modules/core/components'
import { DateContext } from 'src/modules/core/context'
import { EventCard } from 'src/modules/event/components'
import { eventDetailsParams } from 'src/modules/event/constants/event-params'
import { maxMessageLengthWithConfirmation } from 'src/modules/event/constants/event.constants'
import { GET_EVENT_BY_ID_CACHE_KEY } from 'src/modules/event/hooks/use-event-by-id'
import {
  filterWithinTimingValues,
  generateEventMessage,
  generateTimingWarningMessage,
  validateEvent,
} from 'src/modules/event/utils/helpers'
import { TypeformReviewModal } from 'src/modules/review/typeform-review-modal/typeform-review-modal'
import { useProfile } from 'src/modules/user/hooks/use-profile'
import { ApiService } from 'src/services/api.service'
import { groupEventsByDate } from '../../utils/group-events-by-date'
import classes from './event-list.module.scss'

import type { EventData, EventDetails, ExtendEventPreview } from 'src/modules/event/types/event.types'
import type { ErrorMessage } from 'src/modules/core/types/error.types'
import type { EventListProps } from './event-list.interface'
const offsetRight = 0
const offsetBottom = 14

export function EventList({ events, onCardClick, onSwitchChange, changeSelectedDate, isLoading }: EventListProps) {
  const [selectedDate, setSelectedDate] = useState<Date>(new Date())
  const [errorMessage, setErrorMessage] = useState<ErrorMessage>(null)
  const [loadingEventId, setLoadingEventId] = useState<string>(null)

  const { daysCount, setDaysCount } = useContext(DateContext)
  const containerRef = useRef<HTMLDivElement>(null)
  const columnWidth = 230
  const gapWidth = 12
  const calculateColumns = useCallback(() => {
    const containerWidth = containerRef.current?.offsetWidth || 0
    const totalColumnWidth = columnWidth + gapWidth
    const maxColumns = Math.floor((containerWidth + gapWidth) / totalColumnWidth)
    setDaysCount(maxColumns)
  }, [setDaysCount])
  useEffect(() => {
    calculateColumns()
    window.addEventListener('resize', calculateColumns)
    return () => {
      window.removeEventListener('resize', calculateColumns)
    }
  }, [calculateColumns])

  const queryClient = useQueryClient()
  const profile = useProfile()

  const filteredEvents = useMemo(() => {
    return groupEventsByDate(events, addDays(selectedDate, 0), addDays(selectedDate, daysCount))
  }, [events, selectedDate, daysCount])

  const onDateChange = useCallback(
    (number: number) => () => {
      setSelectedDate((prevState) => addDays(prevState, number))
      changeSelectedDate && changeSelectedDate((prevState) => addDays(prevState, number))
    },
    [changeSelectedDate],
  )

  const handleCardClick = useCallback(
    (eventPreview: ExtendEventPreview) => () => {
      onCardClick && onCardClick(eventPreview)
    },
    [onCardClick],
  )

  const handleSwitchChange = useCallback(
    (eventPreview: ExtendEventPreview) => async (e: ChangeEvent<HTMLInputElement>) => {
      const isActivating = e.target.checked
      const id = eventPreview.id

      setLoadingEventId(id)

      const eventDetails = await queryClient.fetchQuery<EventDetails>(
        [GET_EVENT_BY_ID_CACHE_KEY, id, eventDetailsParams],
        () => ApiService.Events.getEventById(id, eventDetailsParams),
        { staleTime: 300000 },
      )

      setLoadingEventId(null)

      const event: EventData = { ...eventPreview, ...eventDetails }
      const { isNumberError, isMessageError, isTimingError } = validateEvent(event, profile.data)
      const eventDate = new Date(parseInt(event.event_date, 10))

      if (isActivating) {
        const isPastEvent = new Date() > eventDate
        const timingValues =
          event.default_time_before && !event.active
            ? profile.data?.default_time_before
            : (event?.reminders?.data?.length > 0 && event.reminders.data.map((t) => t.time_before)) || []

        const withinTimingValues = filterWithinTimingValues(eventDate, timingValues)

        if (isPastEvent || withinTimingValues.length) {
          return setErrorMessage({
            title: `Couldn't create SMS reminder`,
            description: generateTimingWarningMessage(withinTimingValues),
          })
        }

        if (isNumberError || isMessageError || isTimingError) {
          if (onCardClick) {
            return onCardClick(event)
          }
          return setErrorMessage({
            title: `Couldn't create SMS reminder`,
            description: `There must be phone number, message and timing associated with the reminder.`,
          })
        }

        const messageLength = generateEventMessage({ ...profile.data, appointment_confirmation: false }, event).length

        if (profile.data.appointment_confirmation && messageLength > maxMessageLengthWithConfirmation) {
          return setErrorMessage({
            title: 'SMS Message is Too Long',
            description: `Your reminder message is ${messageLength}. Messages must be less than 320 characters (239 characters if you're using Appointment Confirmations) including any dynamic text that's inserted`,
          })
        }
      }

      onSwitchChange && onSwitchChange({ ...event, active: isActivating })
    },
    [queryClient, profile.data, setErrorMessage, onCardClick, onSwitchChange],
  )

  const handleReturnToTodayClick = useCallback(() => {
    const today = new Date()

    setSelectedDate(today)
    today.setHours(0, 0, 0, 0)
    changeSelectedDate && changeSelectedDate(today)
  }, [setSelectedDate, changeSelectedDate])

  return (
    <div className={classes.root}>
      <div className={classes.heading}>
        <div className={classes.leftButtons}>
          <div className={classes.arrowWrapper} onClick={onDateChange(-daysCount)}>
            <Icon name="arrowLeftRounded" className={classes.arrow} />
          </div>
          <Tooltip
            placement="bottom"
            mouseEnterDelay={0.1}
            motion={{ motionName: 'example' }}
            align={{ offset: [offsetRight, offsetBottom] }}
            overlayClassName={classes.tooltipOverlay}
            overlay={
              <Typography variant="span" className={classes.bodyText}>
                {format(new Date(), 'EEEE, LLLL d')}
              </Typography>
            }
          >
            <Button className={classes.button} onClick={handleReturnToTodayClick}>
              <FormattedText id="dashboard.button.today" />
            </Button>
          </Tooltip>
        </div>
        <Typography variant="h2" className={classes.month}>
          {format(selectedDate, 'MMMM uuuu')}
        </Typography>
        <div className={classes.arrowWrapper} onClick={onDateChange(daysCount)}>
          <Icon name="arrowLeftRounded" className={classNames(classes.arrow, classes.arrowRight)} />
        </div>
      </div>
      <div className={classes.remindersList} ref={containerRef}>
        <Loader className={classes.loader} isLoading={isLoading} />
        {!isLoading &&
          filteredEvents.map((dayEvents) => (
            <div key={dayEvents.date} className={classes.remindersListItem}>
              <Typography
                className={classNames(classes.dayTitle, { [classes.bold]: dayEvents.isToday })}
                variant="body"
              >
                {dayEvents.date}
              </Typography>
              <div>
                {dayEvents.events.map((event) => (
                  <div
                    key={`${event.id}-${event.event_date}`}
                    className={classes.reminder}
                    onClick={handleCardClick(event)}
                  >
                    <EventCard
                      showBadge={profile.data?.appointment_confirmation}
                      showSwitch
                      variant={'current'}
                      event={event}
                      onSwitchChange={handleSwitchChange(event)}
                      isLoading={loadingEventId === event.id}
                    />
                  </div>
                ))}
                {dayEvents.events.length === 0 && (
                  <Typography variant="body" className={classes.textEmpty}>
                    <FormattedText id="reminder.text.empty" />
                  </Typography>
                )}
              </div>
            </div>
          ))}
      </div>
      {errorMessage && (
        <ErrorModal
          title={
            <Typography variant="body" className={classes.errorTitle}>
              {errorMessage.title}
            </Typography>
          }
          description={
            <Typography variant="span" className={classes.errorDescription}>
              {errorMessage.description}
            </Typography>
          }
          onClose={() => setErrorMessage(null)}
        />
      )}
      <TypeformReviewModal />
      <AutoReminderModal />
    </div>
  )
}
