import { forwardRef, memo } from 'react'
import classNames from 'classnames'
import uniqid from 'uniqid'

import type { ToggleSwitchProps } from './toggle-switch.interface'
import classes from './toggle-switch.module.scss'

export const ToggleSwitch = memo(
  forwardRef<HTMLInputElement, ToggleSwitchProps>(
    ({ id = uniqid(), labelClassName, variant = 'primary', ...props }, ref) => (
      <div className={classes.root}>
        <input
          id={id}
          type="checkbox"
          {...props}
          ref={ref}
          className={classNames(classes.input, {
            [classes.inputPrimary]: variant === 'primary',
            [classes.inputSecondary]: variant === 'secondary',
          })}
        />
        <label
          htmlFor={id}
          className={classNames(classes.label, labelClassName, {
            [classes.labelPrimary]: variant === 'primary',
            [classes.labelSecondary]: variant === 'secondary',
          })}
        >
          <div
            className={classNames(classes.thumb, {
              [classes.thumbPrimary]: variant === 'primary',
              [classes.thumbSecondary]: variant === 'secondary',
            })}
          />
        </label>
      </div>
    ),
  ),
)
