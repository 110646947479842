import { useQuery } from 'react-query'
import { useHandleRetry } from 'src/modules/core/hooks/use-handle-retry'
import { ApiService } from 'src/services/api.service'

export const GET_SYNC_JOB_ID_CACHE_KEY = 'get-sync-job-status'

export function useSyncJobStatus(jobId: string) {
  const handleRetry = useHandleRetry()
  return useQuery([GET_SYNC_JOB_ID_CACHE_KEY, jobId], () => ApiService.Events.getSyncJobStatus(jobId), {
    enabled: !!jobId,
    retry: handleRetry,
    refetchInterval: (data) => {
      return data?.status === 'completed' ? false : 1000 * 5
    },
  })
}
