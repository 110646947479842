import { addDays, addMinutes, differenceInDays, differenceInMinutes, format, millisecondsToMinutes } from 'date-fns'
import RRule, { RRuleSet, rrulestr } from 'rrule'
import { UserDto } from 'src/helpers/user.dto'
import { isString } from 'src/modules/core/utils/helpers'
import { exampleConfirmationMessage } from 'src/modules/event/constants/event.constants'
import { dateOrders } from 'src/modules/user/constants/date-orders.constants'
import { timingOptions } from 'src/modules/user/constants/profile.constants'
import { DateOrders } from 'src/modules/user/types/date-orders.types'

import type { TimingOption } from 'src/modules/user/types/profile.types'
import type {
  EventData,
  EventPreview,
  EventValidationResponse,
  ExtendEventPreview,
} from 'src/modules/event/types/event.types'

export function formatEventDate(eventDate: Date | string, dateOrder: keyof DateOrders) {
  return format(
    eventDate instanceof Date ? eventDate : new Date(parseInt(eventDate, 10)),
    dateOrders[dateOrder] || dateOrders.MDY,
  )
}

export function generateEventMessage(profile: UserDto, event: EventData): string {
  if (!profile || !event) {
    return ''
  }

  const unprocessedMessage = isString(event?.text) ? event.text : profile?.default_message_text || ''
  const title = isString(event?.title) ? event.title : ''
  const eventLocation = isString(event?.event_location) ? event.event_location : ''
  const location = isString(event?.location) ? event.location : profile?.default_location || ''
  const sender = isString(event?.sender_name) ? event.sender_name : profile?.sender_name || ''
  const eventDate = new Date(parseInt(event.event_date, 10))
  const date = formatEventDate(event.event_date, profile.google_calendar_date_order)
  const time = event.all_day ? 'All Day' : format(eventDate, profile.google_calendar_24_hour_time ? 'HH:mm' : 'h:mma')

  const processedMessage = unprocessedMessage
    .replace(/{title}/g, title)
    .replace(/{eventlocation}/g, eventLocation)
    .replace(/{location}/g, location)
    .replace(/{from}/g, sender)
    .replace(/{date}/g, date)
    .replace(/{time}/g, time)

  if (profile.appointment_confirmation) {
    return processedMessage.concat(exampleConfirmationMessage)
  }

  return processedMessage
}

export function generateEstimatedEventMessage(profile: UserDto): string {
  if (!profile) return ''

  const unprocessedMessage = profile?.default_message_text || ''
  const location = profile?.default_location || ''
  const sender = profile?.sender_name || ''

  const processedMessage = unprocessedMessage
    .replace(/{title}/g, 'Sample Appointment Title')
    .replace(/{eventlocation}/g, '456 Awesome Street, Suite 34D, Sample Town')
    .replace(/{location}/g, location || '78 Test Ave, Suite 5D')
    .replace(/{from}/g, sender || 'Name or Business')
    .replace(/{date}/g, 'DD/MM/YYYY')
    .replace(/{time}/g, profile.google_calendar_24_hour_time ? 'HH:MM' : 'HH:MM pm')

  if (profile.appointment_confirmation) {
    return processedMessage.concat(exampleConfirmationMessage)
  }

  return processedMessage
}

export function groupEventsByDate(
  events: EventPreview[],
  startDate: Date,
  endDate: Date,
): { date: string; events: ExtendEventPreview[] }[] {
  const difference = differenceInDays(endDate, startDate)
  const eventsWithoutRecurringRule = events
    .filter((event) => !event.recurrence_rules)
    .map((event) => {
      if (event.recurring_event_id !== null) {
        const parentEvent = events.find((recurringEvent) => recurringEvent.event_id === event.recurring_event_id)
        if (parentEvent) {
          return {
            ...parentEvent,
            id: event.id,
            parentRecurringId: parentEvent.id,
            recurring_event_id: event.recurring_event_id,
            recurrence_rules: event.recurrence_rules,
            confirmed: event.confirmed,
            title: event.title,
            event_date: event.event_date,
            all_day: event.all_day,
          }
        }

        return event
      }

      return event
    })

  return Array(difference)
    .fill(0)
    .reduce((prev, _, i) => {
      const iterableDay = addDays(startDate, i)
      const eventsWithRecurringRule = events.filter((event) => !!event.recurrence_rules)

      const recurringEvents: EventPreview[] = eventsWithRecurringRule.reduce((arr, recurrenceEvent) => {
        if (recurrenceEvent.recurrence_rules) {
          const rules = [...recurrenceEvent.recurrence_rules]

          const recurrenceEventDate = new Date(parseInt(recurrenceEvent.event_date, 10))

          let dtstart = recurrenceEventDate
          const offset = new Date().getTimezoneOffset()

          if (isDateWithinTZDifferenceWithUTC(recurrenceEventDate, offset)) {
            // add time zone difference with UTC to date
            const recEventDateWithTZHours = addMinutes(recurrenceEventDate, -offset)

            dtstart = recEventDateWithTZHours
          }

          rules.push(RRule.optionsToString({ dtstart }))

          const rule = rrulestr(rules.join('\n')) as RRuleSet
          const updatedRule: Omit<RRuleSet, '_exdate'> & { _exdate: Date[] } = rule

          if (updatedRule._exdate) {
            updatedRule._exdate = updateRruleExdates(
              dtstart,
              rule,
              isDateWithinTZDifferenceWithUTC(recurrenceEventDate, offset),
              offset,
            )
          }

          const ruleStartDate = addDays(new Date(startDate), -1)
          const ruleEndDate = addDays(new Date(endDate), 1)
          const ruleEvents = updatedRule.between(ruleStartDate, ruleEndDate)

          return [
            ...arr,
            ...ruleEvents.map((ruleDate) => {
              ruleDate.setHours(recurrenceEventDate.getHours())
              ruleDate.setMinutes(recurrenceEventDate.getMinutes())

              return {
                ...recurrenceEvent,
                event_date: ruleDate.getTime(),
              }
            }),
          ]
        }

        return [...arr]
      }, [])

      const combinedEvents = [...recurringEvents, ...eventsWithoutRecurringRule]

      const filteredEvents = [
        ...prev,
        {
          date: format(iterableDay, 'EEE, LLL d'),
          events: [
            ...combinedEvents
              .filter((event) => {
                const reminderDate = new Date(parseInt(event.event_date, 10))

                return (
                  reminderDate.getFullYear() === iterableDay.getFullYear() &&
                  reminderDate.getMonth() === iterableDay.getMonth() &&
                  reminderDate.getDate() === iterableDay.getDate()
                )
              })
              .sort((event1, event2) =>
                differenceInMinutes(
                  new Date(parseInt(event1.event_date, 10)),
                  new Date(parseInt(event2.event_date, 10)),
                ),
              ),
          ],
        },
      ]

      return filteredEvents
    }, [])
}

export const formatTimingValues = (values: TimingOption[]): string => {
  return values
    .map((t, index) => `${index === values.length - 1 ? ' and' : ''} ${t.value} ${t.label}`)
    .join(',')
    .replace(', and', ' and')
}

export const filterWithinTimingValues = (then: Date, timingValues: number[]): number[] => {
  const now = new Date()

  const msBetweenDates = Math.abs(then.getTime() - now.getTime())

  return timingValues.filter((t) => msBetweenDates < t)
}

export function generateTimingWarningMessage(timingValues: number[]): string {
  let message = 'The reminder is in the past'
  const timings: TimingOption[] = timingValues.map((t) => {
    const currentTiming = timingOptions.find((option) => +option.value === +t)

    return {
      value: +currentTiming?.label.split(' ')[0],
      label: currentTiming?.label.split(' ')[1],
    }
  })

  if (timings.length === 1) {
    const timingValue = timings[0]

    message = `The reminder for ${timingValue.value} ${timingValue.label} is in the past`
  } else if (timings.length > 1) {
    message = `The reminders for${formatTimingValues(timings)} are in the past`
  }

  return message
}

export function updateRruleExdates(
  dtstart: Date,
  rruleObject: RRuleSet,
  addTZDifferenceWithUTC?: boolean,
  offset?: number,
): Date[] {
  const dtstartHours = dtstart.getUTCHours()
  const dtstartMinutes = dtstart.getUTCMinutes()
  const dtstartSeconds = dtstart.getUTCSeconds()

  return rruleObject._exdate.map((date) => {
    let updatedDate = new Date(date)

    if (addTZDifferenceWithUTC && typeof offset === 'number') {
      // add time zone difference with UTC to date
      updatedDate = addMinutes(date, -offset)
    }

    updatedDate.setUTCHours(dtstartHours)
    updatedDate.setUTCMinutes(dtstartMinutes)
    updatedDate.setUTCSeconds(dtstartSeconds)

    return updatedDate
  })
}

export function validateEvent(event: EventData, profile: UserDto): EventValidationResponse {
  const isNumberError =
    !event.receiver_numbers || (Array.isArray(event.receiver_numbers) && event.receiver_numbers.length === 0)
  const isMessageError =
    (event.text === '' || !event.text) && (!profile.default_message_text || profile.default_message_text === '')
  const isTimingError =
    (!event.reminders.data || event.reminders.data.length === 0) &&
    (!profile.default_time_before || profile.default_time_before.length === 0)

  return { isNumberError, isMessageError, isTimingError }
}

export function isDateWithinTZDifferenceWithUTC(date: Date, offset: number): boolean {
  const midnight = new Date(date.getTime())

  midnight.setHours(0)
  midnight.setMinutes(0)
  midnight.setSeconds(0)

  return (
    millisecondsToMinutes(
      offset < 0
        ? // if time zone is positive (GMT+1)
          date.getTime() - midnight.getTime()
        : // if time zone is negative (GMT-1)
          midnight.getTime() - date.getTime(),
    ) <= -offset
  )
}
