/* eslint-disable no-magic-numbers */
import { hoursToMilliseconds, minutesToMilliseconds } from 'date-fns'
// utils
import { daysToMilliseconds } from 'src/modules/core/utils/helpers'

export const timingOptions = [
  { value: `${daysToMilliseconds(7)}`, label: '7 days' },
  { value: `${daysToMilliseconds(2)}`, label: '2 days' },
  { value: `${hoursToMilliseconds(24)}`, label: '24 hours' },
  { value: `${hoursToMilliseconds(12)}`, label: '12 hours' },
  { value: `${hoursToMilliseconds(6)}`, label: '6 hours' },
  { value: `${hoursToMilliseconds(1)}`, label: '1 hour' },
  { value: `${minutesToMilliseconds(30)}`, label: '30 minutes' },
]
