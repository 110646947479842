import { useQuery, UseQueryOptions } from 'react-query'
import { useSyncCalendars } from 'src/modules/calendar/hooks/use-sync-calendars'
import { useHandleRetry } from 'src/modules/core/hooks/use-handle-retry'
import { ApiService } from 'src/services/api.service'
import { CalendarDto } from '@grouse-software/types/calendar'

type UseCalendarsOptions = { sync: boolean } & UseQueryOptions<CalendarDto[]>

export const GET_CALENDARS_CACHE_KEY = 'get-calendars'

export function useCalendars(params?: {}, options: UseCalendarsOptions = { sync: false }) {
  const handleRetry = useHandleRetry()
  const syncCalendars = useSyncCalendars()

  const { sync, ...restOptions } = options

  return useQuery(
    GET_CALENDARS_CACHE_KEY,
    async () => {
      if (sync) await syncCalendars.mutateAsync()
      return ApiService.Calendars.getCalendars()
    },
    {
      staleTime: 300000,
      retry: handleRetry,
      ...restOptions,
    },
  )
}
