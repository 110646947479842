import { FC, useState } from 'react'
// context
import { EventsContext } from 'src/modules/event/context/'
// types
import type { EventPreview } from 'src/modules/event/types/event.types'

export const EventsProvider: FC = ({ children }) => {
  // state
  const [activeEvents, setActiveEvents] = useState<EventPreview[]>([])

  return (
    <EventsContext.Provider
      value={{
        activeEvents,
        setActiveEvents,
      }}
    >
      {children}
    </EventsContext.Provider>
  )
}
