import { memo, forwardRef } from 'react'
import uniqid from 'uniqid'
import classNames from 'classnames'
// components
import { Icon } from 'src/modules/core/components'

import type { CheckboxProps } from './checkbox.interface'
import classes from './checkbox.module.scss'

export const Checkbox = memo(
  forwardRef<HTMLInputElement, CheckboxProps>(
    (
      {
        id = uniqid(),
        type = 'checkbox',
        disabled,
        labelDisabled = true,
        checked,
        color,
        ...props
      },
      ref,
    ) => {
      const getLabelStyle = () => {
        if (!color) {
          return {}
        }

        if (checked) {
          return { background: color, border: `1px solid ${color}` }
        }

        if (!disabled) {
          return { border: `1px solid ${color}` }
        }
      }

      return (
        <div className={classes.root}>
          <input
            type={type}
            id={id}
            className={classes.checkbox}
            ref={ref}
            checked={checked}
            disabled={disabled}
            {...props}
          />
          <label
            htmlFor={id}
            className={classNames(classes.label, {
              [classes.labelDisabled]: disabled && labelDisabled,
            })}
            style={getLabelStyle()}
          >
            <Icon name="checkmark" className={classes.checkmark} />
          </label>
        </div>
      )
    },
  ),
)

Checkbox.displayName = 'Checkbox'
