import { FC, useContext, useEffect } from 'react'
// components
import { Typography } from 'src/modules/core/components'
// context
import { LoadingWrapperContext } from 'src/modules/core/context'

import classes from './privacy.module.scss'

export const Privacy: FC = () => {
  const { disableLoading } = useContext(LoadingWrapperContext)

  useEffect(() => {
    disableLoading()
  }, [disableLoading])

  return (
    <div className={classes.root}>
      <Typography variant="h1" className={classes.title}>
        Privacy Policy
      </Typography>
      <Typography variant="body">Last updated: April 11, 2022</Typography>
      <br />
      <Typography variant="body">
        This Privacy Policy describes Our policies and procedures on the
        collection, use and disclosure of Your information when You use the
        Service and tells You about Your privacy rights and how the law protects
        You.We use Your Personal data to provide and improve the Service. By
        using the Service, You agree to the collection and use of information in
        accordance with this Privacy Policy.
      </Typography>
      <Typography variant="body">&nbsp;</Typography>
      <Typography variant="h3" className={classes.h3}>
        Interpretation and Definitions
      </Typography>
      <Typography variant="h4" className={classes.h4}>
        &nbsp;
      </Typography>
      <Typography variant="h4" className={classes.h4}>
        Interpretation
      </Typography>
      <Typography variant="body">
        The words of which the initial letter is capitalized have meanings
        defined under the following conditions. The following definitions shall
        have the same meaning regardless of whether they appear in singular or
        in plural.
      </Typography>
      <Typography variant="h4" className={classes.h4}>
        &nbsp;
      </Typography>
      <Typography variant="h4" className={classes.h4}>
        Definitions
      </Typography>
      <Typography variant="body">
        For the purposes of this Privacy Policy:
      </Typography>
      <ul className={classes.list}>
        <li>
          <Typography variant="body">
            <strong>
              <u>Account</u>
            </strong>
            &nbsp;means a unique account created for You to access our Service
            or parts of our Service.
          </Typography>
        </li>
        <li>
          <Typography variant="body">
            <strong>
              <u>Affiliate</u>
            </strong>
            &nbsp;means an entity that controls, is controlled by or is under
            common control with a party, where &quot;control&quot; means
            ownership of 50% or more of the shares, equity interest or other
            securities entitled to vote for election of directors or other
            managing authority.
          </Typography>
        </li>
        <li>
          <Typography variant="body">
            <strong>
              <u>Application</u>
            </strong>
            &nbsp;means the software program provided by the Company used by You
            on any electronic device, named SMS Text Reminder.
          </Typography>
        </li>
        <li>
          <Typography variant="body">
            <strong>
              <u>Company</u>
            </strong>
            &nbsp;(referred to as either &quot;the Company&quot;,
            &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this Agreement)
            refers to Grouse Software Labs Inc, 3516 Prince Edward St.
            Vancouver, BC V5V 3X8.
          </Typography>
        </li>
        <li>
          <Typography variant="body">
            <strong>
              <u>Country</u>
            </strong>
            &nbsp;refers to: British Columbia, Canada
          </Typography>
        </li>
        <li>
          <Typography variant="body">
            <strong>
              <u>Device</u>
            </strong>
            &nbsp;means any device that can access the Service such as a
            computer, a cellphone or a digital tablet.
          </Typography>
        </li>
        <li>
          <Typography variant="body">
            <strong>
              <u>Personal Data</u>
            </strong>
            &nbsp;is any information that relates to an identified or
            identifiable individual.
          </Typography>
        </li>
        <li>
          <Typography variant="body">
            <strong>
              <u>Service</u>
            </strong>
            &nbsp;refers to the Application.
          </Typography>
        </li>
        <li>
          <Typography variant="body">
            <strong>
              <u>Service Provider</u>
            </strong>
            &nbsp;means any natural or legal person who processes the data on
            behalf of the Company. It refers to third-party companies or
            individuals employed by the Company to facilitate the Service, to
            provide the Service on behalf of the Company, to perform services
            related to the Service or to assist the Company in analyzing how the
            Service is used.
          </Typography>
        </li>
        <li>
          <Typography variant="body">
            <strong>
              <u>Usage Data</u>
            </strong>
            &nbsp;refers to data collected automatically, either generated by
            the use of the Service or from the Service infrastructure itself
            (for example, the duration of a page visit).
          </Typography>
        </li>
        <li>
          <Typography variant="body">
            <strong>
              <u>You</u>
            </strong>
            &nbsp;means the individual accessing or using the Service, or the
            company, or other legal entity on behalf of which such individual is
            accessing or using the Service, as applicable.
          </Typography>
        </li>
      </ul>
      <br />
      <Typography variant="h3" className={classes.h3}>
        Collecting and Using Your Personal Data
      </Typography>
      <Typography variant="h4" className={classes.h4}>
        &nbsp;
      </Typography>
      <Typography variant="h4" className={classes.h4}>
        Types of Data Collected
      </Typography>
      <Typography variant="h3" className={classes.h3}>
        &nbsp;
      </Typography>
      <Typography variant="h4" className={classes.h4}>
        Personal Data
      </Typography>
      <Typography variant="body">
        While using Our Service, We may ask You to provide Us with certain
        personally identifiable information that can be used to contact or
        identify You. Personally identifiable information may include, but is
        not limited to:
      </Typography>
      <ul className={classes.list}>
        <li>
          <Typography variant="body">Email address</Typography>
        </li>
        <li>
          <Typography variant="body">First name and last name</Typography>
        </li>
        <li>
          <Typography variant="body">Google Calendar Data</Typography>
        </li>
        <li>
          <Typography variant="body">Usage Data</Typography>
        </li>
      </ul>

      <br />
      <Typography variant="h4" className={classes.h4}>
        Usage Data
      </Typography>
      <Typography variant="body">
        Usage Data is collected automatically when using the Service.
      </Typography>
      <br />
      <Typography variant="body">
        Usage Data may include information such as Your Device&apos;s Internet
        Protocol address (e.g. IP address), browser type, browser version, the
        pages of our Service that You visit, the time and date of Your visit,
        the time spent on those pages, unique device identifiers and other
        diagnostic data.
      </Typography>
      <br />
      <Typography variant="body">
        When You access the Service by or through a mobile device, We may
        collect certain information automatically, including, but not limited
        to, the type of mobile device You use, Your mobile device unique ID, the
        IP address of Your mobile device, Your mobile operating system, the type
        of mobile Internet browser You use, unique device identifiers and other
        diagnostic data.
      </Typography>
      <br />
      <Typography variant="body">
        We may also collect information that Your browser sends whenever You
        visit our Service or when You access the Service by or through a mobile
        device.
      </Typography>
      <br />
      <Typography variant="h4" className={classes.h4}>
        Use of Your Personal Data
      </Typography>
      <Typography variant="body">
        The Company may use Personal Data for the following purposes:
      </Typography>
      <ul className={classes.list}>
        <li>
          <Typography variant="body">
            <strong>
              <u>To provide and maintain our Service</u>
            </strong>
            , including to monitor the usage of our Service.
          </Typography>
        </li>
        <li>
          <Typography variant="body">
            <strong>
              <u>To manage Your Account</u>
            </strong>
            : to manage Your registration as a user of the Service. The Personal
            Data You provide can give You access to different functionalities of
            the Service that are available to You as a registered user.
          </Typography>
        </li>
        <li>
          <Typography variant="body">
            <strong>
              <u>For the performance of a contract</u>
            </strong>
            : the development, compliance and undertaking of the purchase
            contract for the products, items or services You have purchased or
            of any other contract with Us through the Service.
          </Typography>
        </li>
        <li>
          <Typography variant="body">
            <strong>
              <u>To contact You</u>
            </strong>
            : To contact You by email, telephone calls, SMS, or other equivalent
            forms of electronic communication, such as a mobile
            application&apos;s push notifications regarding updates or
            informative communications related to the functionalities, products
            or contracted services, including the security updates, when
            necessary or reasonable for their implementation.
          </Typography>
        </li>
        <li>
          <Typography variant="body">
            <strong>
              <u>To provide You</u>
            </strong>
            &nbsp;with news, special offers and general information about other
            goods, services and events which we offer that are similar to those
            that you have already purchased or enquired about unless You have
            opted not to receive such information.
          </Typography>
        </li>
        <li>
          <Typography variant="body">
            <strong>
              <u>To manage Your requests</u>
            </strong>
            : To attend and manage Your requests to Us.
          </Typography>
        </li>
        <li>
          <Typography variant="body">
            <strong>
              <u>For business transfers</u>
            </strong>
            : We may use Your information to evaluate or conduct a merger,
            divestiture, restructuring, reorganization, dissolution, or other
            sale or transfer of some or all of Our assets, whether as a going
            concern or as part of bankruptcy, liquidation, or similar
            proceeding, in which Personal Data held by Us about our Service
            users is among the assets transferred.
          </Typography>
        </li>
        <li>
          <Typography variant="body">
            <strong>
              <u>For other purposes</u>
            </strong>
            : We may use Your information for other purposes, such as data
            analysis, identifying usage trends, determining the effectiveness of
            our promotional campaigns and to evaluate and improve our Service,
            products, services, marketing and your experience.
          </Typography>
        </li>
      </ul>

      <Typography variant="body">&nbsp;</Typography>
      <Typography variant="body">
        We may share Your personal information in the following situations:
      </Typography>
      <ul className={classes.dottedList}>
        <li>
          <Typography variant="body">
            With Service Providers: We may share Your personal information with
            Service Providers to monitor and analyze the use of our Service, to
            contact You.
          </Typography>
        </li>
        <li>
          <Typography variant="body">
            For business transfers: We may share or transfer Your personal
            information in connection with, or during negotiations of, any
            merger, sale of Company assets, financing, or acquisition of all or
            a portion of Our business to another company.
          </Typography>
        </li>
        <li>
          <Typography variant="body">
            With Affiliates: We may share Your information with Our affiliates,
            in which case we will require those affiliates to honor this Privacy
            Policy. Affiliates include Our parent company and any other
            subsidiaries, joint venture partners or other companies that We
            control or that are under common control with Us.
          </Typography>
        </li>
        <li>
          <Typography variant="body">
            With Your consent: We may disclose Your personal information for any
            other purpose with Your consent.
          </Typography>
        </li>
      </ul>
      <Typography variant="h4" className={classes.h4}>
        &nbsp;
      </Typography>
      <Typography variant="h4" className={classes.h4}>
        Retention of Your Personal Data
      </Typography>
      <Typography variant="body">
        The Company will retain Your Personal Data only for as long as is
        necessary for the purposes set out in this Privacy Policy. We will
        retain and use Your Personal Data to the extent necessary to comply with
        our legal obligations (for example, if we are required to retain your
        data to comply with applicable laws), resolve disputes, and enforce our
        legal agreements and policies.
      </Typography>
      <br />
      <Typography variant="body">
        The Company will also retain Usage Data for internal analysis purposes.
        Usage Data is generally retained for a shorter period of time, except
        when this data is used to strengthen the security or to improve the
        functionality of Our Service, or We are legally obligated to retain this
        data for longer time periods.
      </Typography>
      <br />
      <Typography variant="h4" className={classes.h4}>
        Transfer of Your Personal Data
      </Typography>
      <Typography variant="body">
        Your information, including Personal Data, is processed at the
        Company&apos;s operating offices and in any other places where the
        parties involved in the processing are located. It means that this
        information may be transferred to &mdash; and maintained on &mdash;
        computers located outside of Your state, province, country or other
        governmental jurisdiction where the data protection laws may differ than
        those from Your jurisdiction.
      </Typography>
      <br />
      <Typography variant="body">
        Your consent to this Privacy Policy followed by Your submission of such
        information represents Your agreement to that transfer.
      </Typography>
      <br />
      <Typography variant="body">
        The Company will take all steps reasonably necessary to ensure that Your
        data is treated securely and in accordance with this Privacy Policy and
        no transfer of Your Personal Data will take place to an organization or
        a country unless there are adequate controls in place including the
        security of Your data and other personal information.
      </Typography>
      <br />
      <Typography variant="h4" className={classes.h4}>
        Disclosure of Your Personal Data
      </Typography>
      <Typography variant="h4" className={classes.h4}>
        &nbsp;
      </Typography>
      <Typography variant="h4" className={classes.h4}>
        Business Transactions
      </Typography>
      <Typography variant="body">
        If the Company is involved in a merger, acquisition or asset sale, Your
        Personal Data may be transferred. We will provide notice before Your
        Personal Data is transferred and becomes subject to a different Privacy
        Policy.
      </Typography>
      <Typography variant="h4" className={classes.h4}>
        &nbsp;
      </Typography>
      <Typography variant="h4" className={classes.h4}>
        Law enforcement
      </Typography>
      <Typography variant="body">
        Under certain circumstances, the Company may be required to disclose
        Your Personal Data if required to do so by law or in response to valid
        requests by public authorities (e.g. a court or a government agency).
      </Typography>
      <Typography variant="h4" className={classes.h4}>
        &nbsp;
      </Typography>
      <Typography variant="h4" className={classes.h4}>
        Other legal requirements
      </Typography>
      <Typography variant="body">
        The Company may disclose Your Personal Data in the good faith belief
        that such action is necessary to:
      </Typography>
      <ul className={classes.dottedList}>
        <li>
          <Typography variant="body">Comply with a legal obligation</Typography>
        </li>
        <li>
          <Typography variant="body">
            Protect and defend the rights or property of the Company
          </Typography>
        </li>
        <li>
          <Typography variant="body">
            Prevent or investigate possible wrongdoing in connection with the
            Service
          </Typography>
        </li>
        <li>
          <Typography variant="body">
            Protect the personal safety of Users of the Service or the public
          </Typography>
        </li>
        <li>
          <Typography variant="body">
            Protect against legal liability
          </Typography>
        </li>
      </ul>
      <Typography variant="h3" className={classes.h3}>
        &nbsp;
      </Typography>
      <Typography variant="h4" className={classes.h4}>
        Security of Your Personal Data
      </Typography>
      <Typography variant="body">
        The security of Your Personal Data is important to Us, but remember that
        no method of transmission over the Internet, or method of electronic
        storage is 100% secure. While We strive to use commercially acceptable
        means to protect Your Personal Data, We cannot guarantee its absolute
        security.
      </Typography>
      <Typography variant="body">&nbsp;</Typography>
      <Typography variant="h3" className={classes.h3}>
        Detailed Information on the Processing of Your Personal Data
      </Typography>
      <br />
      <Typography variant="body">
        The Service Providers We use may have access to Your Personal Data.
        These third-party vendors collect, store, use, process and transfer
        information about Your activity on Our Service in accordance with their
        Privacy Policies.
      </Typography>
      <br />
      <Typography variant="h4" className={classes.h4}>
        Email Marketing
      </Typography>
      <Typography variant="body">
        We may use Your Personal Data to contact You with newsletters, marketing
        or promotional materials and other information that may be of interest
        to You. You may opt-out of receiving any, or all, of these
        communications from Us by following the unsubscribe link or instructions
        provided in any email We send or by contacting Us.
      </Typography>
      <br />
      <Typography variant="body">
        We may use Email Marketing Service Providers to manage and send emails
        to You.
      </Typography>
      <br />
      <Typography variant="h5" className={classes.h5}>
        Mailchimp
      </Typography>
      <Typography variant="body">
        Mailchimp is an email marketing sending service provided by The Rocket
        Science Group LLC.
      </Typography>
      <Typography variant="body">
        For more information on the privacy practices of Mailchimp, please visit
        their Privacy policy:&nbsp;
        <a href="https://mailchimp.com/legal/privacy/">
          https://mailchimp.com/legal/privacy/
        </a>
      </Typography>
      <br />
      <Typography variant="h5" className={classes.h5}>
        Crisp
      </Typography>
      <Typography variant="body">
        Crisp is a marketing and customer service platform provided by Crisp IM
        SAS. For more information on the privacy practices of Crips, please
        visit their Privacy policy:&nbsp;
        <a href="https://crisp.chat/en/privacy/">
          https://crisp.chat/en/privacy/
        </a>
        &nbsp;
      </Typography>
      <br />
      <Typography variant="h4" className={classes.h4}>
        Usage, Performance and Miscellaneous
      </Typography>
      <Typography variant="body">
        We may use third-party Service Providers to provide better improvement
        of our Service.
      </Typography>
      <Typography variant="body">&nbsp;</Typography>
      <Typography variant="h5" className={classes.h5}>
        FreshDesk
      </Typography>
      <Typography variant="body">
        FreshDesk is a customer support software. The service is operated by
        Freshworks, Inc.
      </Typography>
      <Typography variant="body">
        FreshDesk service may collect information from Your Device.
      </Typography>
      <Typography variant="body">
        The information gathered by FreshDesk is held in accordance with its
        Privacy Policy:&nbsp;
        <a href="https://www.freshworks.com/privacy/">
          https://www.freshworks.com/privacy/
          <br />
          <br />
        </a>
      </Typography>
      <Typography variant="h5" className={classes.h5}>
        Google Analytics
      </Typography>
      <Typography variant="body">
        Google Analytics is analytics software operated by Google Inc. Google
        Analytics may collect information from Your Device. The information
        gathered by FreshDesk is held in accordance with its Privacy
        Policy:&nbsp;
        <a href="https://policies.google.com/privacy?hl=en-US">
          https://policies.google.com/privacy?hl=en-US
        </a>
        &nbsp;
      </Typography>
      <br />
      <Typography variant="h3" className={classes.h3}>
        Children&apos;s Privacy
      </Typography>
      <Typography variant="body">
        Our Service does not address anyone under the age of 13. We do not
        knowingly collect personally identifiable information from anyone under
        the age of 13. If You are a parent or guardian and You are aware that
        Your child has provided Us with Personal Data, please contact Us. If We
        become aware that We have collected Personal Data from anyone under the
        age of 13 without verification of parental consent, We take steps to
        remove that information from Our servers.
      </Typography>
      <br />
      <Typography variant="body">
        If We need to rely on consent as a legal basis for processing Your
        information and Your country requires consent from a parent, We may
        require Your parent&apos;s consent before We collect and use that
        information.
      </Typography>
      <Typography variant="body">&nbsp;</Typography>
      <Typography variant="h3" className={classes.h3}>
        Links to Other Websites
      </Typography>
      <Typography variant="body">
        Our Service may contain links to other websites that are not operated by
        Us. If You click on a third party link, You will be directed to that
        third party&apos;s site. We strongly advise You to review the Privacy
        Policy of every site You visit.
      </Typography>
      <Typography variant="body">
        We have no control over and assume no responsibility for the content,
        privacy policies or practices of any third party sites or services.
      </Typography>
      <br />
      <Typography variant="h3" className={classes.h3}>
        Changes to this Privacy Policy
      </Typography>
      <Typography variant="body">
        We may update Our Privacy Policy from time to time. We will notify You
        of any changes by posting the new Privacy Policy on this page.
      </Typography>
      <Typography variant="body">
        We will let You know via email and/or a prominent notice on Our Service,
        prior to the change becoming effective and update the &quot;Last
        updated&quot; date at the top of this Privacy Policy.
      </Typography>
      <br />
      <Typography variant="body">
        You are advised to review this Privacy Policy periodically for any
        changes. Changes to this Privacy Policy are effective when they are
        posted on this page.
      </Typography>
      <br />
      <Typography variant="h3" className={classes.h3}>
        Contact Us
      </Typography>
      <Typography variant="body">
        If you have any questions about this Privacy Policy, You can contact us:
      </Typography>
      <ul className={classes.dottedList}>
        <li>
          <Typography variant="body">
            By email:{' '}
            <a href="mailto:hello@gointeractive.ca">hello@gointeractive.ca</a>
          </Typography>
        </li>
      </ul>
      <br />
      <br />
    </div>
  )
}
