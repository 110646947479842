import { FC } from 'react'
// hooks
import { useCrisp } from 'src/modules/core/hooks/use-crisp'

import classes from './secondary-layout.module.scss'

export const SecondaryLayout: FC = ({ children }) => {
  useCrisp()

  return <div className={classes.root}>{children}</div>
}
