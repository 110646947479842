type Event = {
  active?: boolean
  receiver_numbers?: string[]
  suggested_numbers?: string[]
  suggestions_reviewed?: boolean | null
  accepted_suggested_numbers?: string[]
}

export class EventSuggestedNumbersHelper {
  static getPendingSuggestions(event: Event | undefined): string[] {
    return event?.accepted_suggested_numbers?.length === 0 && !event?.suggestions_reviewed
      ? event?.suggested_numbers
      : []
  }

  static isSuggestedNumber(event: Event | undefined, phone: string): boolean {
    return event?.suggested_numbers?.includes(phone) && !event?.accepted_suggested_numbers?.includes(phone)
  }

  static needsReview(event: Event | undefined): boolean {
    return (
      !event?.active &&
      event?.suggested_numbers?.length > 0 &&
      event?.accepted_suggested_numbers?.length === 0 &&
      !event?.suggestions_reviewed
    )
  }
}
