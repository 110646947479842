import { useMutation } from 'react-query'
import { ApiService } from 'src/services/api.service'

import type { ConfirmEventBodyDto } from '@grouse-software/types/event/event.api'

export const CONFIRM_EVENT_CACHE_KEY = 'confirm-event'

export const useConfirmEvent = () => {
  return useMutation(CONFIRM_EVENT_CACHE_KEY, (params: ConfirmEventBodyDto) =>
    ApiService.Confirmation.confirmEvent(params),
  )
}
