import { useQuery, UseQueryOptions } from 'react-query'
import { useHandleRetry } from 'src/modules/core/hooks/use-handle-retry'
import { ApiService } from 'src/services/api.service'

import type { BillingPlanDto } from '@grouse-software/types/billing/billing.dto'
import type { GetSubscriptionPlansDto } from '../../billing/types/billing.types'

export const GET_SUBSCRIPTION_PLANS_CACHE_KEY = 'get-subscription-plans'

export function useSubscriptionPlans(
  params?: GetSubscriptionPlansDto,
  options?: UseQueryOptions<{ data: BillingPlanDto[] }>,
) {
  const handleRetry = useHandleRetry()
  return useQuery([GET_SUBSCRIPTION_PLANS_CACHE_KEY, params], () => ApiService.Billing.getSubscriptionPlans(params), {
    staleTime: 300000,
    retry: handleRetry,
    ...options,
  })
}
