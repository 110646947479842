import { FC, useContext, useMemo } from 'react'
import { DateContext } from 'src/modules/core/context'
import { eventPreviewFields } from 'src/modules/event/constants/event-params'
import { useEventList } from 'src/modules/event/hooks/use-event-list'
import { ReminderList } from 'src/modules/reminder/components'

import type { EventPreviewWithReminders } from 'src/modules/event/types/event.types'
import type { DashboardTabProps } from 'src/modules/user/types/dashboard'

export const SentRemindersTab: FC<DashboardTabProps> = ({ isLoading }) => {
  const { setSelectedDate, startDate, endDate } = useContext(DateContext)
  const params = useMemo(
    () => ({
      expand: ['data.reminders'],
      include: ['only_with_reminders'],
      fields: eventPreviewFields,
      start_date: startDate,
      end_date: endDate,
    }),
    [startDate, endDate],
  )

  const eventList = useEventList<EventPreviewWithReminders>(params, {
    enabled: !isLoading,
  })

  const activeEventList = useMemo(() => eventList.data?.filter((event) => event.active) || [], [eventList.data])

  return (
    <ReminderList
      events={activeEventList}
      isLoading={eventList.isLoading || isLoading}
      changeSelectedDate={setSelectedDate}
    />
  )
}
