import { addDays } from 'date-fns'

import type { ListEventOptions } from '@grouse-software/types/event'

const today = new Date()
const days = 30

today.setHours(0, 0, 0, 0)

export const eventPreviewFields: string[] = [
  'id',
  'title',
  'event_id',
  'recurring_event_id',
  'calendar',
  'active',
  'event_date',
  'recurrence_rules',
  'confirmed',
  'all_day',
  'receiver_numbers',
  'suggested_numbers',
  'suggestions_reviewed',
  'accepted_suggested_numbers',
  'created_at',
  'errors',
]

export const eventDetailsParams: ListEventOptions = {
  expand: ['reminders'],
  fields: [
    'receiver_numbers',
    'default_sender_name',
    'default_message_text',
    'default_location',
    'default_time_before',
    'sender_name',
    'text',
    'location',
    'event_location',
    'errors',
  ],
}

export const currentMonthParams: ListEventOptions = {
  fields: eventPreviewFields,
  start_date: today.getTime(),
  end_date: addDays(today, days).getTime(),
}

export const prevMonthParams: ListEventOptions = {
  fields: eventPreviewFields,
  start_date: addDays(today, 1 - days).getTime(),
  end_date: addDays(today, 2).getTime(),
}
