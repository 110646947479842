import { FC, useMemo } from 'react'
// components
import { Loader } from 'src/modules/core/components'
import {
  SettingsProfileInfo,
  SettingsAppointmentConfirmation,
} from 'src/modules/user/components'
// hooks
import { useProfile } from 'src/modules/user/hooks/use-profile'

import classes from './settings-my-details-tab.module.scss'

export const SettingsMyDetailsTab: FC = () => {
  // hooks
  const profile = useProfile()
  // memo
  const isLoading = useMemo(() => profile.isLoading, [profile.isLoading])

  if (isLoading) return <Loader isLoading={isLoading} />

  return (
    <div className={classes.settingsWrapper}>
      <div className={classes.settingsWrapperChild}>
        {profile.data && <SettingsProfileInfo profileInfo={profile.data} />}
      </div>
    </div>
  )
}
