import { createContext } from 'react'

import type { DateContextValue } from './date-context.interface'

export const DateContext = createContext<DateContextValue>({
  selectedDate: null,
  setSelectedDate: () => {},
  setDaysCount: () => {},
  startDate: 0,
  nextStartDate: 0,
  prevStartDate: 0,
  endDate: 0,
  nextEndDate: 0,
  prevEndDate: 0,
  daysCount: 0,
})
