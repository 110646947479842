import { useMutation } from 'react-query'
import { useHandleRetry } from 'src/modules/core/hooks/use-handle-retry'
import { ApiService } from 'src/services/api.service'

export const SYNCHRONIZE_CALENDARS_CACHE_KEY = 'synchronize-calendars'

export const useSynchronizeCalendars = () => {
  const handleRetry = useHandleRetry({
    numberOfRetries: -1,
  })
  return useMutation(SYNCHRONIZE_CALENDARS_CACHE_KEY, () => ApiService.Calendars.synchronizeCalendars(), {
    retry: handleRetry,
  })
}
