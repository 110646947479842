import { FC, memo } from 'react'
// components
import { Icon, Typography } from 'src/modules/core/components'

import type { ErrorProps } from './error.interface'
import classes from './error.module.scss'

export const Error: FC<ErrorProps> = memo(({ text }) => (
  <div className={classes.root}>
    <div>
      <Icon name="error" />
    </div>
    <Typography variant="span">{text}</Typography>
  </div>
))

Error.displayName = 'Error'
