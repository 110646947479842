/* eslint-disable no-magic-numbers */
import { addDays } from 'date-fns'
import { FC, useMemo, useState } from 'react'
// context
import { DateContext } from 'src/modules/core/context/'

const today = new Date()

today.setHours(0, 0, 0, 0)

export const DateProvider: FC = ({ children }) => {
  // state
  const [selectedDate, setSelectedDate] = useState<Date>(today)
  const [daysCount, setDaysCount] = useState<number>(5)

  // memo
  const [startDate, nextStartDate, prevStartDate] = useMemo(() => {
    const firstDay = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1)

    firstDay.setHours(0, 0, 0, 0)

    const firstDayWithGap = addDays(firstDay, -daysCount)

    const nextFirstDay = addDays(new Date(firstDay.getFullYear(), firstDay.getMonth() + 1, 1, 0, 0, 0, 0), -daysCount)
    const prevFirstDay = addDays(new Date(firstDay.getFullYear(), firstDay.getMonth() - 1, 1, 0, 0, 0, 0), -daysCount)

    return [firstDayWithGap.getTime(), nextFirstDay.getTime(), prevFirstDay.getTime()]
  }, [daysCount, selectedDate])

  const [endDate, nextEndDate, prevEndDate] = useMemo(() => {
    const lastDay = new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 1)

    lastDay.setHours(23, 59, 59, 59)

    const lastDayWithGap = addDays(lastDay, daysCount)

    const nextLastDay = addDays(new Date(lastDay.getFullYear(), lastDay.getMonth() + 1, 1, 23, 59, 59, 59), daysCount)
    const prevLastDay = addDays(new Date(lastDay.getFullYear(), lastDay.getMonth() - 1, 1, 23, 59, 59, 59), daysCount)

    return [lastDayWithGap.getTime(), nextLastDay.getTime(), prevLastDay.getTime()]
  }, [daysCount, selectedDate])

  return (
    <DateContext.Provider
      value={{
        selectedDate,
        setSelectedDate,
        setDaysCount,
        startDate,
        nextStartDate,
        prevStartDate,
        endDate,
        nextEndDate,
        prevEndDate,
        daysCount,
      }}
    >
      {children}
    </DateContext.Provider>
  )
}
