import { useMutation } from 'react-query'
import { useHandleRetry } from 'src/modules/core/hooks/use-handle-retry'
import { ApiService } from 'src/services/api.service'

export const SYNC_CALENDARS_CACHE_KEY = 'sync-calendars'

export function useSyncCalendars() {
  const handleRetry = useHandleRetry({
    numberOfRetries: -1,
  })
  return useMutation(SYNC_CALENDARS_CACHE_KEY, () => ApiService.Calendars.syncCalendars(), {
    retry: handleRetry,
  })
}
