import { makeRequest } from 'src/helpers/request'

export const AnalyticsService = {
  track: (event: string, properties?: Record<string, any>) => {
    return makeRequest(`${process.env.REACT_APP_API_URL}/analytics/track`, 'post', {
      event,
      properties,
    })
  },
}
