import { forwardRef, memo, ReactHTML, useMemo } from 'react'
import classNames from 'classnames'

import { TypographyProps, ElementsMap } from './typography.interface'
import classes from './typography.module.scss'

const elementsMap: ElementsMap = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  body: 'p',
  span: 'span',
}

export const Typography = memo(
  forwardRef<any, TypographyProps>(
    ({ variant, className, children, element, ...props }, ref) => {
      const Root = useMemo<keyof ReactHTML>(
        () => element ?? elementsMap[variant],
        [element, variant],
      )

      return (
        <Root
          // TODO: fix ts-ignore
          // @ts-ignore
          ref={ref}
          className={classNames(classes[variant], className)}
          {...props}
        >
          {children}
        </Root>
      )
    },
  ),
)

Typography.displayName = 'Typography'
