import React, { useEffect, useRef, useState } from 'react'

type FocusAwareProps<T> = T & { hasFocus: boolean }

interface FocusAwareOptions {
  timeout?: number
}

const focusAwareComponent = <P extends object>(
  WrappedComponent: React.ComponentType<FocusAwareProps<P>>,
  options: FocusAwareOptions = { timeout: 10 },
) => {
  const { timeout } = options

  const FocusAware = (props: P) => {
    const [hasFocus, setHasFocus] = useState(false)
    const [hasBeenClicked, setHasBeenClicked] = useState(false)
    const componentRef = useRef<HTMLDivElement>(null)
    const timeoutRef = useRef<NodeJS.Timeout | null>(null)

    const handleMouseOver = () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
      setHasFocus(true)
    }

    const handleMouseOut = () => {
      if (!hasBeenClicked) {
        timeoutRef.current = setTimeout(() => {
          setHasFocus(false)
        }, timeout)
      }
    }

    const handleMouseDown = (event: MouseEvent) => {
      if (componentRef.current && componentRef.current.contains(event.target as Node)) {
        setHasBeenClicked(true)
        setHasFocus(true)
      } else {
        setHasBeenClicked(false)
        setHasFocus(false)
      }
    }

    useEffect(() => {
      document.addEventListener('mousedown', handleMouseDown)
      return () => {
        document.removeEventListener('mousedown', handleMouseDown)
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current)
        }
      }
    }, [])

    return (
      <div ref={componentRef} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
        <WrappedComponent {...props} hasFocus={hasFocus} />
      </div>
    )
  }

  // Set the display name for better debugging
  const wrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Component'
  FocusAware.displayName = `focusAware(${wrappedComponentName})`

  return FocusAware
}

export default focusAwareComponent
