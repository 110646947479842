import { useContext, useEffect } from 'react'
// containers
import { CancelPlanWrapper } from 'src/modules/payment/containers'
// context
import { LoadingWrapperContext } from 'src/modules/core/context'

import classes from './cancel-plan.module.scss'

export const CancelPlan = () => {
  // contexts
  const { disableLoading } = useContext(LoadingWrapperContext)

  useEffect(() => {
    disableLoading()
  }, [disableLoading])

  return (
    <div className={classes.root}>
      <CancelPlanWrapper />
    </div>
  )
}
