import { useQuery, UseQueryOptions } from 'react-query'
import { useHandleRetry } from 'src/modules/core/hooks/use-handle-retry'
import { ApiService } from 'src/services/api.service'

import type { BillingInvoiceDto } from '@grouse-software/types/billing/billing.dto'

export const GET_INVOICES_CACHE_KEY = 'get-invoices'

export function useInvoices(params?: {}, options?: UseQueryOptions<{ data: BillingInvoiceDto[] }>) {
  const handleRetry = useHandleRetry()

  return useQuery(GET_INVOICES_CACHE_KEY, () => ApiService.Billing.getInvoices(), {
    staleTime: 300000,
    retry: handleRetry,
    ...options,
  })
}
