import { ChangeEvent } from 'react'

export const exampleConfirmationMessage: string =
  '\nConfirm or cancel: https://www.smsreminder.co/confirmation/ZwhFHBRK2wq9sM6KGME_M'

export const maxMessageLength: number = 320
export const maxMessageLengthWithConfirmation: number = 239

export function onReminderMessageInput(event: ChangeEvent<HTMLTextAreaElement>, maxMessageLength: number) {
  if (event.target.value.length > maxMessageLength) {
    event.target.value = event.target.value.slice(0, maxMessageLength)
  }
}
