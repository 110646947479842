import { useContext, useEffect } from 'react'
// containers
import { ConfirmationWrapper } from 'src/modules/confirmation/containers'
// context
import { LoadingWrapperContext } from 'src/modules/core/context'

import classes from './confirmation.module.scss'

export const Confirmation = () => {
  const { disableLoading } = useContext(LoadingWrapperContext)

  useEffect(() => {
    disableLoading()
  }, [disableLoading])

  return (
    <div className={classes.root}>
      <ConfirmationWrapper />
    </div>
  )
}
