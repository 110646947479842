import { makePublicRequest, makeRequest } from 'src/helpers/request'

export const AuthService = {
  authorize: (code: string, extraData?: Record<string, any>) => {
    const params = new URLSearchParams({ ...extraData, code })
    return makeRequest(`${process.env.REACT_APP_API_URL}/auth/getAccessToken?${params}`, 'get')
  },

  refreshToken: (refreshToken: string) => {
    return makePublicRequest(`${process.env.REACT_APP_API_URL}/auth/refreshAccessToken`, 'post', { refreshToken })
  },
}
