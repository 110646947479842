import classNames from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    AutoReminderCallout
} from 'src/modules/auto-reminders/auto-reminder-callout/auto-reminder-callout';
import { CalendarList } from 'src/modules/calendar/components';
import { FormattedText, Icon, Typography } from 'src/modules/core/components';
import { navigation } from 'src/modules/core/constants/navigation';
import { routeNames } from 'src/modules/core/constants/routeNames';
import classes from './sidebar.module.scss';

export function Sidebar() {
  const navigate = useNavigate()
  const location = useLocation()

  return (
    <div className={classes.root}>
      <Typography variant="h2" className={classes.title}>
        <FormattedText id="common.companyName" />
      </Typography>
      <ul className={classes.sidebar}>
        {navigation.map((item) => (
          <li
            key={item.path}
            className={classNames(classes.sidebarItem, {
              [classes.active]: location.pathname === item.path,
            })}
            onClick={() => navigate(item.path)}
          >
            <Icon name={item.icon} className={classes.icon} />
            <Typography variant="body" className={classes.text}>
              {item.text}
            </Typography>
          </li>
        ))}
      </ul>
      {location.pathname === routeNames.dashboard.path && (
        <>
          <div className={classNames(classes.lineWrapper, classes.line)} />
          <div className={classes.wrapper}>
            <Typography variant="h4" className={classes.secondaryTitle}>
              <FormattedText id="calendars.dashboard.title" />
            </Typography>
          </div>
          <CalendarList listClassName={classes.calendarList} withUpgradeButton>
            <div className={classes.line} />
          </CalendarList>
        </>
      )}
      <AutoReminderCallout />
    </div>
  )
}
