import { useHasUserEvent } from 'src/modules/user-events/use-has-user-event';
import { USER_EVENTS } from 'src/modules/user-events/user-events.constants';
import { useProfile } from 'src/modules/user/hooks/use-profile';
import {
    useAutoReminderModal
} from '../auto-reminder-modal/use-auto-reminder-modal';
import classes from './auto-reminder-callout.module.scss';

export function AutoReminderCallout() {
  const { setModalOpen } = useAutoReminderModal()
  const profile = useProfile()
  const isDeactivated = useHasUserEvent(USER_EVENTS.AUTO_REMINDERS_CALLOUT_DEACTIVATED)

  function onLearnMoreClick(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void {
    e.preventDefault()
    setModalOpen(true)
  }

  if (profile.isLoading || profile.data?.usage_mode !== 'customer') {
    return null
  }

  if (isDeactivated.isLoading || isDeactivated.data) {
    return null
  }

  return (
    <div className={classes.callout}>
      <h4>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/recordatorio-de-cita.appspot.com/o/did-you-know-question.svg?alt=media&token=2a2d501e-ea31-43d2-aab4-5602ebd2cb92"
          alt="Question mark"
          aria-hidden
        />
        Did you know?
      </h4>
      <p>You can set reminders from any calendar app, including your phone?</p>
      <p>
        <a href="#" onClick={onLearnMoreClick}>
          Learn more
        </a>
      </p>
    </div>
  )
}
