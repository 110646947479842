import { FC, useCallback, useContext, useMemo, useState } from 'react'
import { DateContext } from 'src/modules/core/context'
import { EditEventModal, EventList } from 'src/modules/event/components'
import { eventDetailsParams, eventPreviewFields } from 'src/modules/event/constants/event-params'
import { useEventById } from 'src/modules/event/hooks/use-event-by-id'
import { useEventList } from 'src/modules/event/hooks/use-event-list'
import { useUpdateEvent } from 'src/modules/event/hooks/use-update-event'
import { useProfile } from 'src/modules/user/hooks/use-profile'

import type {
  EventData,
  EventDetails,
  EventPreview,
  ExtendEventPreview,
  UpdateExtendEventBodyDto,
} from 'src/modules/event/types/event.types'
import type { DashboardTabProps } from 'src/modules/user/types/dashboard'

export const FutureEventsTab: FC<DashboardTabProps> = ({ isLoading }) => {
  const { setSelectedDate, startDate, endDate } = useContext(DateContext)

  const params = useMemo(
    () => ({
      fields: eventPreviewFields,
      start_date: startDate,
      end_date: endDate,
    }),
    [startDate, endDate],
  )

  const eventList = useEventList<EventPreview>(params, { enabled: !isLoading })
  const profile = useProfile()
  const updateEvent = useUpdateEvent(params)

  const [editableEventPreview, setEditableEventPreview] = useState<ExtendEventPreview>(null)

  const eventDetails = useEventById<EventDetails>(editableEventPreview?.id, eventDetailsParams)

  const isEventDetailsLoading = eventDetails.isLoading || eventDetails.isRefetching

  const editableEvent = useMemo<EventData>(() => {
    if (!editableEventPreview || !eventDetails.data) return null

    return { ...editableEventPreview, ...eventDetails.data }
  }, [editableEventPreview, eventDetails.data])

  const handleSave = useCallback(
    (id: string, data: UpdateExtendEventBodyDto) => {
      updateEvent.mutate(data)
      setEditableEventPreview(null)
    },
    [updateEvent],
  )

  const onEventClick = useCallback((eventPreview: ExtendEventPreview) => {
    setEditableEventPreview(eventPreview)
  }, [])

  const onSwitchChange = useCallback(
    (event: EventData) => {
      let time_before = profile.data.default_time_before

      if (event?.reminders?.data?.length > 0) {
        time_before = event.reminders.data.map((r) => r.time_before)
      }

      updateEvent.mutate({
        id: event.id,
        active: event.active,
        time_before,
      })
    },
    [profile.data, updateEvent],
  )

  return (
    <>
      <EventList
        events={eventList.data ?? []}
        onCardClick={onEventClick}
        onSwitchChange={onSwitchChange}
        changeSelectedDate={setSelectedDate}
        isLoading={eventList.isLoading || isLoading}
      />
      {(editableEvent || isEventDetailsLoading) && (
        <EditEventModal
          profileData={profile.data}
          event={editableEvent}
          onClose={() => setEditableEventPreview(null)}
          handleSave={handleSave}
          isLoading={isEventDetailsLoading}
        />
      )}
    </>
  )
}
