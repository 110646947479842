import classNames from 'classnames'
import { format } from 'date-fns'
import Tooltip from 'rc-tooltip'
import { ChangeEvent, MouseEvent, useMemo } from 'react'
import { NestedValue } from 'react-hook-form'
import { useCalendars } from 'src/modules/calendar/hooks/use-calendars'
import { confirmationVariants } from 'src/modules/confirmation/constants/confirmation-variants'
import { FormattedText, Icon, ToggleSwitch, Typography } from 'src/modules/core/components'
import focusAwareComponent from 'src/modules/core/utils/focus-aware-component'
import { useProfile } from 'src/modules/user/hooks/use-profile'
import { Box } from '@material-ui/core'
import { ExtendEventPreview } from '../../types/event.types'
import { EventCardPhoneFields } from './event-card-phone-fields'
import { EventCardStatus } from './event-card-status'
import classes from './event-card.module.scss'

export type EventCardVariant = 'finished' | 'current'
type OnChangeHandler = (e: ChangeEvent<HTMLInputElement>) => void

export type EventCardProps = {
  onSwitchChange?: OnChangeHandler
  variant: EventCardVariant
  event: ExtendEventPreview
  eventRepresentsReminder?: boolean
  showBadge?: boolean
  showSwitch?: boolean
  showStatus?: boolean
  showPhoneInputs?: boolean
  isLoading?: boolean
  hasFocus?: boolean
}

export type ReceiverNumber = {
  id: string
  phone: string
  country: string
}

export type EditEventForm = {
  dummy?: string
  receiverNumbers: NestedValue<ReceiverNumber[]>
}

const offsetBottom = -20

export const EventCard = focusAwareComponent<EventCardProps>(
  ({
    variant,
    event,
    eventRepresentsReminder = false,
    showBadge = true,
    showSwitch = true,
    showStatus = true,
    showPhoneInputs = true,
    onSwitchChange,
    isLoading,
    hasFocus,
  }) => {
    const profile = useProfile()
    const calendars = useCalendars()

    const eventDate = useMemo(() => new Date(parseInt(event.event_date, 10)), [event.event_date])

    const isPastEvent = useMemo(() => new Date() > eventDate, [eventDate])
    const hideSwitch = useMemo(() => !showSwitch || isPastEvent, [showSwitch, isPastEvent])

    const acceptanceVariant = useMemo(() => {
      if (!showBadge || !event.active || event.confirmed === null || (event.confirmed === 'pending' && isPastEvent)) {
        return null
      }

      if (event.confirmed === 'pending') {
        return confirmationVariants.waiting
      }

      if (event.confirmed === 'confirmed') {
        return confirmationVariants.accept
      }

      if (event.confirmed === 'cancelled') {
        return confirmationVariants.reject
      }

      return null
    }, [showBadge, event.active, event.confirmed, isPastEvent])

    const parentCalendar = useMemo(
      () => calendars.data?.find((calendar) => calendar.id === event.calendar),
      [calendars.data, event.calendar],
    )

    return (
      <div
        className={classNames(classes.root, {
          [classes.clickable]: variant === 'current',
        })}
      >
        {acceptanceVariant && (
          <Tooltip
            placement="top"
            mouseEnterDelay={0.1}
            motion={{ motionName: 'example' }}
            align={{ offset: [0, offsetBottom] }}
            overlayClassName={classes.tooltipOverlay}
            overlay={
              <Typography variant="body" className={classes.bodyText}>
                {acceptanceVariant === confirmationVariants.accept && (
                  <FormattedText id="confirmation.tooltip.accept" />
                )}
                {acceptanceVariant === confirmationVariants.waiting && (
                  <FormattedText id="confirmation.tooltip.pending" />
                )}
                {acceptanceVariant === confirmationVariants.reject && (
                  <FormattedText id="confirmation.tooltip.cancel" />
                )}
              </Typography>
            }
          >
            <div
              className={classNames(classes.statusCircle, {
                [classes.statusCircleAccept]: acceptanceVariant === confirmationVariants.accept,
                [classes.statusCircleWaiting]: acceptanceVariant === confirmationVariants.waiting,
                [classes.statusCircleRejected]: acceptanceVariant === confirmationVariants.reject,
              })}
            >
              {acceptanceVariant === confirmationVariants.accept && <Icon name="markAccepted" />}
              {acceptanceVariant === confirmationVariants.waiting && <Icon name="markWaiting" />}
              {acceptanceVariant === confirmationVariants.reject && <Icon name="markRejected" />}
            </div>
          </Tooltip>
        )}
        <div className={classes.statusAndSwitchContainer}>
          {showStatus && (
            <div>
              <EventCardStatus event={event} eventDateIsReminderDate={eventRepresentsReminder} />
            </div>
          )}
          {!hideSwitch && (
            <div className={classes.flexWrapper}>
              {isLoading && (
                <div className={classes.spinnerFlexWrapper}>
                  <div className={classes.spinnerWrapper}>
                    <div className={classes.spinner}>
                      <div />
                    </div>
                  </div>
                </div>
              )}
              <div className={classes.toggleWrapper} onClick={(e: MouseEvent) => e.stopPropagation()}>
                {variant === 'current' ? (
                  <ToggleSwitch
                    checked={event.active}
                    onChange={onSwitchChange}
                    readOnly={!onSwitchChange || (isPastEvent && !event.active)}
                    disabled={isLoading}
                    variant="primary"
                  />
                ) : (
                  <Icon name="successCheckmark" />
                )}
              </div>
            </div>
          )}
        </div>
        <div>
          <div className={classes.titleWrapper}>
            <div className={classes.circle} style={parentCalendar ? { backgroundColor: parentCalendar.color } : {}} />
            <p className={classes.title} title={event.title}>
              {event.title || <FormattedText id="reminder.noTitle" />}
            </p>
          </div>
        </div>
        <div className={classes.flexWrapper}>
          <Typography variant="body" className={classes.regular}>
            {eventRepresentsReminder ? 'Reminder sent at ' : ''}
            {event.all_day ? <FormattedText id="reminder.allDay" /> : format(eventDate, 'h:mm a')}
          </Typography>
        </div>

        {showPhoneInputs && profile.data.usage_mode === 'customer' && !event.active && !isPastEvent && (
          <EventCardPhoneFields profile={profile.data} event={event} hasFocus={hasFocus} />
        )}

        {hasFocus && event.errors.length > 0 && event.active && (
          <Box display={'flex'} flexDirection={'row'} alignItems={'center'} gridGap={3} marginTop={1}>
            <Icon name="eventCardError" />
            <span className={classes.error}>{event.errors.join(', ')}</span>
          </Box>
        )}
      </div>
    )
  },
)
