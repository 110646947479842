import { useMutation, useQueryClient } from 'react-query'
import { GET_INVOICES_CACHE_KEY } from 'src/modules/payment/hooks/use-invoices'
import { GET_SUBSCRIPTION_CACHE_KEY } from 'src/modules/payment/hooks/use-subscription'
import { ApiService } from 'src/services/api.service'

import type { BillingSubscriptionDto, ChangePaddleSubscriptionPlanBody } from '@grouse-software/types/billing'

export const CHANGE_SUBSCRIPTION_CACHE_KEY = 'change-subscription'

export function useChangeSubscription() {
  const queryClient = useQueryClient()
  return useMutation(
    CHANGE_SUBSCRIPTION_CACHE_KEY,
    (params: ChangePaddleSubscriptionPlanBody) => ApiService.Billing.changeSubscription(params),
    {
      onMutate: async (newData: ChangePaddleSubscriptionPlanBody) => {
        if ('plan_id' in newData) {
          await queryClient.cancelQueries(GET_SUBSCRIPTION_CACHE_KEY)

          queryClient.setQueryData(GET_SUBSCRIPTION_CACHE_KEY, (prev: BillingSubscriptionDto) => ({
            ...prev,
            plan_id: +newData.plan_id,
          }))
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(GET_SUBSCRIPTION_CACHE_KEY)
        queryClient.invalidateQueries(GET_INVOICES_CACHE_KEY)
      },
    },
  )
}
