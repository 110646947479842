import { useQuery, UseQueryOptions } from 'react-query'
import { useHandleRetry } from 'src/modules/core/hooks/use-handle-retry'
import { ApiService } from 'src/services/api.service'
import { UserEvent } from './user-event.interface'
import { UserEventType } from './user-events.constants'

export const LIST_USER_EVENTS_CACHE_KEY = 'list-user-events'

export const useListUserEvents = (filter?: UserEventType[], options?: UseQueryOptions<UserEvent[]>) => {
  const handleRetry = useHandleRetry()
  return useQuery([LIST_USER_EVENTS_CACHE_KEY, filter], () => ApiService.UserEvents.listUserEvents(filter), {
    staleTime: 5 * 60 * 1000 /* 5 min */,
    retry: handleRetry,
    ...options,
  })
}
