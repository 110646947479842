import { FC, Fragment } from 'react'
// components
import { FormattedText, Typography } from 'src/modules/core/components'

import type { AppointmentConfirmationStepsProps } from './appointment-confirmation-steps.interface'
import classes from './appointment-confirmation-steps.module.scss'

export const AppointmentConfirmationSteps: FC<
  AppointmentConfirmationStepsProps
> = ({ steps }) => {
  return (
    <div className={classes.root}>
      <div className={classes.stepper}>
        {steps.map((_, index) => (
          <Fragment key={index}>
            <div className={classes.circle} />
            {index !== steps.length - 1 && (
              <div className={classes[`line${index + 1}`]} />
            )}
          </Fragment>
        ))}
      </div>
      <div className={classes.steps}>
        {steps.map(({ image, text, ...rest }, i) => (
          <div className={classes.step} key={i}>
            <img
              className={classes.image}
              src={image}
              alt={`Step ${i + 1}`}
              style={rest}
            />
            <Typography variant="span" className={classes.text}>
              <FormattedText id={text} />
            </Typography>
          </div>
        ))}
      </div>
    </div>
  )
}
