import classNames from 'classnames'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useQueryClient } from 'react-query'
import { CSSTransition } from 'react-transition-group'
import { CalendarSelectionModal } from 'src/modules/calendar/components'
import { useBulkUpdateCalendars } from 'src/modules/calendar/hooks/use-bulk-update-calendars'
import { useCalendars } from 'src/modules/calendar/hooks/use-calendars'
import { isCalendarLimitExceeded } from 'src/modules/calendar/utils/helpers'
import { FormattedText, Icon, Loader, PageWrapper, Typography } from 'src/modules/core/components'
import { GET_EVENT_LIST_CACHE_KEY } from 'src/modules/event/hooks/use-event-list'
import { useSyncEvents } from 'src/modules/event/hooks/use-sync-events'
import { useSyncJobStatus } from 'src/modules/event/hooks/use-sync-job-status'
import { usePlanData } from 'src/modules/payment/hooks/use-plan-data'
import { useSubscription } from 'src/modules/payment/hooks/use-subscription'
import { dashboardTabs } from 'src/modules/user/constants/dashboardTabs'
import { paramsWithScheduledReminders } from 'src/modules/user/constants/profile-params'
import { useGoogleProfile } from 'src/modules/user/hooks/use-google-profile'
import { GET_PROFILE_CACHE_KEY, useProfile } from 'src/modules/user/hooks/use-profile'
import classes from './dashboard-wrapper.module.scss'
import { PaymentWidget } from './payment-widget'

import type { UpdateCalendarBodyDto } from '@grouse-software/types/calendar'

export const DashboardWrapper: FC = () => {
  const queryClient = useQueryClient()
  const googleProfile = useGoogleProfile()
  const profile = useProfile(paramsWithScheduledReminders)
  const calendars = useCalendars()
  const bulkUpdateCalendars = useBulkUpdateCalendars()
  const syncEvents = useSyncEvents()
  const syncJobStatus = useSyncJobStatus(profile.data?.job_key)

  const subscriptionOptions = {
    enabled: Boolean(profile.data?.billing_subscription_id),
  }
  const subscription = useSubscription({}, subscriptionOptions)
  const { currentPlan, loading } = usePlanData({
    subscriptionOptions,
  })

  const [tabIndex, setTabIndex] = useState(0)
  const [prevTabIndex, setPrevTabIndex] = useState(0)
  const [isCalendarSelectionModalOpen, setIsCalendarSelectionModalOpen] = useState(false)

  const isLoading = useMemo(
    () =>
      googleProfile.isLoading ||
      profile.isLoading ||
      subscription.isLoading ||
      calendars.isLoading ||
      bulkUpdateCalendars.isLoading ||
      loading,
    [
      googleProfile.isLoading,
      profile.isLoading,
      subscription.isLoading,
      calendars.isLoading,
      bulkUpdateCalendars.isLoading,
      loading,
    ],
  )

  const isSyncEventsCalled = syncEvents.isSuccess || syncEvents.isError
  const isSyncJobComplete = syncJobStatus.data?.status === 'completed'

  const defaultSelectedCalendars = useMemo(
    () => calendars.data?.filter((calendar) => calendar.selected) || [],
    [calendars.data],
  )

  useEffect(() => {
    syncEvents.mutate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isSyncJobComplete) {
      // Invalidate all event list queries
      queryClient.invalidateQueries({
        queryKey: [GET_EVENT_LIST_CACHE_KEY],
      })
      queryClient.invalidateQueries([GET_PROFILE_CACHE_KEY])
    }
  }, [isSyncJobComplete, queryClient])

  useEffect(() => {
    if (isSyncEventsCalled) {
      queryClient.invalidateQueries([GET_PROFILE_CACHE_KEY])
      syncEvents.reset()
    }
  }, [isSyncEventsCalled, queryClient, syncEvents])

  useEffect(() => {
    if (isCalendarLimitExceeded(currentPlan, defaultSelectedCalendars)) {
      setIsCalendarSelectionModalOpen(true)
    }
  }, [currentPlan, defaultSelectedCalendars])

  useEffect(() => {
    if (isCalendarLimitExceeded(currentPlan, defaultSelectedCalendars)) {
      setIsCalendarSelectionModalOpen(true)
    }
  }, [currentPlan, defaultSelectedCalendars])

  const handleTabChange = (index: number) => () => {
    setTabIndex((prev) => {
      setPrevTabIndex(prev)
      return index
    })
  }

  const onSelectCalendars = useCallback(
    (selectedCalendars: UpdateCalendarBodyDto[]) => {
      const unselectedCalendars: UpdateCalendarBodyDto[] = defaultSelectedCalendars.reduce((acc, calendar) => {
        if (!selectedCalendars.some((item) => item.id === calendar.id)) {
          acc.push({ id: calendar.id, selected: false })
        }
        return acc
      }, [])

      bulkUpdateCalendars.mutate([...selectedCalendars, ...unselectedCalendars], {
        onSuccess: () => {
          setIsCalendarSelectionModalOpen(false)
          syncEvents.mutate()
        },
      })
    },
    [bulkUpdateCalendars, defaultSelectedCalendars, syncEvents],
  )

  if (isLoading)
    return (
      <div className={classes.loaderWrapper}>
        <Loader isLoading={isLoading} />
      </div>
    )

  return (
    <PageWrapper
      title={
        <FormattedText
          id="dashboard.title"
          values={{
            name: googleProfile.data?.given_name || googleProfile.data?.name,
          }}
        />
      }
      className={classes.wrapper}
      titleClassName={classes.title}
      wrapperClassName={classes.primaryWrapper}
      profileInfoClassName={classes.profileInfo}
      authInfo
    >
      <div className={classes.root}>
        <div className={classes.top}>
          <div className={classes.heading}>
            <Typography variant="body" className={classes.description}>
              <FormattedText id="dashboard.description" />
            </Typography>
          </div>
          <div className={classes.tabsWrapper}>
            <PaymentWidget
              profile={profile.data}
              subscription={subscription.data}
              currentPlan={currentPlan}
              isRefetching={profile.isRefetching}
            />
            <div className={classes.tabs}>
              {dashboardTabs.map((tab, index) => (
                <div
                  key={index}
                  className={classNames(classes.tab, {
                    [classes.selected]: tabIndex === index,
                  })}
                  onClick={handleTabChange(index)}
                >
                  <div className={classes.icon}>
                    <Icon name={tab.icon} />
                  </div>
                  <Typography variant="body" style={{ color: tab.tabColor }} className={classes.tabTitle}>
                    {tab.title}
                  </Typography>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className={classes.flex}>
          {dashboardTabs.map(({ component: Component }, index) => (
            <CSSTransition
              key={index}
              in={tabIndex === index}
              timeout={500}
              classNames={{
                enter: prevTabIndex < index ? classes.rightEnter : classes.leftEnter,
                enterActive: prevTabIndex < index ? classes.rightEnterActive : classes.leftEnterActive,
                exit: tabIndex < index ? classes.rightExit : classes.leftExit,
                exitActive: tabIndex < index ? classes.rightExitActive : classes.leftExitActive,
              }}
              unmountOnExit
            >
              <div className={classes.content}>
                <Component isLoading={syncEvents.isLoading} />
              </div>
            </CSSTransition>
          ))}
        </div>
      </div>
      {isCalendarSelectionModalOpen && (
        <CalendarSelectionModal subscriptionPlan={currentPlan} onButtonClick={onSelectCalendars} />
      )}
    </PageWrapper>
  )
}
