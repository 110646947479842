import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useBulkUpdateCalendars } from 'src/modules/calendar/hooks/use-bulk-update-calendars'
// components
import { FormattedText, InfoModal, Loader, Typography } from 'src/modules/core/components'
// constants
import { routeNames } from 'src/modules/core/constants/routeNames'
// context
import { LoadingWrapperContext } from 'src/modules/core/context'
import { SubscriptionSelector } from 'src/modules/payment/components'
// constants
import { billingTypes, billingTypeSet } from 'src/modules/payment/constants/payment.constants'
import { useSubscription } from 'src/modules/payment/hooks/use-subscription'
import { useSubscriptionPlans } from 'src/modules/payment/hooks/use-subscription-plans'
// types
import { SubscriptionStatus } from 'src/modules/payment/types/payment.types'
// hooks
import { useProfile } from 'src/modules/user/hooks/use-profile'
import classes from './canceled-subscription.module.scss'

import type { BillingPlanDto } from '@grouse-software/types/billing/billing.dto'
export const CanceledSubscription = () => {
  // state
  const [selectedPlan, setSelectedPlan] = useState<BillingPlanDto>(null)
  const [isTimeoutLoading, setIsTimeoutLoading] = useState(false)
  const [isReadyForRedirect, setIsReadyForRedirect] = useState(true)
  // hooks
  const navigate = useNavigate()
  const profile = useProfile()
  const subscriptionOptions = {
    enabled: Boolean(profile.data?.billing_subscription_id),
  }
  const subscription = useSubscription({}, subscriptionOptions)
  const subscriptionPlans = useSubscriptionPlans()
  const bulkUpdateCalendars = useBulkUpdateCalendars()
  // contexts
  const { disableLoading } = useContext(LoadingWrapperContext)
  // memo
  const isLoading = useMemo(
    () => isTimeoutLoading || subscriptionPlans.isLoading || profile.isLoading || subscription.isLoading,
    [isTimeoutLoading, profile.isLoading, subscription.isLoading, subscriptionPlans.isLoading],
  )

  useEffect(() => {
    disableLoading()
  }, [disableLoading])

  useEffect(() => {
    if (isReadyForRedirect) {
      if (subscription.data?.state === SubscriptionStatus.deleted) {
        if (Date.now() < parseInt(profile.data?.billing_cancellation_effective_date, 10)) {
          navigate(routeNames.dashboard.path, { replace: true })
        }
      } else {
        navigate(routeNames.dashboard.path, { replace: true })
      }
    }
  }, [navigate, subscription.data?.state, isReadyForRedirect, profile.data?.billing_cancellation_effective_date])

  const onSelectPlan = useCallback(
    (plan: BillingPlanDto, selectedCalendarIds: string[]) => () => {
      window.Paddle.Checkout.open({
        product: plan.paddle_id,
        email: subscription.data?.user_email,
        passthrough: JSON.stringify({ user_id: profile.data?.id }),
        successCallback: () => {
          setIsReadyForRedirect(false)
          setIsTimeoutLoading(true)
          const timeout = 3000

          setTimeout(async () => {
            bulkUpdateCalendars.mutate(
              selectedCalendarIds.map((id) => ({ id, selected: true })),
              {
                onSuccess: async () => {
                  await profile.refetch()
                  await subscription.refetch()
                  setSelectedPlan(plan)
                  setIsTimeoutLoading(false)
                },
              },
            )
          }, timeout)
        },
      })
    },
    [bulkUpdateCalendars, profile, subscription],
  )

  const onSuccess = useCallback(() => {
    setIsReadyForRedirect(true)
    navigate(routeNames.dashboard.path, { replace: true })
  }, [navigate])

  if (isLoading) return <Loader isLoading={isLoading} />

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <Typography variant="h1" className={classes.title}>
          <FormattedText id="payment.subscription.canceled.title" />
        </Typography>
        <Typography variant="body" className={classes.description}>
          <FormattedText id="payment.subscription.canceled.description" />
        </Typography>
      </div>
      <SubscriptionSelector onSelectPlan={onSelectPlan} buttonClassName={classes.button} />
      {selectedPlan && (
        <InfoModal
          title={<FormattedText id="payment.subscription.canceled.success.title" />}
          description={
            <FormattedText
              id="payment.subscription.canceled.success.description"
              values={{
                strong: (chunks) => <strong>{chunks}</strong>,
                plan: selectedPlan.name,
                billingType:
                  billingTypeSet[
                    selectedPlan.billing_type === billingTypes.longInterval
                      ? billingTypes.longInterval
                      : billingTypes.shortInterval
                  ],
                amount: subscription.data?.next_payment?.amount,
                nextBillDate: subscription.data?.next_payment?.date,
              }}
            />
          }
          button={<FormattedText id="payment.subscription.success.button" />}
          onClick={onSuccess}
          onClose={() => setSelectedPlan(null)}
        />
      )}
    </div>
  )
}
