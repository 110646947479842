import { FC, memo } from 'react'
import classNames from 'classnames'
// components
import { FormattedText, Icon, Typography } from 'src/modules/core/components'

import type { SettingsBlockWrapperProps } from './settings-block-wrapper.interface'
import classes from './settings-block-wrapper.module.scss'

export const SettingsBlockWrapper: FC<SettingsBlockWrapperProps> = memo(
  ({ title, onClick, children, onTooltipClick }) => {
    return (
      <div>
        <div className={classes.blockTitle}>
          {onTooltipClick ? (
            <div className={classes.tooltipLink}>
              <Typography
                variant="h2"
                className={classNames(classes.title, classes.tooltipText)}
              >
                {title}
              </Typography>
              <sup onClick={onTooltipClick}>
                <Icon name="questionMark" />
              </sup>
            </div>
          ) : (
            <Typography variant="h2" className={classes.title}>
              {title}
            </Typography>
          )}
          {onClick && (
            <Typography
              variant="h2"
              className={classNames(classes.title, classes.clickableText)}
              onClick={onClick}
            >
              <FormattedText id="settings.button.change" />
            </Typography>
          )}
        </div>
        {children}
      </div>
    )
  },
)

SettingsBlockWrapper.displayName = 'SettingsBlockWrapper'
