import { FC } from 'react'
// components
import { Sidebar } from 'src/modules/core/components'
// hooks
import { useCrisp } from 'src/modules/core/hooks/use-crisp'

import classes from './main-layout.module.scss'

export const MainLayout: FC = ({ children }) => {
  useCrisp()

  return (
    <div className={classes.root}>
      <Sidebar />
      <div className={classes.content}>{children}</div>
    </div>
  )
}
