import { forwardRef, memo } from 'react'
import classNames from 'classnames'
import uniqid from 'uniqid'
// components
import { Typography } from 'src/modules/core/components'

import type { TextFieldProps } from './text-field.interface'
import classes from './text-field.module.scss'

export const TextField = memo(
  forwardRef<HTMLInputElement & HTMLTextAreaElement, TextFieldProps>(
    (
      {
        Element = 'input',
        id = uniqid(),
        error,
        label,
        className,
        wrapperClassName,
        labelClassName,
        children,
        variant = 'primary',
        ...props
      },
      ref,
    ) => {
      return (
        <div className={classNames(classes.root, wrapperClassName)}>
          {label && (
            <label htmlFor={id}>
              <div
                className={classNames(classes.labelWrapper, {
                  [classes.single]: !children,
                })}
              >
                <Typography
                  className={classNames(classes.label, labelClassName, {
                    [classes.labelError]: !!error,
                    [classes.labelSecondary]: variant === 'secondary',
                  })}
                  variant="body"
                >
                  {label}
                </Typography>
                {children}
              </div>
            </label>
          )}
          <Element
            className={classNames(classes.input, className, {
              [classes.textarea]: Element === 'textarea',
              [classes.inputSecondary]: variant === 'secondary',
              [classes.inputError]: !!error,
            })}
            ref={ref}
            type={Element}
            id={id}
            {...props}
          />
          {error && (
            <Typography className={classes.error} variant="body">
              {error}
            </Typography>
          )}
        </div>
      )
    },
  ),
)

TextField.displayName = 'TextField'
