import { FC, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedText, Icon } from 'src/modules/core/components';
import { ConfigurationStepContext } from 'src/modules/core/context';
import {
    useOptimisticUpdateProfile
} from 'src/modules/user/hooks/use-optimistic-update-profile';
import * as amplitude from '@amplitude/analytics-browser';
import { Container, Grid } from '@material-ui/core';
import { UsageMode } from '../../types/user-api.types';
import ChooseModeCard from './choose-mode-card';

interface ProfileFormData {
  usageMode: UsageMode
}

export const ChooseModeForm: FC = () => {
  const { handleSubmit, register, setValue } = useForm<ProfileFormData>()
  const { handleNext } = useContext(ConfigurationStepContext)
  const optimisticUpdateProfile = useOptimisticUpdateProfile()

  const onSubmit = ({ usageMode }: ProfileFormData) => {
    amplitude.track(usageMode === 'personal' ? 'Onboarding_selectPersonal' : 'Onboarding_selectCustomer')
    optimisticUpdateProfile.mutate({ usage_mode: usageMode }, { onSuccess: () => handleNext() })
  }

  return (
    <Container maxWidth="md" className="translate">
      <form onSubmit={handleSubmit(onSubmit)}>
        <input type="hidden" {...register('usageMode')} />
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <ChooseModeCard
              image={<Icon name="usageModePersonal" />}
              title={<FormattedText id="chooseMode.personal.title" />}
              description={<FormattedText id="chooseMode.personal.description" />}
              howItWorks={<FormattedText id="chooseMode.personal.howItWorks" />}
              buttonText={<FormattedText id="chooseMode.personal.button" />}
              onClick={() => setValue('usageMode', 'personal')}
              isLoading={optimisticUpdateProfile.isLoading}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ChooseModeCard
              image={<Icon name="usageModeCustomer" />}
              title={<FormattedText id="chooseMode.customer.title" />}
              description={<FormattedText id="chooseMode.customer.description" />}
              howItWorks={<FormattedText id="chooseMode.customer.howItWorks" />}
              buttonText={<FormattedText id="chooseMode.customer.button" />}
              onClick={() => setValue('usageMode', 'customer')}
              isLoading={optimisticUpdateProfile.isLoading}
            />
          </Grid>
        </Grid>
      </form>
    </Container>
  )
}
