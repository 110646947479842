import { createContext } from 'react'

import type { LoadingWrapperContextValue } from './loading-wrapper-context.interface'

export const LoadingWrapperContext = createContext<LoadingWrapperContextValue>({
  isLoading: true,
  loadingMessage: '',
  setLoadingMessage: () => {},
  toggleLoading: () => {},
  enableLoading: () => {},
  disableLoading: () => {},
})
