import { DashboardTab } from 'src/modules/user/types/dashboard'
import { SentRemindersTab } from 'src/modules/reminder/containers'
import {
  EnabledRemindersTab,
  FutureEventsTab,
  UnsetEventsTab,
} from 'src/modules/event/containers'

export const dashboardTabs: DashboardTab[] = [
  {
    icon: 'schedule',
    tabColor: '#5777DA',
    title: 'All Appointments',
    description: 'in your calendar',
    component: FutureEventsTab,
  },
  {
    icon: 'bell',
    tabColor: '#55AFB0',
    title: 'Active Reminders',
    description: 'next 30 days',
    component: EnabledRemindersTab,
  },
  {
    icon: 'warning',
    tabColor: '#59A6DE',
    title: 'Unset Appointments',
    description: 'without reminders',
    component: UnsetEventsTab,
  },
  {
    icon: 'phone',
    tabColor: '#908EF2',
    title: 'Sent Reminders',
    description: 'sent in last 30 days',
    component: SentRemindersTab,
  },
]
