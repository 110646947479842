// constants
import { routeNames } from 'src/modules/core/constants/routeNames'

// types
import type { Navigation } from 'src/modules/core/types/navigation.types'

export const navigation: Navigation[] = [
  {
    icon: 'dashboard',
    text: 'Dashboard',
    path: routeNames.dashboard.path,
    restricted: true,
  },
  {
    icon: 'settings',
    text: 'Settings',
    path: routeNames.settings.path,
    restricted: true,
  },
]
