import { addDays, addMilliseconds, addMinutes, differenceInDays, format, isToday as isTodayDateFns } from 'date-fns'
import RRule, { RRuleSet, rrulestr } from 'rrule'
import { EventPreview, ExtendEventPreview } from '../types/event.types'
import { updateRruleExdates } from './helpers'

export function groupEventsByDate(
  events: EventPreview[],
  startDate: Date,
  endDate: Date,
): { date: string; isToday: boolean; events: ExtendEventPreview[] }[] {
  startDate = onlyDate(startDate)
  endDate = onlyDate(endDate)

  const totalDays = differenceInDays(endDate, startDate)

  const nonRecurringEvents = events
    .filter((event) => !event.recurrence_rules)
    .map((event) => mergeParentIfRecurringChild(event, events))

  const recurringEvents = events.filter((event) => event.recurrence_rules)

  const groupedEvents = Array.from({ length: totalDays }).map((_, i) => {
    const currentDate = addDays(startDate, i)

    // Generate occurrences only for this single day
    const dayStart = currentDate
    const dayEnd = addMinutes(addDays(currentDate, 1), -1)

    const expandedRecurringEvents = expandRecurringEventsForDay(recurringEvents, dayStart, dayEnd)
    const allEvents = [...expandedRecurringEvents, ...nonRecurringEvents]

    return {
      date: format(currentDate, 'EEE, LLL d'),
      isToday: isTodayDateFns(currentDate),
      events: allEvents
        .filter((event) => isEventOnSameDay(event.event_date, currentDate))
        .sort((event1, event2) => compareEventsByTime(event1, event2)),
    }
  })

  return groupedEvents
}

function onlyDate(date: Date) {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate())
}

function mergeParentIfRecurringChild(event: EventPreview, events: EventPreview[]): ExtendEventPreview {
  if (event.recurring_event_id !== null) {
    const parentEvent = events.find((e) => e.event_id === event.recurring_event_id)
    if (parentEvent) {
      return { ...parentEvent, ...event }
    }
  }
  return event
}

function expandRecurringEventsForDay(recurringEvents: EventPreview[], startDate: Date, endDate: Date): EventPreview[] {
  return recurringEvents.flatMap((recurringEvent) => {
    const recurrenceRuleSet = buildRuleSetForEvent(recurringEvent)
    const occurrences = recurrenceRuleSet.between(startDate, endDate, true)

    return occurrences.map((occurrenceDateUTC) => ({
      ...recurringEvent,
      event_date: applyOriginalEventTime(occurrenceDateUTC, recurringEvent.event_date).toString(),
    }))
  })
}

function buildRuleSetForEvent(recurringEvent: EventPreview): RRuleSet {
  const rules = [...recurringEvent.recurrence_rules]
  const originalEventDateUTC = new Date(parseInt(recurringEvent.event_date, 10))

  rules.push(RRule.optionsToString({ dtstart: originalEventDateUTC }))

  const recurrenceRuleSet = rrulestr(rules.join('\n')) as RRuleSet
  updateExdatesIfNeeded(recurrenceRuleSet, originalEventDateUTC, 0)

  return recurrenceRuleSet
}

function updateExdatesIfNeeded(ruleSet: RRuleSet, dtstart: Date, offset: number): void {
  const extendedRuleSet = ruleSet as RRuleSet & { _exdate: Date[] }
  if (extendedRuleSet._exdate) {
    extendedRuleSet._exdate = updateRruleExdates(dtstart, ruleSet, false, 0)
  }
}

function applyOriginalEventTime(occurrence: Date, originalEventDateString: string): number {
  const originalEventDate = new Date(parseInt(originalEventDateString, 10))

  occurrence.setUTCHours(originalEventDate.getUTCHours())
  occurrence.setUTCMinutes(originalEventDate.getUTCMinutes())
  occurrence.setUTCSeconds(originalEventDate.getUTCSeconds())
  occurrence.setUTCMilliseconds(originalEventDate.getUTCMilliseconds())

  return occurrence.getTime()
}

function isEventOnSameDay(eventDateString: string, currentDay: Date): boolean {
  const eventDate = new Date(parseInt(eventDateString, 10))
  return (
    eventDate.getFullYear() === currentDay.getFullYear() &&
    eventDate.getMonth() === currentDay.getMonth() &&
    eventDate.getDate() === currentDay.getDate()
  )
}

function compareEventsByTime(event1: EventPreview, event2: EventPreview): number {
  const time1 = parseInt(event1.event_date, 10)
  const time2 = parseInt(event2.event_date, 10)
  return time1 - time2
}
