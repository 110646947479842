import 'react-phone-input-2/lib/style.css'
import classNames from 'classnames'
import { useIntl } from 'react-intl'
import img from 'src/assets/images/onboarding-account-created.jpg'
import { Button, Modal, Typography } from 'src/modules/core/components'
import { useOptimisticUpdateProfile } from 'src/modules/user/hooks/use-optimistic-update-profile'
import { useProfile } from 'src/modules/user/hooks/use-profile'
import { Grid } from '@material-ui/core'
import classes from './account-created-modal.module.scss'

export interface AccountCreatedModalProps {
  onClick?: () => void
}

export function AccountCreatedModal({ onClick = () => {} }: AccountCreatedModalProps) {
  const { formatMessage } = useIntl()

  const profile = useProfile()
  const optimisticUpdateProfile = useOptimisticUpdateProfile()

  return (
    <Modal className={classNames(classes.modal, 'translate')}>
      <Grid container direction="row" justifyContent="center" alignItems="center" spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h3" className={classes.modalTitle}>
            {formatMessage({ id: 'onboarding.accountCreated.title' })}
          </Typography>
        </Grid>
        <Grid item>
          <p>{formatMessage({ id: 'onboarding.accountCreated.text' })}</p>
        </Grid>
        <Grid item>
          <img
            src={img}
            alt="Smiling person typing on the computer with calendars behind them"
            className={classes.image}
          />
        </Grid>
        <Grid item>
          <Button variant="primary" onClick={onClick} isLoading={optimisticUpdateProfile.isLoading}>
            {formatMessage({
              id: profile.data.refresh_token
                ? 'onboarding.accountCreated.goToDashboard'
                : 'onboarding.accountCreated.nextStep',
            })}
          </Button>
        </Grid>
      </Grid>
    </Modal>
  )
}
