import { useMutation, useQueryClient } from 'react-query'
import { GET_CALENDARS_CACHE_KEY } from 'src/modules/calendar/hooks/use-calendars'
import { GET_EVENT_LIST_CACHE_KEY } from 'src/modules/event/hooks/use-event-list'
import { ApiService } from 'src/services/api.service'

import type { CalendarDto, UpdateCalendarBodyDto } from '@grouse-software/types/calendar'

export const UPDATE_CALENDAR_CACHE_KEY = 'update-calendar'

export function useUpdateCalendar() {
  const queryClient = useQueryClient()
  return useMutation(
    UPDATE_CALENDAR_CACHE_KEY,
    (params: UpdateCalendarBodyDto) => ApiService.Calendars.updateCalendar(params),
    {
      onMutate: async (updatedCalendar: UpdateCalendarBodyDto) => {
        await queryClient.cancelQueries(GET_CALENDARS_CACHE_KEY)
        const previousData = queryClient.getQueriesData<CalendarDto[]>(GET_CALENDARS_CACHE_KEY)
        if (previousData) {
          queryClient.setQueryData<CalendarDto[] | undefined>(GET_CALENDARS_CACHE_KEY, (oldData) => {
            if (!oldData) return

            return oldData.map((item) =>
              item.id === updatedCalendar.id ? { ...item, selected: updatedCalendar.selected } : item,
            )
          })
        }
        return { previousData: [...previousData] }
      },

      onError: (_err, _variables, context) => {
        if (context?.previousData) {
          context?.previousData.forEach((prevData) => queryClient.setQueriesData(prevData[0], prevData[1]))
        }
      },

      onSuccess: (newData) => {
        if (newData) {
          queryClient.setQueryData<CalendarDto[] | undefined>(GET_CALENDARS_CACHE_KEY, (oldData) => {
            if (!oldData) return
            const updatedCalendar = newData[0] // use the first item as this hook only allows us to update one calendar
            return oldData.map((item) =>
              item.id === updatedCalendar.id ? { ...item, selected: updatedCalendar.selected } : item,
            )
          })
        }
        queryClient.invalidateQueries(GET_EVENT_LIST_CACHE_KEY)
      },
    },
  )
}
