import { makeRequest } from 'src/helpers/request'

import type { UpdateRemindersForEventsBodyDto } from '@grouse-software/types/reminder/reminder.api'

export const RemindersService = {
  updateReminders: (params: UpdateRemindersForEventsBodyDto) => {
    return makeRequest<UpdateRemindersForEventsBodyDto>(
      `${process.env.REACT_APP_API_URL}/reminders/bulk`,
      'patch',
      params,
    )
  },
}
