import { useMutation, useQueryClient } from 'react-query'
import { ApiService } from 'src/services/api.service'
import { LIST_USER_EVENTS_CACHE_KEY } from './use-list-user-events'
import { UserEventType } from './user-events.constants'

type AddUserEventData = {
  eventName: UserEventType
  once?: boolean
}

export function useAddUserEvent() {
  const queryClient = useQueryClient()
  return useMutation<void, Error, AddUserEventData>(
    ({ eventName, once }) => ApiService.UserEvents.addUserEvent(eventName, once),
    {
      onMutate: async () => {
        await queryClient.invalidateQueries([LIST_USER_EVENTS_CACHE_KEY])
      },
    },
  )
}
