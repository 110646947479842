import { useQuery, UseQueryOptions } from 'react-query'
import { UserDto } from 'src/helpers/user.dto'
import { useHandleRetry } from 'src/modules/core/hooks/use-handle-retry'
import { ApiService } from 'src/services/api.service'

import type { IDataOptions } from '@grouse-software/types/common'

export const GET_PROFILE_CACHE_KEY = 'get-profile'
export const DEFAULT_GET_PROFILE_CACHE_KEY = [GET_PROFILE_CACHE_KEY, {}]

export function useProfile(
  // eslint-disable-next-line default-param-last
  params: IDataOptions = {},
  options?: UseQueryOptions<UserDto>,
) {
  const handleRetry = useHandleRetry()

  return useQuery([GET_PROFILE_CACHE_KEY, params], () => ApiService.Profile.getProfile(params), {
    staleTime: 300000,
    retry: handleRetry,
    ...options,
  })
}
