import { useQuery, UseQueryOptions } from 'react-query'
import { TOKEN_ERROR } from 'src/modules/core/constants/errorCodes'
import { useHandleRetry } from 'src/modules/core/hooks/use-handle-retry'
import { ApiService } from 'src/services/api.service'

import type { BillingSubscriptionDto } from '@grouse-software/types/billing/billing.dto'

export const GET_SUBSCRIPTION_CACHE_KEY = 'get-subscription'

export function useSubscription(params?: {}, options?: UseQueryOptions<BillingSubscriptionDto | null>) {
  const handleRetry = useHandleRetry({
    errorCodes: [TOKEN_ERROR],
  })

  return useQuery(GET_SUBSCRIPTION_CACHE_KEY, () => ApiService.Billing.getSubscription(), {
    staleTime: 300000,
    retry: handleRetry,
    ...options,
  })
}
