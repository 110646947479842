import type { BillingPlanDto } from '@grouse-software/types/billing'
import type { CalendarDto } from '@grouse-software/types/calendar'
// types
import { CalendarsLimitType } from 'src/modules/payment/types/payment.types';
// utils
import { getCalendarsLimitType } from 'src/modules/payment/utils/helpers';

export const sortCalendars = (calendars: CalendarDto[]): CalendarDto[] => {
  if (!calendars) return []

  return [...calendars].sort((a, b) => {
    if (a.primary && !b.primary) return -1
    if (!a.primary && b.primary) return 1

    return a.title?.localeCompare(b.title)
  })
}

export const isCalendarLimitReached = (
  plan: BillingPlanDto,
  calendar: CalendarDto,
  selectedCalendars: any[],
): boolean => {
  const calendarsLimitType = getCalendarsLimitType(plan)

  return (
    (calendarsLimitType === CalendarsLimitType.default && selectedCalendars.length >= plan.calendars_limit) ||
    (calendarsLimitType === CalendarsLimitType.primary && !calendar.primary)
  )
}

export const isCalendarLimitExceeded = (plan: BillingPlanDto, selectedCalendars: any[]): boolean => {
  const calendarsLimitType = getCalendarsLimitType(plan)

  return (
    (calendarsLimitType === CalendarsLimitType.primary && selectedCalendars.length > 1) ||
    (calendarsLimitType === CalendarsLimitType.default && selectedCalendars.length > plan.calendars_limit)
  )
}
