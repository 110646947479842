import { FC, useCallback, useMemo } from 'react'
import { format } from 'date-fns'
import classNames from 'classnames'
// components
import {
  FormattedText,
  Typography,
  Table,
  Loader,
} from 'src/modules/core/components'
// hooks
import { useSubscription } from 'src/modules/payment/hooks/use-subscription'
import { useProfile } from 'src/modules/user/hooks/use-profile'
import { useInvoices } from 'src/modules/payment/hooks/use-invoices'
// utils
import { roundNumber } from 'src/modules/core/utils/helpers'
// types
import type { TableColumn, TableData } from 'src/modules/core/types/table.types'
import { SubscriptionStatus } from 'src/modules/payment/types/payment.types'

import classes from './billing-tab.module.scss'

const formatId = (n: number): string => {
  const maxValue = 10

  return n < maxValue ? `#0${n}` : `#${n}`
}

const tableColumns: TableColumn[] = [
  {
    value: <FormattedText id="payment.billing.table.invoiceId" />,
  },
  {
    value: <FormattedText id="payment.billing.table.description" />,
    width: 256,
  },
  {
    value: <FormattedText id="payment.billing.table.startDate" />,
    width: 145,
  },
  {
    value: <FormattedText id="payment.billing.table.amount" />,
    width: 149,
  },
  {
    value: <FormattedText id="payment.billing.table.status" />,
    width: 213,
  },
]

export const BillingTab: FC = () => {
  // hooks
  const profile = useProfile()
  const subscriptionOptions = {
    enabled: !!profile.data?.billing_subscription_id,
  }
  const subscription = useSubscription({}, subscriptionOptions)
  const invoices = useInvoices()
  // memo
  const isLoading = useMemo(
    () => subscription.isLoading || invoices.isLoading,
    [subscription.isLoading, invoices.isLoading],
  )

  const isLastPaymentSuccessful =
    subscription.data?.state !== SubscriptionStatus.past_due

  const tableData: TableData[] = useMemo(
    () =>
      Array.isArray(invoices.data?.data) &&
      [...invoices.data.data]
        .filter((invoice) => invoice.transaction)
        .sort(
          (a, b) =>
            new Date(b.transaction.created_at).getTime() -
            new Date(a.transaction.created_at).getTime(),
        )
        .map((invoice, index, array) => {
          const isPaid = invoice.transaction.status === 'completed'
          const startDate = new Date(invoice.transaction.created_at)

          return {
            id: formatId(array.length - index),
            description: (
              <FormattedText
                id="payment.billing.table.description.value"
                values={{ month: format(startDate, 'LLLL') }}
              />
            ),
            startDate: format(startDate, 'M/d/yyyy'),
            amount: `$${roundNumber(
              parseInt(invoice.transaction.amount, 10),
              2,
            )}`,
            status: (
              <div
                className={classNames(classes.statusWrapper, {
                  [classes.successWrapper]: isPaid,
                  [classes.errorWrapper]: !isPaid,
                })}
              >
                <Typography
                  variant="span"
                  className={classNames(classes.status, {
                    [classes.successText]: isPaid,
                    [classes.errorText]: !isPaid,
                  })}
                >
                  {isPaid ? 'paid' : 'due'}
                </Typography>
              </div>
            ),
          }
        }),
    [invoices.data?.data],
  )

  const handleUpdateCard = useCallback(() => {
    window.Paddle.Checkout.open({
      override: subscription.data?.update_url,
    })
  }, [subscription.data?.update_url])

  if (isLoading) return <Loader isLoading={isLoading} />

  return (
    <div>
      {subscription?.data?.next_payment && (
        <div className={classes.row}>
          <div className={classes.left}>
            <div className={classes.textWrapper}>
              <Typography variant="span" className={classes.title}>
                <FormattedText id="payment.billing.title.nextCycle" />
              </Typography>
              <Typography variant="span" className={classes.description}>
                <FormattedText id="payment.billing.description.automaticBilling" />
              </Typography>
            </div>
            <Typography variant="body" className={classes.message}>
              ${roundNumber(subscription.data?.next_payment.amount, 2)} on{' '}
              {format(
                new Date(subscription.data?.next_payment.date),
                'EEEE, MMMM d, yyyy',
              )}
            </Typography>
          </div>
        </div>
      )}
      {subscription?.data && (
        <div className={classes.row}>
          <div className={classes.left}>
            <div className={classes.textWrapper}>
              <Typography variant="span" className={classes.title}>
                <FormattedText id="payment.billing.title.details" />
              </Typography>
              <Typography variant="span" className={classes.description}>
                <FormattedText id="payment.billing.description.changeCard" />
              </Typography>
            </div>
            <Typography
              variant="body"
              className={classNames(classes.message, {
                [classes.error]: !isLastPaymentSuccessful,
              })}
            >
              {isLastPaymentSuccessful ? (
                <FormattedText id="payment.billing.details.message.succeeded" />
              ) : (
                <FormattedText id="payment.billing.details.message.failed" />
              )}
            </Typography>
          </div>
          <div className={classes.right}>
            <Typography
              variant="h4"
              className={classes.clickableText}
              onClick={handleUpdateCard}
            >
              <FormattedText id="settings.button.edit" />
            </Typography>
          </div>
        </div>
      )}
      <div className={classes.tableTitleWrapper}>
        <Typography variant="span" className={classes.title}>
          <FormattedText id="payment.billing.title.invoices" />
        </Typography>
      </div>
      <Table columns={tableColumns} data={tableData} />
    </div>
  )
}
