import React from 'react'
import classNames from 'classnames'
// components
import { Typography } from 'src/modules/core/components'

import type { FormControlProps } from './form-control.interfaces'
import classes from './form-control.module.scss'

export const FormControl: React.FC<FormControlProps & React.ReactNode> = ({
  label,
  error,
  className,
  labelClassName,
  children,
}) => {
  return (
    <div className={classNames(classes.inputWrapper, className)}>
      {label && (
        <label className={classes.labelWrapper}>
          <Typography
            className={classNames(classes.label, labelClassName, {
              [classes.labelError]: !!error,
            })}
            variant="body"
          >
            {label}
          </Typography>
        </label>
      )}
      {children}
      {error && (
        <Typography className={classes.error} variant="body">
          {error}
        </Typography>
      )}
    </div>
  )
}
