import classNames from 'classnames'
import { FormattedText, Loader } from 'src/modules/core/components'
import { ConfigurationForm, ProfileInfo } from 'src/modules/user/components'
import { useProfile } from 'src/modules/user/hooks/use-profile'
import classes from './first-configuration-screen.module.scss'

export function FirstConfigurationScreen() {
  const profile = useProfile()

  if (profile.isLoading) {
    return <Loader isLoading text={<FormattedText id="configuration.profileLoadingText" />} full />
  }

  return (
    <div className={classNames(classes.root)}>
      <ConfigurationForm />
      <ProfileInfo rootClassName={classes.absoluteWrapper} />
    </div>
  )
}
