import classNames from 'classnames'
import { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { CalendarItem } from 'src/modules/calendar/components'
import { useCalendars } from 'src/modules/calendar/hooks/use-calendars'
import { isCalendarLimitReached, sortCalendars } from 'src/modules/calendar/utils/helpers'
import { Button, FormattedText, Loader, ToggleSwitch, Typography } from 'src/modules/core/components'
import { SubscriptionPlanCard } from 'src/modules/payment/components'
import { usePlanData } from 'src/modules/payment/hooks/use-plan-data'
import { getCalendarsLimitType } from 'src/modules/payment/utils/helpers'
import { ProfileInfo } from 'src/modules/user/components'
import uniqid from 'uniqid'
import classes from './subscription-selector.module.scss'

import type { BillingPlanDto } from '@grouse-software/types/billing'
import type { SubscriptionSelectorProps } from './subscription-selector.interface'

export const SubscriptionSelector: FC<SubscriptionSelectorProps> = ({
  isCurrentPlanDisplayed,
  onSelectPlan,
  subscriptionOptions = {},
  buttonClassName,
  type,
  title = <FormattedText id="configuration.subscription.title.plan" />,
  description = <FormattedText id="configuration.subscription.description" />,
}) => {
  const [isYearly, setIsYearly] = useState(true)
  const [selectedPlan, setSelectedPlan] = useState<BillingPlanDto>(null)
  const [selectedCalendarIds, setSelectedCalendarIds] = useState<string[]>(null)

  const navigate = useNavigate()
  const { formatMessage } = useIntl()
  const calendars = useCalendars({}, { sync: true })
  const { availablePlans, currentPlan } = usePlanData({
    isYearly,
    subscriptionOptions,
    type,
  })

  const plans = useMemo(
    () => (isCurrentPlanDisplayed ? [currentPlan] : availablePlans),
    [availablePlans, currentPlan, isCurrentPlanDisplayed],
  )

  const calendarsLimitType = getCalendarsLimitType(selectedPlan)

  const processedCalendars = useMemo(() => sortCalendars(calendars.data), [calendars.data])

  useEffect(() => {
    if (processedCalendars.length && !selectedCalendarIds) {
      setSelectedCalendarIds([processedCalendars[0].id])
    }
  }, [processedCalendars, selectedCalendarIds])

  const onSwitchChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setIsYearly(e.target.checked)
  }, [])

  const handleSelectPlan = (plan: BillingPlanDto) => () => {
    setSelectedPlan(plan)
  }

  const handleCheckboxChange = useCallback(
    (id: string) => (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        setSelectedCalendarIds((prev) => [...prev, id])
      } else {
        setSelectedCalendarIds((prev) => prev.filter((item) => item !== id))
      }
    },
    [],
  )

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.contentWrapper}>
          <Typography className={classes.title} variant="h1">
            {title}
          </Typography>
          <Typography className={classes.description} variant="body">
            {description}
          </Typography>
          {!isCurrentPlanDisplayed && (
            <div className={classes.switchWrapper}>
              <Typography
                variant="body"
                className={classNames(classes.switchLabel, {
                  [classes.selected]: !isYearly,
                })}
              >
                <FormattedText id="payment.switch.monthly" />
              </Typography>
              <ToggleSwitch variant="secondary" name="periodSwitch" checked={isYearly} onChange={onSwitchChange} />
              <Typography
                variant="body"
                className={classNames(classes.switchLabel, {
                  [classes.selected]: isYearly,
                })}
              >
                <FormattedText id="payment.switch.yearly" />
              </Typography>
            </div>
          )}
          <div
            className={classNames(classes.plans, {
              [classes.singlePlan]: isCurrentPlanDisplayed,
            })}
          >
            {plans.map((plan) => {
              const newPlan = { ...plan }

              if (isCurrentPlanDisplayed) {
                newPlan.benefits = null
                newPlan.description = null
              } else if (newPlan.price === 0) {
                newPlan.price = null
                newPlan.description = formatMessage({
                  id: 'configuration.subscription.freePlan.description',
                })
              }

              return (
                <div
                  className={classNames(classes.planCard, {
                    [classes.activePlanCard]: selectedPlan?.paddle_id === plan.paddle_id,
                  })}
                  onClick={handleSelectPlan(plan)}
                  key={plan.paddle_id}
                >
                  <SubscriptionPlanCard plan={newPlan} descriptionClassName={classes.planDescription} />
                </div>
              )
            })}
          </div>
          {selectedPlan && (
            <>
              <div className={classes.wrapper}>
                <div className={classes.line} />
                {calendars.isLoading ? (
                  <div className={classes.loadingWrapper}>
                    <Loader isLoading={calendars.isLoading} />
                  </div>
                ) : (
                  <>
                    <Typography className={classNames(classes.description, classes.secondaryTitle)} variant="body">
                      <FormattedText id="calendars.title" />
                    </Typography>
                    <Typography className={classes.secondaryDescription} variant="body">
                      <FormattedText
                        id="calendars.description"
                        values={{
                          strong: (chunks) => <strong>{chunks}</strong>,
                          limitType: calendarsLimitType,
                          limit: selectedPlan.calendars_limit,
                          isNew: false,
                        }}
                      />
                    </Typography>
                    <ul
                      className={classNames(classes.calendarList, {
                        [classes.fullWidthList]: processedCalendars.length === 1,
                      })}
                    >
                      {processedCalendars.map((calendar) => {
                        const isSelected = selectedCalendarIds?.includes(calendar.id)
                        const disabled =
                          isCalendarLimitReached(selectedPlan, calendar, selectedCalendarIds || []) && !isSelected

                        return (
                          <li key={uniqid()} className={classes.calendarItem}>
                            <CalendarItem
                              calendar={calendar}
                              selected={Array.isArray(selectedCalendarIds) && isSelected}
                              disabled={disabled}
                              onCheckboxChange={handleCheckboxChange}
                            />
                          </li>
                        )
                      })}
                    </ul>
                    <div className={classes.line} />
                  </>
                )}
              </div>
            </>
          )}
        </div>
        <Button
          className={classNames(classes.button, {
            [buttonClassName]: !selectedPlan,
          })}
          onClick={onSelectPlan(selectedPlan, selectedCalendarIds)}
          disabled={!selectedPlan}
        >
          <FormattedText id="common.continue" />
        </Button>
        <Typography
          variant="span"
          className={classes.cancelButton}
          onClick={() => {
            navigate(-1)
          }}
        >
          <FormattedText id="common.doNotMakeChanges" />
        </Typography>
      </div>
      <ProfileInfo rootClassName={classes.absoluteWrapper} />
    </div>
  )
}
