import { useQuery } from 'react-query'
import { useHandleRetry } from 'src/modules/core/hooks/use-handle-retry'
import { ApiService } from 'src/services/api.service'

import type { ListEventOptions } from '@grouse-software/types/event/event.api'
export const GET_EVENT_BY_ID_CACHE_KEY = 'get-event-by-id'

export function useEventById<T extends {}>(id: string, params?: ListEventOptions) {
  const handleRetry = useHandleRetry()
  return useQuery([GET_EVENT_BY_ID_CACHE_KEY, id, params], () => ApiService.Events.getEventById<T>(id, params), {
    enabled: !!id,
    retry: handleRetry,
    staleTime: 300000,
  })
}
