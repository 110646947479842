import { allowedEventKeys, createQueryParams, makeRequest } from 'src/helpers/request'
import { ApiService, ServerResponse } from './api.service'

import type { ListEventOptions } from '@grouse-software/types/event/event.api'
import type { SyncJobStatus, UpdateExtendEventBodyDto } from 'src/modules/event/types/event.types'
import type { UpdateRemindersForEventBodyDto } from '@grouse-software/types/reminder/reminder.api'
import type { EventDto } from '@grouse-software/types/event'

export const EventsService = {
  getEventList: async <T extends {}>(params?: ListEventOptions): Promise<T[]> => {
    const queryParams: string = createQueryParams(params)
    const response = await makeRequest<ServerResponse<T[]>>(
      `${process.env.REACT_APP_API_URL}/events${queryParams}`,
      'get',
    )
    return response.data
  },

  getEventById: async <T extends {}>(id: string, params?: ListEventOptions): Promise<T> => {
    const queryParams: string = createQueryParams(params)
    return makeRequest<T>(`${process.env.REACT_APP_API_URL}/events/${id}${queryParams}`, 'get')
  },

  updateEvents: async (eventsParams?: UpdateExtendEventBodyDto[]): Promise<EventDto[]> => {
    const eventsPayload: { [key: string]: string }[] = []
    const reminderPayload: UpdateRemindersForEventBodyDto[] = []

    eventsParams.forEach((event) => {
      const { id, ...updateEventValue } = event

      for (const property in updateEventValue) {
        if (Object.prototype.hasOwnProperty.call(updateEventValue, property) && allowedEventKeys.includes(property)) {
          eventsPayload.push({
            op: 'replace',
            path: `/events/${id}/${property}`,
            value: updateEventValue[property],
          })
        }
      }

      if (event.time_before && (event?.active ?? true)) {
        reminderPayload.push({
          event: id,
          reminders: event.time_before.map((timeBefore: unknown) => ({
            time_before: parseInt(timeBefore as string, 10), // need to convert to integer here otherwise BE logic will not work properly
          })),
        })
      }
    })

    if (reminderPayload.length !== 0) {
      await ApiService.Reminders.updateReminders({
        payload: reminderPayload,
      })
    }

    return makeRequest<{ [key: string]: string }[]>(`${process.env.REACT_APP_API_URL}/events`, 'patch', eventsPayload)
  },

  updateEvent: async (event?: UpdateExtendEventBodyDto): Promise<EventDto> => {
    if (event.time_before && (event?.active ?? true)) {
      await ApiService.Reminders.updateReminders({
        payload: [
          {
            event: event.id,
            reminders: event.time_before.map((timeBefore: unknown) => ({
              time_before: Number(timeBefore),
            })),
          },
        ],
      })
    }

    const { id, ...updateEventValue } = event
    const eventsPayload: { [key: string]: string }[] = []
    for (const property in updateEventValue) {
      if (Object.prototype.hasOwnProperty.call(updateEventValue, property) && allowedEventKeys.includes(property)) {
        eventsPayload.push({
          op: 'replace',
          path: `/events/${id}/${property}`,
          value: updateEventValue[property],
        })
      }
    }

    const responseData = await makeRequest<{ [key: string]: string }[]>(
      `${process.env.REACT_APP_API_URL}/events`,
      'patch',
      eventsPayload,
    )

    return responseData[0]
  },

  syncEvents: async (): Promise<void> => {
    return makeRequest(`${process.env.REACT_APP_API_URL}/events/sync`, 'post')
  },

  getSyncJobStatus: (jobId: string): Promise<SyncJobStatus> => {
    return makeRequest(`${process.env.REACT_APP_API_URL}/events/sync/${jobId}`, 'get')
  },
}
