import classNames from 'classnames'
import { ChangeEvent, FC, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { AppointmentConfirmationStepsModal } from 'src/modules/confirmation/containers'
// components
import {
  Button,
  FormattedText,
  FormControl,
  Icon,
  SelectField,
  TextField,
  ToggleSwitch,
  Typography,
} from 'src/modules/core/components'
import {
  maxMessageLength,
  maxMessageLengthWithConfirmation,
  onReminderMessageInput,
} from 'src/modules/event/constants/event.constants'
// utils
import { generateEstimatedEventMessage } from 'src/modules/event/utils/helpers'
import { ConsumedReminderSegments, CreateReminderHelper } from 'src/modules/reminder/components'
import { timingOptions } from 'src/modules/user/constants/profile.constants'
// constants
import { settingsFormSchema } from 'src/modules/user/constants/settings-form'
// hooks
import { useOptimisticUpdateProfile } from 'src/modules/user/hooks/use-optimistic-update-profile'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box } from '@material-ui/core'
import classes from './settings-profile-info-form.module.scss'

// types
import type { ProfileFormData } from 'src/modules/user/types/profile.types'

import type { SettingsProfileInfoFormProps } from './settings-profile-info-form.interface'
export const SettingsProfileInfoFormCustomerMode: FC<SettingsProfileInfoFormProps> = ({
  profileInfo,
  setIsProfileInfoEditing,
}) => {
  // hooks
  const optimisticUpdateProfile = useOptimisticUpdateProfile()
  const [isStepsModalOpen, setIsStepsModalOpen] = useState(false)
  const { formatMessage } = useIntl()

  const {
    control,
    register,
    handleSubmit,
    watch,
    setError,
    formState: { isDirty, errors, dirtyFields },
  } = useForm<ProfileFormData>({
    defaultValues: {
      from: profileInfo.sender_name,
      meetingLocation: profileInfo.default_location,
      defaultReminder: profileInfo.default_message_text,
      timing: profileInfo.default_time_before.map((t) => t.toString()),
      appointmentConfirmation: !!profileInfo.appointment_confirmation,
    },
    // @ts-ignore
    resolver: yupResolver(settingsFormSchema),
  })
  const { defaultReminder, meetingLocation, from } = watch()

  const maxDefaultReminderLength = profileInfo.appointment_confirmation
    ? maxMessageLengthWithConfirmation
    : maxMessageLength

  const eventMessage = useMemo(
    () =>
      generateEstimatedEventMessage({
        ...profileInfo,
        appointment_confirmation: false,
        sender_name: from,
        default_location: meetingLocation,
        default_message_text: defaultReminder,
      }),
    [defaultReminder, from, meetingLocation, profileInfo],
  )

  const onSubmit = (data: ProfileFormData) => {
    if (isDirty && Object.keys(dirtyFields).length !== 0) {
      if (profileInfo.appointment_confirmation && eventMessage.length > maxMessageLengthWithConfirmation) {
        return setError('defaultReminder', {
          type: 'custom',
          message: 'Default SMS Reminder must be shorter than 239 characters',
        })
      }

      if (eventMessage.length > maxMessageLength) {
        return setError('defaultReminder', {
          type: 'custom',
          message: 'Default SMS Reminder must be shorter than 320 characters',
        })
      }

      optimisticUpdateProfile.mutate({
        default_location: data.meetingLocation,
        default_message_text: data.defaultReminder,
        sender_name: data.from,
        default_time_before: data.timing.map((time) => +time),
        appointment_confirmation: !!data.appointmentConfirmation,
      })
    }

    setIsProfileInfoEditing(false)
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          wrapperClassName={classes.textFieldWrapper}
          className={classes.textField}
          labelClassName={classes.label}
          label={formatMessage({ id: 'settings.label.businessName' })}
          placeholder={formatMessage({
            id: 'configuration.step1.placeholder.from',
          })}
          {...register('from')}
          error={errors.from?.message}
        />
        <TextField
          wrapperClassName={classes.textFieldWrapper}
          className={classes.textField}
          labelClassName={classes.label}
          label={formatMessage({
            id: 'configuration.step1.label.location',
          })}
          placeholder={formatMessage({
            id: 'configuration.step1.placeholder.location',
          })}
          Element="textarea"
          {...register('meetingLocation')}
          error={errors.meetingLocation?.message}
        />
        <TextField
          labelClassName={classes.label}
          label={formatMessage({
            id: 'configuration.step1.label.reminder',
          })}
          placeholder={formatMessage({
            id: 'configuration.step1.placeholder.reminder',
          })}
          Element="textarea"
          rows={3}
          {...register('defaultReminder')}
          onInput={(event: ChangeEvent<HTMLTextAreaElement>) => onReminderMessageInput(event, maxDefaultReminderLength)}
          error={errors.defaultReminder?.message}
          className={classNames(classes.textField, {
            [classes.error]: eventMessage.length > maxDefaultReminderLength,
          })}
        >
          <ConsumedReminderSegments
            appointmentConfirmation={profileInfo.appointment_confirmation}
            message={eventMessage}
            textClassName={classes.sublabel}
            isEstimate
          />
        </TextField>
        <div className={classNames(classes.textWrapper, classes.textFieldWrapper)}>
          <CreateReminderHelper />
          {!!(eventMessage && eventMessage.length) && (
            <Typography
              className={classNames(classes.sublabel, {
                [classes.error]: eventMessage.length > maxDefaultReminderLength,
                [classes.larger]: eventMessage.length > maxDefaultReminderLength,
              })}
              variant="span"
            >
              <FormattedText
                id="configuration.step1.sublabel"
                values={{
                  current: eventMessage.length,
                  max: maxDefaultReminderLength,
                  strong: (chunks) => <strong>{chunks}</strong>,
                }}
              />
            </Typography>
          )}
        </div>
        <FormControl
          label={formatMessage({
            id: 'configuration.step1.timing.title',
          })}
          labelClassName={classes.label}
          error={errors?.timing?.message}
        >
          <Controller
            control={control}
            defaultValue={[]}
            name="timing"
            render={({ field: { onChange, value, ref } }) => (
              <SelectField
                ref={ref}
                value={timingOptions.filter((c) => value.includes(c.value))}
                onChange={(val) => onChange(val.map((c) => c.value))}
                className={classes.textFieldWrapper}
                options={timingOptions}
                isMulti
              />
            )}
          />
        </FormControl>
        <FormControl
          label={formatMessage({
            id: 'settings.appointment.confirmation',
          })}
          labelClassName={classes.label}
          error={errors?.appointmentConfirmation?.message}
        >
          <Box display="flex" alignItems="center" gridColumnGap={10}>
            <Controller
              control={control}
              name="appointmentConfirmation"
              render={({ field: { onChange, value, ref } }) => (
                <ToggleSwitch
                  ref={ref}
                  checked={value}
                  labelClassName={classes.switchLabel}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e.target.checked)}
                />
              )}
            />
            <Typography variant="body" className={classes.infoText}>
              <FormattedText id={watch('appointmentConfirmation') ? 'common.enabled' : 'common.disabled'} />
            </Typography>
            <Icon name="questionMark" className={classes.tooltipLink} onClick={() => setIsStepsModalOpen(true)} />
          </Box>
        </FormControl>
        <Button
          type="submit"
          className={classes.button}
          widthType="content"
          isLoading={optimisticUpdateProfile.isLoading}
        >
          <FormattedText id="common.save" />
        </Button>
      </form>

      {isStepsModalOpen && <AppointmentConfirmationStepsModal onClose={() => setIsStepsModalOpen(false)} />}
    </>
  )
}
