import { FC } from 'react'
// components
import { FormattedText, Icon, Typography } from 'src/modules/core/components'

import classes from './confirmation-message.module.scss'
import type { ConfirmationMessageProps } from './confirmation-message.interface'

export const ConfirmationMessage: FC<ConfirmationMessageProps> = ({
  variant,
}) => {
  return (
    <div>
      <div className={classes.confirmationMessageWrapper}>
        <div className={classes.confirmationSuccessMark}>
          {variant === 'success' && (
            <Icon name="checkmark" className={classes.checkIcon} />
          )}
          {(variant === 'canceled' || variant === 'error') && (
            <Icon name="calendarCanceled" />
          )}
        </div>
        <Typography variant="body" className={classes.text}>
          {variant === 'success' && (
            <FormattedText id="confirmation.confirmed" />
          )}
          {variant === 'canceled' && (
            <FormattedText id="confirmation.canceled" />
          )}
          {variant === 'error' && <FormattedText id="confirmation.error" />}
        </Typography>
      </div>
    </div>
  )
}
