import { FC, memo } from 'react'

import classes from './footer.module.scss'

export const Footer: FC = memo(({ children }) => (
  <div className={classes.root}>
    <div className={classes.content}>{children}</div>
  </div>
))

Footer.displayName = 'Footer'
