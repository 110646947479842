import classNames from 'classnames'
import { EventPreviewWithReminderData } from '../../types/event.types'
import { EventSuggestedNumbersHelper } from '../../utils/EventSuggestedNumbersHelper'
import classes from './event-card-status.module.scss'

export type EventCardStatusProps = {
  event: EventPreviewWithReminderData
  isError?: boolean
  /**
   * In some cases, the Event Card is used to display reminders,
   * and in that case the event_date is already the reminder date.
   */
  eventDateIsReminderDate?: boolean
}

type EventStatus = 'error' | 'inactive' | 'review' | 'scheduled' | 'sent'

export function EventCardStatus({ event, isError, eventDateIsReminderDate }: EventCardStatusProps) {
  let status: EventStatus = 'inactive'

  if (event.active) {
    const eventDate = new Date(+event.event_date).getTime()
    let reminderDate = eventDate

    if (!eventDateIsReminderDate) {
      const reminderOffset = event.reminders?.map((r) => r.time_before).sort((a, b) => a - b)[0] ?? 0
      reminderDate = eventDate - reminderOffset
    }

    const isPastDate = new Date().getTime() > reminderDate
    const createdAt = new Date(event.recurring_event?.data.created_at ?? event.created_at).getTime()
    status = reminderDate > createdAt ? (isPastDate ? 'sent' : 'scheduled') : 'inactive'
  }

  if (EventSuggestedNumbersHelper.needsReview(event)) {
    status = 'review'
  }

  if (isError || event.errors.length > 0) {
    status = 'error'
  }

  const cssClass = classNames(classes.status, {
    [classes.statusError]: status === 'error',
    [classes.statusInactive]: status === 'inactive',
    [classes.statusReview]: status === 'review',
    [classes.statusScheduled]: status === 'scheduled',
    [classes.statusSent]: status === 'sent',
  })

  return <span className={classNames(classes.status, cssClass)}>{status}</span>
}
