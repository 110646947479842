import { AxiosError } from 'axios'
import { getReasonPhrase } from 'http-status-codes'
import { ProviderContext, useSnackbar } from 'notistack'
import { FC, useCallback, useContext, useEffect, useRef } from 'react'
import { useIntl } from 'react-intl'
import { QueryClient, useQueryClient } from 'react-query'
import { TgglContext, useTggl } from 'react-tggl-client'
import { FormattedText, Icon, Typography } from 'src/modules/core/components'
import { oauthScopes } from 'src/modules/core/constants/oauthScopes'
import { routeNames } from 'src/modules/core/constants/routeNames'
import { LoadingWrapperContext } from 'src/modules/core/context'
import { evaluateRouteAccess } from 'src/modules/core/utils/router.utils'
import { GET_SUBSCRIPTION_CACHE_KEY } from 'src/modules/payment/hooks/use-subscription'
import { AuthContext } from 'src/modules/user/context'
import { useAuth } from 'src/modules/user/hooks/use-auth'
import { GET_PROFILE_CACHE_KEY } from 'src/modules/user/hooks/use-profile'
import { ApiService } from 'src/services/api.service'
import { TokenService } from 'src/services/token.service'
import classes from './login.module.scss'

export const Login: FC = () => {
  const auth = useAuth()
  const snackbar = useSnackbar()
  const queryClient = useQueryClient()
  const { formatMessage } = useIntl()
  const { updateContext } = useTggl()
  const { authenticateUser, logoutUser, isReauthorizingForOfflineSync } = useContext(AuthContext)
  const { enableLoading, disableLoading, setLoadingMessage } = useContext(LoadingWrapperContext)

  const handleLoginError = useCallback(
    (errorCode: number, snackbar: ProviderContext, formatMessage: any, message?: string) => {
      snackbar.enqueueSnackbar(
        formatMessage(
          { id: 'login.error.withCode' },
          {
            code: errorCode,
            message: message ?? getReasonPhrase(errorCode).toLowerCase(),
          },
        ),
        { variant: 'error', autoHideDuration: 9000 },
      )
      disableLoading()
    },
    [disableLoading],
  )

  function checkScopes(codeResponse: any) {
    const scopesInArray = oauthScopes.split(' ')
    const receivedScopes = codeResponse.scope.split(' ')
    return scopesInArray.every((scope) => receivedScopes.includes(scope))
  }

  async function fetchProfileAndSubscription(
    queryClient: QueryClient,
    updateContext: (context: Partial<TgglContext>) => void,
    profileParams: any,
  ) {
    const profile = await queryClient.fetchQuery(
      [GET_PROFILE_CACHE_KEY, profileParams],
      () => ApiService.Profile.getProfile(profileParams),
      { staleTime: 300000 },
    )

    if (profile) {
      updateContext({ userId: profile.id, email: profile.email })
      const subscription = profile.billing_subscription_id
        ? await queryClient.fetchQuery(GET_SUBSCRIPTION_CACHE_KEY, () => ApiService.Billing.getSubscription(), {
            staleTime: 300000,
          })
        : null
      return { profile, subscription }
    }

    return { profile: null, subscription: null }
  }

  const processAuthCode = useCallback(
    (code: string, state?: string) => {
      enableLoading()
      const extraData = state ? JSON.parse(decodeURIComponent(state)) : {}
      auth.mutate(
        { ...extraData, code },
        {
          onSuccess: async (response) => {
            TokenService.getInstance().setToken(response.access_token, response.refresh_token)
            authenticateUser()

            try {
              const profileParams = {}
              const { profile, subscription } = await fetchProfileAndSubscription(
                queryClient,
                updateContext,
                profileParams,
              )

              if (!profile) throw new Error()

              const { redirect, redirectPath } = evaluateRouteAccess({
                path: routeNames.dashboard.path,
                isAuthenticated: true,
                restricted: true,
                subscription,
                profile,
                isLoading: false,
              })

              window.location.href = redirect ? redirectPath : routeNames.dashboard.path
            } catch {
              logoutUser()
              TokenService.getInstance().removeToken()
              disableLoading()
              snackbar.enqueueSnackbar(formatMessage({ id: 'login.error.unknown' }), { variant: 'error' })
              queryClient.removeQueries()
            }
          },
          onError: (error: AxiosError) => {
            const errorCode = error.response ? error.response.status : 404
            handleLoginError(errorCode, snackbar, formatMessage)
          },
        },
      )
    },
    [
      auth,
      authenticateUser,
      formatMessage,
      logoutUser,
      queryClient,
      snackbar,
      updateContext,
      enableLoading,
      disableLoading,
      handleLoginError,
    ],
  )

  // Handle login by popup
  const loginByCode = useCallback(() => {
    enableLoading()
    setLoadingMessage('Authorize with Google')
    const codeClient = window.google.accounts.oauth2.initCodeClient({
      client_id: process.env.REACT_APP_CLIENT_ID,
      ux_mode: 'popup',
      scope: oauthScopes,
      callback: async (codeResponse) => {
        if (codeResponse?.error) {
          const errorCode = codeResponse.error === 'access_denied' ? 403 : 404
          handleLoginError(errorCode, snackbar, formatMessage)
          return
        }
        if (!checkScopes(codeResponse)) {
          handleLoginError(403, snackbar, formatMessage, 'Please review the permissions requested during login')
          return
        }
        processAuthCode(codeResponse.code)
      },
    })
    setTimeout(() => codeClient.requestCode(), 100)
  }, [processAuthCode, enableLoading, setLoadingMessage, snackbar, formatMessage, handleLoginError])

  useEffect(() => disableLoading(), [disableLoading])

  // Handle login by redirect
  const isRunning = useRef(false)
  useEffect(() => {
    if (isRunning.current) return
    isRunning.current = true

    const urlParams = new URLSearchParams(location.search)
    const authorizationCode = urlParams.get('code')
    const state = urlParams.get('state')
    if (authorizationCode) processAuthCode(authorizationCode, state)
  }, [processAuthCode, enableLoading])

  if (isReauthorizingForOfflineSync) {
    enableLoading()
    return null
  }

  return (
    <div className={classes.root}>
      <div className={classes.banner}>
        <Icon name="googleCalendar" className={classes.calendarIcon} />
        <Icon name="imessage" className={classes.messageIcon} />
        <Typography variant="span" className={classes.message}>
          <FormattedText id="login.exampleMessage" />
        </Typography>
      </div>
      <Typography variant="body" className={classes.title}>
        <FormattedText id="login.title" />
      </Typography>
      <Typography variant="body" className={classes.description}>
        <FormattedText id="login.description" />
      </Typography>
      <img
        src="/signIn.png"
        width="191px"
        height="46px"
        onClick={loginByCode}
        className={classes.imageWrapper}
        alt="Sign in"
      />
    </div>
  )
}
