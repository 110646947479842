import { FC, MouseEvent, useEffect } from 'react'
// components
import { Icon } from 'src/modules/core/components/icon'

import type { ErrorModalProps } from './error-modal.interface'
import classes from './error-modal.module.scss'

export const ErrorModal: FC<ErrorModalProps> = ({
  onClose,
  title,
  description,
}) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden'

    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [])

  return (
    <div className={classes.backdrop} onClick={onClose}>
      <div
        className={classes.modal}
        onClick={(e: MouseEvent) => e.stopPropagation()}
      >
        <div className={classes.wrapper}>
          <div className={classes.content}>
            <div className={classes.errorWrapper}>
              <Icon name="error" />
            </div>
            <div className={classes.text}>
              {title}
              {description}
            </div>
          </div>
          <div>
            <Icon name="close" className={classes.close} onClick={onClose} />
          </div>
        </div>
      </div>
    </div>
  )
}
