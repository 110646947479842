import { FC, useCallback, useState } from 'react'
// context
import { PaginationContext } from 'src/modules/core/context'

export const PaginationProvider: FC = ({ children }) => {
  // state
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [numberOfPages, setNumberOfPages] = useState<number>(1)

  const handleNextPageClick = useCallback(() => {
    if (currentPage < numberOfPages) {
      setCurrentPage(currentPage + 1)
    }
  }, [currentPage, numberOfPages])

  const handlePrevPageClick = useCallback(() => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1)
    }
  }, [currentPage])

  return (
    <PaginationContext.Provider
      value={{
        currentPage,
        numberOfPages,
        nextPage: handleNextPageClick,
        prevPage: handlePrevPageClick,
        setPage: setCurrentPage,
        setNumberOfPages,
      }}
    >
      {children}
    </PaginationContext.Provider>
  )
}
