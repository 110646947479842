import { FC, useCallback, memo, useContext } from 'react'
import Cookies from 'js-cookie'
// components
import { FormattedText, Typography, Button } from 'src/modules/core/components'
import { ProfileInfo } from 'src/modules/user/components/profile-info'
// context
import { EventsContext } from 'src/modules/event/context'
// hooks
import { useOptimisticUpdateProfile } from 'src/modules/user/hooks/use-optimistic-update-profile'
// constants
import { CookieKeys } from 'src/modules/core/types/cookie.types'

import classes from './congratulations-screen.module.scss'

export const CongratulationsScreen: FC = memo(() => {
  // context
  const { activeEvents } = useContext(EventsContext)
  // hooks
  const updateProfile = useOptimisticUpdateProfile()

  const handleCompleteOnboarding = useCallback(() => {
    Cookies.set(CookieKeys.hideReminderCountingPopup, 'true', {
      expires: 15,
    })
    updateProfile.mutate({ completed_onboarding: true })
  }, [updateProfile])

  return (
    <div className={classes.root}>
      <div className={classes.textWrapper}>
        <Typography className={classes.title} variant="h1">
          <FormattedText id="configuration.congratulations.title" />
        </Typography>
        <Typography className={classes.description} variant="body">
          {activeEvents.length !== 0 ? (
            <FormattedText
              id="configuration.congratulations.description.set"
              values={{
                remindersAmount: activeEvents.length,
                strong: (chunks) => <strong>{chunks}</strong>,
              }}
            />
          ) : (
            <FormattedText id="configuration.congratulations.description.notSet" />
          )}
        </Typography>
      </div>
      <Button className={classes.button} onClick={handleCompleteOnboarding}>
        <FormattedText id="configuration.congratulations.button" />
      </Button>
      <ProfileInfo rootClassName={classes.absoluteWrapper} />
    </div>
  )
})

CongratulationsScreen.displayName = 'CongratulationsScreen'
