import 'react-phone-input-2/lib/style.css'
import classNames from 'classnames'
import { useCallback, useContext, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { Modal, Typography } from 'src/modules/core/components'
import { DateContext } from 'src/modules/core/context'
import { eventDetailsParams, eventPreviewFields } from 'src/modules/event/constants/event-params'
import { useEventById } from 'src/modules/event/hooks/use-event-by-id'
import { useEventList } from 'src/modules/event/hooks/use-event-list'
import { useSendTestReminder } from 'src/modules/event/hooks/use-send-test-reminder'
import { useSyncEvents } from 'src/modules/event/hooks/use-sync-events'
import { EventData, EventDetails, EventPreview, ExtendEventPreview } from 'src/modules/event/types/event.types'
import { useProfile } from 'src/modules/user/hooks/use-profile'
import { Grid } from '@material-ui/core'
import { SendTestReminderFormModal } from '../send-test-reminder-form-modal'
import { SendTestReminderPayload } from '../send-test-reminder-form-modal/send-test-reminder-form-modal.interface'
import { EventList } from './event-list'
import classes from './send-test-reminder-events-modal.module.scss'

export interface SendTestReminderEventsModalProps {
  onSkip?: () => void
  onSent?: () => void
}

export function SendTestReminderEventsModal({
  onSkip = () => {},
  onSent = () => {},
}: SendTestReminderEventsModalProps) {
  const { formatMessage } = useIntl()

  const { setSelectedDate, startDate, endDate } = useContext(DateContext)

  const profile = useProfile()
  const sendTestReminder = useSendTestReminder()
  const [sendError, setSendError] = useState<string | null>(null)

  const [selectedEvent, setSelectedEvent] = useState<ExtendEventPreview>(null)

  const selectedEventData = useEventById<EventDetails>(selectedEvent?.id, eventDetailsParams)

  const isLoadingEventData = selectedEventData.isLoading || selectedEventData.isRefetching

  const editableEvent = useMemo<EventData>(() => {
    if (!selectedEvent || !selectedEventData.data) return null
    return { ...selectedEvent, ...selectedEventData.data }
  }, [selectedEvent, selectedEventData.data])

  const syncEvents = useSyncEvents()

  const eventListParams = useMemo(
    () => ({
      fields: eventPreviewFields,
      start_date: startDate,
      end_date: endDate,
    }),
    [startDate, endDate],
  )

  const eventList = useEventList<EventPreview>(eventListParams, {
    enabled: !syncEvents.isLoading,
  })

  const onEventClick = useCallback((eventPreview: ExtendEventPreview) => {
    setSelectedEvent(eventPreview)
  }, [])

  const handleSend = useCallback(
    (data: SendTestReminderPayload) => {
      setSendError(null)
      sendTestReminder.mutate(data, {
        onSuccess: () => {
          setSelectedEvent(null)
          onSent()
        },
        onError: (error: any) => {
          const message = error?.response?.data?.error ?? error?.toString() ?? 'Unknown error'
          setSendError(message)
        },
      })
    },
    [sendTestReminder, onSent],
  )

  return (
    <>
      <Modal className={classNames(classes.modal, 'translate')}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={3}
          className={classes.modalContent}
        >
          <Grid item xs={12}>
            <Typography variant="h3" className={classes.modalTitle}>
              {formatMessage({
                id: 'onboarding.sendTestReminder.events.title',
              })}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <p>{formatMessage({ id: 'onboarding.sendTestReminder.events.text' })}</p>
          </Grid>
          <Grid item>
            <EventList
              events={eventList.data ?? []}
              eventCardMode="testReminder"
              changeSelectedDate={setSelectedDate}
              onCardClick={onEventClick}
            />
          </Grid>
          <Grid item>
            <a href="#" onClick={onSkip}>
              Skip sending the test reminder
            </a>
          </Grid>
        </Grid>
      </Modal>
      {(editableEvent || isLoadingEventData) && (
        <SendTestReminderFormModal
          profileData={profile.data}
          event={editableEvent}
          isLoading={isLoadingEventData || sendTestReminder.isLoading}
          onClose={() => setSelectedEvent(null)}
          onSend={handleSend}
          error={sendError}
        />
      )}
    </>
  )
}
