import { FC } from 'react'
// components
import { Icon, Typography } from 'src/modules/core/components'

import type { FeaturesProps } from './features.interface'
import classes from './features.module.scss'

export const Features: FC<FeaturesProps> = ({ list, className }) => (
  <div className={className}>
    {list.map((feature, i) => (
      <div key={`${feature}${i}`} className={classes.feature}>
        <Icon name="secondaryCheckmark" />
        <Typography variant="span">{feature}</Typography>
      </div>
    ))}
  </div>
)
