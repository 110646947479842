import { useMutation } from 'react-query'
import { ApiService } from 'src/services/api.service'

interface AuthParams {
  code: string
  extraData?: Record<string, any>
}

export function useAuth() {
  return useMutation(({ code, ...extraData }: AuthParams) => ApiService.Auth.authorize(code, extraData))
}
