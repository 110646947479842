import { useMutation, useQueryClient } from 'react-query'
import { GET_CALENDARS_CACHE_KEY } from 'src/modules/calendar/hooks/use-calendars'
import { ApiService } from 'src/services/api.service'

import type { CalendarDto, UpdateCalendarBodyDto } from '@grouse-software/types/calendar'

export const BULK_UPDATE_CALENDARS_CACHE_KEY = 'bulk-update-calendars'

export function useBulkUpdateCalendars() {
  const queryClient = useQueryClient()
  return useMutation(
    BULK_UPDATE_CALENDARS_CACHE_KEY,
    (params: UpdateCalendarBodyDto[]) => ApiService.Calendars.bulkUpdateCalendars(params),
    {
      onSuccess: (newData) => {
        if (newData) {
          queryClient.setQueryData<CalendarDto[] | undefined>(GET_CALENDARS_CACHE_KEY, (oldData) => {
            if (!oldData) return

            const newDataSet = new Set(newData.map((item) => item.id))
            const uniqueItems = [...newData, ...oldData.filter((item) => !newDataSet.has(item.id))]

            return uniqueItems
          })
        }
      },
    },
  )
}
