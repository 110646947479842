import { DeepMap, DeepPartial, FieldError, Resolver } from 'react-hook-form'
import * as yup from 'yup'

/**
 * Creates a custom resolver for react-hook-form using a Yup schema for validation.
 * This allows for custom error handling, preprocessing of values, enhanced type safety,
 * and advanced validation logic beyond what the standard yupResolver provides.
 * @template T - The type of form data.
 * @param {yup.ObjectSchema<any>} schema - The Yup schema used for validation.
 * @returns {Resolver<T>} - The resolver function for react-hook-form.
 */
export function createYupResolver<T>(
  schema: yup.ObjectSchema<any>,
  fieldNameMap?: Record<string, string>,
): Resolver<T> {
  return async (values) => {
    try {
      const parsedValues = await schema.validate(values, {
        abortEarly: false,
      })
      return {
        values: parsedValues,
        errors: {} as DeepMap<DeepPartial<T>, FieldError>,
      }
    } catch (yupError) {
      const errors = yupError.inner.reduce((allErrors: DeepMap<DeepPartial<T>, FieldError>, currentError: any) => {
        const fieldName = fieldNameMap?.[currentError.path] || currentError.path
        const errorMessage = currentError.message.startsWith(currentError.path)
          ? `${fieldName} ${currentError.message.substring(currentError.path.length)}`
          : currentError.message
        return {
          ...allErrors,
          [currentError.path]: {
            type: currentError.type ?? 'validation',
            message: errorMessage,
          } as FieldError,
        }
      }, {} as DeepMap<DeepPartial<T>, FieldError>)
      return {
        values: {} as DeepPartial<T>,
        errors,
      }
    }
  }
}
