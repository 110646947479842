import { FC, memo } from 'react'
import { ReactComponent as ArrowLeftIcon } from 'src/assets/icons/arrowLeft.svg'
import { ReactComponent as ArrowLeftRoundedIcon } from 'src/assets/icons/arrowLeftRounded.svg'
import { ReactComponent as ArrowRightIcon } from 'src/assets/icons/arrowRight.svg'
import { ReactComponent as ArrowRightMediumIcon } from 'src/assets/icons/arrowRightMedium.svg'
import { ReactComponent as ArrowRightSmallIcon } from 'src/assets/icons/arrowRightSmall.svg'
import { ReactComponent as BackIcon } from 'src/assets/icons/back.svg'
import { ReactComponent as BellIcon } from 'src/assets/icons/bell.svg'
import { ReactComponent as CalendarIcon } from 'src/assets/icons/calendar.svg'
import { ReactComponent as CalendarCanceledIcon } from 'src/assets/icons/calendarCanceled.svg'
import { ReactComponent as CalendarAltIcon } from 'src/assets/icons/calendardAlt.svg'
import { ReactComponent as CheckboxIcon } from 'src/assets/icons/check.svg'
import { ReactComponent as CheckmarkIcon } from 'src/assets/icons/checkmark.svg'
import { ReactComponent as ChevronRightIcon } from 'src/assets/icons/chervonRight.svg'
import { ReactComponent as CloseIcon } from 'src/assets/icons/close.svg'
import { ReactComponent as ConfirmEntry } from 'src/assets/icons/confirmEntry.svg'
import { ReactComponent as DashboardIcon } from 'src/assets/icons/dashboard.svg'
import { ReactComponent as DoubleArrowLeftIcon } from 'src/assets/icons/doubleArrowLeft.svg'
import { ReactComponent as ErrorIcon } from 'src/assets/icons/error.svg'
import { ReactComponent as EventCardError } from 'src/assets/icons/eventCardError.svg'
import { ReactComponent as EyeIcon } from 'src/assets/icons/eye.svg'
import { ReactComponent as GoogleIcon } from 'src/assets/icons/google.svg'
import { ReactComponent as GoogleCalendarIcon } from 'src/assets/icons/googleCalendar.svg'
import { ReactComponent as ImessageIcon } from 'src/assets/icons/imessage.svg'
import { ReactComponent as InfoIcon } from 'src/assets/icons/info.svg'
import { ReactComponent as MarkAcceptedIcon } from 'src/assets/icons/markAccepted.svg'
import { ReactComponent as MarkRejectedIcon } from 'src/assets/icons/markRejected.svg'
import { ReactComponent as MarkWaitingIcon } from 'src/assets/icons/markWaiting.svg'
import { ReactComponent as MessageIcon } from 'src/assets/icons/message.svg'
import { ReactComponent as PhoneIcon } from 'src/assets/icons/phone.svg'
import { ReactComponent as PinIcon } from 'src/assets/icons/pin.svg'
import { ReactComponent as QuestionMarkIcon } from 'src/assets/icons/questionMark.svg'
import { ReactComponent as QuestionMarkPrimaryIcon } from 'src/assets/icons/questionMarkPrimary.svg'
import { ReactComponent as RadioBorderIcon } from 'src/assets/icons/radioBorder.svg'
import { ReactComponent as RadioDotIcon } from 'src/assets/icons/radioDot.svg'
import { ReactComponent as RemoveEntry } from 'src/assets/icons/removeEntry.svg'
import { ReactComponent as ScheduleIcon } from 'src/assets/icons/schedule.svg'
import { ReactComponent as SecondaryCheckmarkIcon } from 'src/assets/icons/secondaryCheckmark.svg'
import { ReactComponent as SendIcon } from 'src/assets/icons/send.svg'
import { ReactComponent as SettingsIcon } from 'src/assets/icons/settings.svg'
import { ReactComponent as SuccessCheckmarkIcon } from 'src/assets/icons/successCheckmark.svg'
import { ReactComponent as UsageModeCustomer } from 'src/assets/icons/usageModeCustomer.svg'
import { ReactComponent as UsageModePersonal } from 'src/assets/icons/usageModePersonal.svg'
import { ReactComponent as WarningIcon } from 'src/assets/icons/warning.svg'
import { IconsMap, Props } from './icon.interface'

const icons: IconsMap = {
  arrowLeft: ArrowLeftIcon,
  arrowLeftRounded: ArrowLeftRoundedIcon,
  arrowRight: ArrowRightIcon,
  arrowRightMedium: ArrowRightMediumIcon,
  arrowRightSmall: ArrowRightSmallIcon,
  back: BackIcon,
  bell: BellIcon,
  calendar: CalendarIcon,
  calendarAlt: CalendarAltIcon,
  calendarCanceled: CalendarCanceledIcon,
  checkbox: CheckboxIcon,
  checkmark: CheckmarkIcon,
  chevronRight: ChevronRightIcon,
  close: CloseIcon,
  confirmEntry: ConfirmEntry,
  dashboard: DashboardIcon,
  doubleArrowLeft: DoubleArrowLeftIcon,
  error: ErrorIcon,
  eventCardError: EventCardError,
  eye: EyeIcon,
  google: GoogleIcon,
  googleCalendar: GoogleCalendarIcon,
  imessage: ImessageIcon,
  info: InfoIcon,
  markAccepted: MarkAcceptedIcon,
  markRejected: MarkRejectedIcon,
  markWaiting: MarkWaitingIcon,
  message: MessageIcon,
  phone: PhoneIcon,
  pin: PinIcon,
  questionMark: QuestionMarkIcon,
  questionMarkPrimary: QuestionMarkPrimaryIcon,
  radioBorder: RadioBorderIcon,
  radioDot: RadioDotIcon,
  removeEntry: RemoveEntry,
  schedule: ScheduleIcon,
  secondaryCheckmark: SecondaryCheckmarkIcon,
  send: SendIcon,
  settings: SettingsIcon,
  successCheckmark: SuccessCheckmarkIcon,
  usageModeCustomer: UsageModeCustomer,
  usageModePersonal: UsageModePersonal,
  warning: WarningIcon,
}

export const Icon: FC<Props> = memo(({ name, ...props }) => {
  const IconElement = icons[name]

  if (!IconElement) {
    console.warn({ message: `Icon with name: "${name}" does not exist.` })
    return null
  }

  return <IconElement name={name} {...props} />
})

Icon.displayName = 'Icon'
