import { FC, useContext } from 'react'
import classNames from 'classnames'
// components
import { Typography } from 'src/modules/core/components'
import { ProfileInfo } from 'src/modules/user/components'
// context
import { AuthContext } from 'src/modules/user/context'

import type { PageWrapperProps } from './page-wrapper.interface'
import classes from './page-wrapper.module.scss'

export const PageWrapper: FC<PageWrapperProps> = ({
  title,
  className,
  titleClassName,
  wrapperClassName,
  profileInfoClassName,
  authInfo = false,
  children,
}) => {
  const { isAuthenticated } = useContext(AuthContext)

  return (
    <div className={classNames(classes.root, className)}>
      <div className={classNames(classes.wrapper, wrapperClassName)}>
        {title && (
          <Typography
            variant="h1"
            className={classNames(classes.title, titleClassName)}
          >
            {title}
          </Typography>
        )}
        {authInfo && isAuthenticated && (
          <ProfileInfo className={profileInfoClassName} />
        )}
      </div>
      {children}
    </div>
  )
}
