import { memo, forwardRef, HTMLProps } from 'react'
import classNames from 'classnames'
import uniqid from 'uniqid'
// components
import { Icon } from 'src/modules/core/components'

import classes from './radio.module.scss'

export const Radio = memo(
  forwardRef<HTMLInputElement, HTMLProps<HTMLInputElement>>(
    ({ id = uniqid(), ...props }, ref) => {
      return (
        <div className={classes.root}>
          <input
            type="radio"
            id={id}
            className={classes.input}
            ref={ref}
            {...props}
          />
          <label htmlFor={id}>
            <Icon
              name="radioBorder"
              className={classNames(classes.radio, classes.radioBorder)}
            />
            <Icon
              name="radioDot"
              className={classNames(classes.radio, classes.radioDot)}
            />
          </label>
        </div>
      )
    },
  ),
)

Radio.displayName = 'Radio'
