import { FC } from 'react'
// components
import { FormattedText, Modal, Typography } from 'src/modules/core/components'
import classes from './preview-reminder-modal.module.scss'

import type { PreviewReminderModalProps } from './preview-reminder-modal.interface'
export const PreviewReminderModal: FC<PreviewReminderModalProps> = ({ message, onClose, onBack }) => {
  if (!message) {
    onBack()

    return null
  }

  return (
    <Modal
      variant="withBack"
      onClose={onClose}
      onBack={onBack}
      title={
        <Typography variant="h3" className={classes.modalTitle}>
          <FormattedText id="configuration.previewReminderModal.title" />
        </Typography>
      }
    >
      <div className={classes.root}>
        <div className={classes.border} />
        <div className={classes.phoneBody}>
          <Typography variant="span" className={classes.message}>
            {message}
          </Typography>
        </div>
        <div className={classes.phoneBottom} />
      </div>
    </Modal>
  )
}
