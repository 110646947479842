import { FC } from 'react'
import type { SettingsProfileInfoProps } from './settings-profile-info.interface'
import { SettingsProfileInfoCustomerMode } from './settings-profile-info-customer-mode'
import { SettingsProfileInfoPersonalMode } from './settings-profile-info-personal-mode'
import { useProfile } from '../../hooks/use-profile'

export const SettingsProfileInfo: FC<SettingsProfileInfoProps> = ({
  profileInfo,
}) => {
  const { isLoading, data } = useProfile()

  if (isLoading) return null

  return data.usage_mode === 'personal' ? (
    <SettingsProfileInfoPersonalMode profileInfo={profileInfo} />
  ) : (
    <SettingsProfileInfoCustomerMode profileInfo={profileInfo} />
  )
}
