import classNames from 'classnames'
import { FC, memo, useCallback, useState } from 'react'

import type { AccordionProps } from './accordion.interface'
import classes from './accordion.module.scss'

export const Accordion: FC<AccordionProps> = memo(
  ({ className, Title, body }) => {
    // state
    const [isActive, setIsActive] = useState<boolean>(false)

    const onToggle = useCallback(() => {
      setIsActive((prev) => !prev)
    }, [])

    return (
      <div className={className}>
        <div className={classes.title} onClick={onToggle}>
          <Title isActive={isActive} />
        </div>
        <div
          className={classNames(classes.body, {
            [classes.active]: isActive,
          })}
        >
          {body}
        </div>
      </div>
    )
  },
)

Accordion.displayName = 'Accordion'
