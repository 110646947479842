import { useMutation, useQueryClient } from 'react-query'
import { changeArrayElementValues } from 'src/modules/core/utils/helpers'
import { currentMonthParams, eventDetailsParams, prevMonthParams } from 'src/modules/event/constants/event-params'
import { GET_EVENT_BY_ID_CACHE_KEY } from 'src/modules/event/hooks/use-event-by-id'
import { GET_EVENT_LIST_CACHE_KEY } from 'src/modules/event/hooks/use-event-list'
import { LIST_USER_EVENTS_CACHE_KEY } from 'src/modules/user-events/use-list-user-events'
import { paramsWithScheduledReminders } from 'src/modules/user/constants/profile-params'
import { GET_PROFILE_CACHE_KEY } from 'src/modules/user/hooks/use-profile'
import { ApiService } from 'src/services/api.service'

import type { ListEventOptions } from '@grouse-software/types/event/event.api'
import type { EventPreview, UpdateExtendEventBodyDto } from 'src/modules/event/types/event.types'

export const UPDATE_EVENT_CACHE_KEY = 'update-event'

export function useUpdateEvent(requestParams?: ListEventOptions) {
  const queryClient = useQueryClient()

  return useMutation(
    UPDATE_EVENT_CACHE_KEY,
    (params: UpdateExtendEventBodyDto) => ApiService.Events.updateEvent(params),
    {
      onMutate: async (variables) => {
        const updateParams = [currentMonthParams, prevMonthParams, requestParams]

        updateParams.forEach(async (param) => {
          await queryClient.cancelQueries([GET_EVENT_LIST_CACHE_KEY, param])
        })

        updateParams.forEach((param) => {
          queryClient.setQueryData(
            [GET_EVENT_LIST_CACHE_KEY, param],
            changeArrayElementValues<EventPreview>(variables.id, (event) => ({
              ...event,
              active: variables?.active ?? event.active,
            })),
          )
        })

        return variables
      },

      onSuccess: async (_, variables) => {
        queryClient.invalidateQueries([GET_EVENT_LIST_CACHE_KEY])
        queryClient.invalidateQueries([GET_EVENT_BY_ID_CACHE_KEY, variables.id, eventDetailsParams])
        queryClient.invalidateQueries([GET_PROFILE_CACHE_KEY, paramsWithScheduledReminders])

        // Refresh the LIST_USER_EVENTS_CACHE_KEY cache key if the event has been activated
        if (variables?.active) {
          queryClient.invalidateQueries([LIST_USER_EVENTS_CACHE_KEY])
          const updatedValue = await ApiService.UserEvents.listUserEvents()
          queryClient.setQueryData([LIST_USER_EVENTS_CACHE_KEY], updatedValue)
        }
      },
    },
  )
}
