import { useQuery, useQueryClient } from 'react-query';

const MODAL_STATE_KEY = 'auto-reminder-modal-open'

export const useAutoReminderModal = () => {
  const queryClient = useQueryClient()

  const { data: isModalOpen = false } = useQuery(MODAL_STATE_KEY, () => false, {
    initialData: false,
  })

  const setModalOpen = (isOpen: Boolean) => {
    queryClient.setQueryData(MODAL_STATE_KEY, isOpen)
  }

  return { isModalOpen, setModalOpen }
}
