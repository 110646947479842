import classNames from 'classnames'
import Tooltip from 'rc-tooltip'
import { FC, useRef } from 'react'
// components
import { Checkbox, Typography } from 'src/modules/core/components'
import uniqid from 'uniqid'
import classes from './calendar-item.module.scss'

import type { CalendarItemProps } from './calendar-item.interface'
const offsetRight = 0
const offsetBottom = -20

export const CalendarItem: FC<CalendarItemProps> = ({ calendar, selected, disabled, onCheckboxChange, className }) => {
  // ref
  const titleRef = useRef<HTMLParagraphElement>()

  const id = uniqid()

  const getTooltipContainer = () => (titleRef.current.scrollWidth > titleRef.current.clientWidth ? document.body : null)

  return (
    <label
      htmlFor={id}
      className={classNames(classes.calendar, className, {
        [classes.disabled]: disabled,
      })}
    >
      <Checkbox
        type="checkbox"
        checked={selected}
        onChange={onCheckboxChange(calendar.id)}
        id={id}
        disabled={disabled}
        color={calendar.color}
      />
      <Tooltip
        placement="top"
        mouseEnterDelay={0.1}
        motion={{ motionName: 'example' }}
        align={{ offset: [offsetRight, offsetBottom] }}
        overlayClassName={classes.tooltipOverlay}
        getTooltipContainer={getTooltipContainer}
        overlay={
          <Typography variant="body" className={classes.tooltipText}>
            {calendar.title}
          </Typography>
        }
      >
        <Typography variant="body" ref={titleRef}>
          {calendar.title}
        </Typography>
      </Tooltip>
    </label>
  )
}
