import { useQuery, UseQueryOptions } from 'react-query'
import { useHandleRetry } from 'src/modules/core/hooks/use-handle-retry'
import { ApiService } from 'src/services/api.service'

import type { UpdateBillingSubscriptionPreviewDto } from '@grouse-software/types/billing'

export const GET_UPDATE_SUBSCRIPTION_PREVIEW_CACHE_KEY = 'get-update-subscription-preview'

export function useUpdateSubscriptionPreview(
  planId: number,
  options: UseQueryOptions<UpdateBillingSubscriptionPreviewDto> = {
    enabled: true,
  },
) {
  const handleRetry = useHandleRetry()
  return useQuery(
    [GET_UPDATE_SUBSCRIPTION_PREVIEW_CACHE_KEY, planId],
    () => ApiService.Billing.getUpdateSubscriptionPreview(planId),
    {
      enabled: planId !== undefined && options.enabled,
      staleTime: 300000,
      retry: handleRetry,
    },
  )
}
