import 'react-phone-input-2/lib/style.css'
import classNames from 'classnames'
import { useIntl } from 'react-intl'
import img from 'src/assets/images/onboarding-test-reminder-sent.jpg'
import { Button, Modal, Typography } from 'src/modules/core/components'
import { useProfile } from 'src/modules/user/hooks/use-profile'
import { Grid } from '@material-ui/core'
import classes from './test-reminder-sent-modal.module.scss'

export interface TestReminderSentModalProps {
  onClick?: () => void
}

export function TestReminderSentModal({ onClick = () => {} }: TestReminderSentModalProps) {
  const { formatMessage } = useIntl()
  const profile = useProfile()

  return (
    <Modal className={classNames(classes.modal, 'translate')}>
      <Grid container direction="row" justifyContent="center" alignItems="center" spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h3" className={classes.modalTitle}>
            {formatMessage({ id: 'onboarding.testReminderSent.title' })}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <p>{formatMessage({ id: 'onboarding.testReminderSent.text' })}</p>
        </Grid>
        <Grid item>
          <img src={img} alt="Mobile phone with SMS tag" className={classes.image} />
        </Grid>
        <Grid item>
          <p>{formatMessage({ id: 'onboarding.testReminderSent.checkSpamFolder' })}</p>
        </Grid>
        <Grid item>
          <Button variant="primary" onClick={onClick}>
            {formatMessage({
              id: profile.data.refresh_token
                ? 'onboarding.testReminderSent.goToDashboard'
                : 'onboarding.testReminderSent.nextStep',
            })}
          </Button>
        </Grid>
      </Grid>
    </Modal>
  )
}
