import { useQuery } from 'react-query'
import { useHandleRetry } from 'src/modules/core/hooks/use-handle-retry'
import { ApiService } from 'src/services/api.service'

export const GET_GOOGLE_PROFILE_CACHE_KEY = 'get-google-profile'

export function useGoogleProfile(enabled = true) {
  const handleRetry = useHandleRetry()
  return useQuery(GET_GOOGLE_PROFILE_CACHE_KEY, () => ApiService.Profile.getGoogleProfile(), {
    enabled,
    staleTime: 300000, // 5 minutes
    retry: handleRetry,
  })
}
