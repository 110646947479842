import { useMemo } from 'react';
import { useListUserEvents } from './use-list-user-events';
import { UserEventType } from './user-events.constants';

export function useHasUserEvent(eventName: UserEventType) {
  const { data: userEvents, isLoading, isError, ...rest } = useListUserEvents()

  const hasEvent = useMemo(() => {
    if (isLoading || isError) return null
    return userEvents?.some((event) => event.event === eventName) ?? false
  }, [userEvents, eventName, isLoading, isError])

  return {
    data: hasEvent,
    isLoading,
    isError,
    ...rest,
  }
}
