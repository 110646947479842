import classNames from 'classnames';
import { FC, memo, useMemo } from 'react';
import {
    Button, Features, FormattedText, Typography
} from 'src/modules/core/components';
import classes from './subscription-plan-card.module.scss';

import type { FreePlanCardProps } from './free-plan-card.interface'
export const FreePlanCard: FC<FreePlanCardProps> = memo(({ plan }) => {
  return (
    <div>
      <Typography variant="h2">Free Trial</Typography>
      <Typography variant="span">Try SMS Reminder App for free</Typography>
      <div className={classes.price}>
        <Typography variant="h1">Free</Typography>
      </div>
      <Button className={classes.button} disabled onClick={() => {}}>
        <FormattedText id="payment.button.currentPlan" />
      </Button>
      <Features
        className={classNames(classes.features)}
        list={['Send 25 free reminders', 'Unlimited calendars', '1 user']}
      />
    </div>
  )
})

FreePlanCard.displayName = 'FreePlanCard'
