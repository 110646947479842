import { useContext, useState } from 'react'
import { ConfigurationStepContext } from 'src/modules/core/context'
import * as amplitude from '@amplitude/analytics-browser'
import { useOptimisticUpdateProfile } from '../../hooks/use-optimistic-update-profile'
import { useProfile } from '../../hooks/use-profile'
import { AccountCreatedModal } from './account-created-modal'
import { SendTestReminderEventsModal } from './send-test-reminder-events-modal'
import { SyncOfflineChangesModal } from './sync-offline-changes-modal'
import { TestReminderSentModal } from './test-reminder-sent-modal'

export interface SendTestReminderFlowProps {
  active?: boolean
  sendTestReminder?: boolean
}

export function SendTestOrFinishFlow({ active = false, sendTestReminder = false }: SendTestReminderFlowProps) {
  const [skipTestReminder, setSkipTestReminder] = useState(false)
  const [testReminderSent, setTestReminderSent] = useState(false)
  const [proceedToSecondOAuth, setProceedToSecondOAuth] = useState(false)

  const profile = useProfile()
  const optimisticUpdateProfile = useOptimisticUpdateProfile()
  const { handleNext } = useContext(ConfigurationStepContext)

  function handleAccountCreatedClick() {
    amplitude.track('Onboarding_AccountComplete_NextStep_button')
    amplitude.track('message_template_complete')
    if (profile.data.refresh_token) {
      optimisticUpdateProfile.mutate({ completed_onboarding: true }, { onSuccess: () => handleNext() })
    } else {
      setProceedToSecondOAuth(true)
    }
  }

  if (!active) {
    return null
  }

  if (sendTestReminder && !skipTestReminder && !testReminderSent) {
    return (
      <SendTestReminderEventsModal onSkip={() => setSkipTestReminder(true)} onSent={() => setTestReminderSent(true)} />
    )
  }

  if (sendTestReminder && testReminderSent && !proceedToSecondOAuth) {
    return <TestReminderSentModal onClick={handleAccountCreatedClick} />
  }

  if (!proceedToSecondOAuth) {
    return <AccountCreatedModal onClick={handleAccountCreatedClick} />
  }

  return <SyncOfflineChangesModal />
}
