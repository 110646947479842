import { FC, useState } from 'react'
import { FormattedText, Typography } from 'src/modules/core/components'
import { SettingsProfileInfoForm } from 'src/modules/user/components'
import { SettingsBlockWrapper } from 'src/modules/user/containers'
import { timingOptions } from 'src/modules/user/constants/profile.constants'
import type { SettingsProfileInfoProps } from './settings-profile-info.interface'
import classes from './settings-profile-info.module.scss'

export const SettingsProfileInfoPersonalMode: FC<SettingsProfileInfoProps> = ({
  profileInfo,
}) => {
  const [isProfileInfoEditing, setIsProfileInfoEditing] =
    useState<boolean>(false)

  const handleChangeProfileInfo = () => {
    setIsProfileInfoEditing(true)
  }

  return (
    <SettingsBlockWrapper
      title={<FormattedText id="settings.title.profile" />}
      onClick={handleChangeProfileInfo}
    >
      <div className={classes.profileInfo}>
        {isProfileInfoEditing ? (
          <SettingsProfileInfoForm
            profileInfo={profileInfo}
            setIsProfileInfoEditing={setIsProfileInfoEditing}
          />
        ) : (
          <>
            <div className={classes.infoBlock}>
              <Typography className={classes.infoLabel} variant="span">
                <FormattedText id="configuration.step1.label.from" />
              </Typography>
              <Typography variant="body" className={classes.infoText}>
                {profileInfo?.sender_name}
              </Typography>
            </div>
            <div className={classes.infoBlock}>
              <Typography className={classes.infoLabel} variant="span">
                <FormattedText id="configuration.step1.personalMode.phone.label" />
              </Typography>
              {(profileInfo?.phone_numbers ?? []).map((phone) => (
                <Typography
                  variant="body"
                  className={classes.infoText}
                  key={phone}
                >
                  {phone}
                </Typography>
              ))}
            </div>
            <div className={classes.infoBlock}>
              <Typography className={classes.infoLabel} variant="span">
                <FormattedText id="configuration.step1.label.location" />
              </Typography>
              <Typography variant="body" className={classes.infoText}>
                {profileInfo?.default_location}
              </Typography>
            </div>
            <div className={classes.infoBlock}>
              <Typography className={classes.infoLabel} variant="span">
                <FormattedText id="configuration.step1.label.reminder" />
              </Typography>
              <Typography variant="body" className={classes.infoText}>
                {profileInfo?.default_message_text}
              </Typography>
            </div>
            <div className={classes.infoBlock}>
              <Typography className={classes.infoLabel} variant="span">
                <FormattedText id="configuration.step1.timing.title" />
              </Typography>
              <Typography variant="body" className={classes.infoText}>
                {profileInfo?.default_time_before
                  ?.map((t) => {
                    const currentTiming = timingOptions.find(
                      (option) => +option.value === t,
                    )

                    return `${currentTiming.label} before`
                  })
                  .join(', ')}
              </Typography>
              <div className={classes.infoBlock}>
                <Typography className={classes.infoLabel} variant="span">
                  <FormattedText id="settings.personalMode.autoReminders.label" />
                </Typography>
                <Typography variant="body" className={classes.infoText}>
                  <FormattedText
                    id={
                      !!profileInfo?.personal_reminders_for_all_appointments
                        ? 'common.enabled'
                        : 'common.disabled'
                    }
                  />
                </Typography>
              </div>
            </div>
          </>
        )}
      </div>
    </SettingsBlockWrapper>
  )
}
