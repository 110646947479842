import { useEffect, useRef } from 'react';
import { FormattedText, Modal, Typography } from 'src/modules/core/components';
import { useAddUserEvent } from 'src/modules/user-events/use-add-event';
import { useHasUserEvent } from 'src/modules/user-events/use-has-user-event';
import { USER_EVENTS } from 'src/modules/user-events/user-events.constants';
import { useProfile } from 'src/modules/user/hooks/use-profile';
import classes from './auto-reminder-modal.module.scss';
import { useAutoReminderModal } from './use-auto-reminder-modal';

export function AutoReminderModal() {
  const profile = useProfile()
  const addUserEvent = useAddUserEvent()
  const calloutActivated = useHasUserEvent(USER_EVENTS.AUTO_REMINDERS_CALLOUT_ACTIVATED)
  const modalSeen = useHasUserEvent(USER_EVENTS.AUTO_REMINDERS_MODAL_SEEN)
  const modalSeenTriggered = useRef(false)

  const { isModalOpen, setModalOpen } = useAutoReminderModal()

  useEffect(() => {
    if (
      !modalSeen.isLoading &&
      !calloutActivated.isLoading &&
      calloutActivated.data === true &&
      modalSeen.data === false &&
      !modalSeenTriggered.current
    ) {
      setModalOpen(true)
      modalSeenTriggered.current = true
      addUserEvent.mutate({ eventName: USER_EVENTS.AUTO_REMINDERS_MODAL_SEEN, once: true })
    }
  }, [
    calloutActivated.data,
    modalSeen.data,
    calloutActivated.isLoading,
    modalSeen.isLoading,
    addUserEvent,
    calloutActivated,
    modalSeen,
    setModalOpen,
  ])

  if (calloutActivated.isLoading || modalSeen.isLoading || profile.isLoading || !isModalOpen) {
    return null
  }

  return (
    <Modal
      variant="primary"
      onClose={() => setModalOpen(false)}
      title={
        <Typography variant="h3" className={classes.modalTitle}>
          <FormattedText id="autoRemindersModal.title" />
        </Typography>
      }
    >
      <div className={classes.root}>
        <p>
          <FormattedText id="autoRemindersModal.lead" />
        </p>
        <p>
          <FormattedText id="autoRemindersModal.text" />
        </p>
        <div className={classes.box}>
          <h3>
            <FormattedText id="autoRemindersModal.eventTitle.title" />
          </h3>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/recordatorio-de-cita.appspot.com/o/auto-reminders-title.jpg?alt=media&token=39b403f7-6423-46fb-8bbd-e2c26f7af645"
            alt="Auto Reminders in the Event Title"
          />
        </div>
        <div className={classes.box}>
          <h3>
            <FormattedText id="autoRemindersModal.eventDescription.title" />
          </h3>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/recordatorio-de-cita.appspot.com/o/auto-reminders-description.jpg?alt=media&token=be64bcce-832c-40e6-b5fe-38dad286f3a2"
            alt="Auto Reminders in the Event Description"
          />
          <p>
            <FormattedText id="autoRemindersModal.eventDescription.text" />
          </p>
        </div>
      </div>
    </Modal>
  )
}
