import {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useNavigate } from 'react-router-dom'
import uniqid from 'uniqid'
import type { UpdateCalendarBodyDto } from '@grouse-software/types/calendar'
// components
import {
  FormattedText,
  InfoModal,
  Loader,
  Typography,
} from 'src/modules/core/components'
import { CalendarItem } from 'src/modules/calendar/components'
// hooks
import { useCalendars } from 'src/modules/calendar/hooks/use-calendars'
import { useUpdateCalendar } from 'src/modules/calendar/hooks/use-update-calendar'
import { useCalendarById } from 'src/modules/calendar/hooks/use-calendar-by-id'
import { usePlanData } from 'src/modules/payment/hooks/use-plan-data'
// constants
import { routeNames } from 'src/modules/core/constants/routeNames'
import { paramsWithScheduledReminders } from 'src/modules/user/constants/profile-params'
// utils
import {
  sortCalendars,
  isCalendarLimitReached,
} from 'src/modules/calendar/utils/helpers'

import type { CalendarListProps } from './calendar-list.interface'
import classes from './calendar-list.module.scss'

export const CalendarList: FC<CalendarListProps> = ({
  withUpgradeButton,
  listClassName,
  itemClassName,
  children,
}) => {
  // state
  const [updatedCalendar, setUpdatedCalendar] =
    useState<UpdateCalendarBodyDto>(null)
  const [unselectedCalendar, setUnselectedCalendar] =
    useState<UpdateCalendarBodyDto>(null)
  // hooks
  const navigate = useNavigate()
  const calendars = useCalendars({}, { sync: true })
  const updateCalendar = useUpdateCalendar()
  const calendarById = useCalendarById(
    unselectedCalendar?.id,
    paramsWithScheduledReminders,
  )
  const { currentPlan, loading } = usePlanData({
    subscriptionOptions: { enabled: true },
  })
  // memo
  const isLoading = useMemo(
    () => calendars.isLoading || calendarById.isLoading || loading,
    [calendars.isLoading, calendarById.isLoading, loading],
  )

  const processedCalendars = useMemo(
    () => sortCalendars(calendars.data),
    [calendars.data],
  )

  const selectedCalendars = processedCalendars.filter((item) => item.selected)

  const handleUpdateCalendar = useCallback(
    (calendar: UpdateCalendarBodyDto) => {
      setUpdatedCalendar(calendar)

      updateCalendar.mutate(calendar, {
        onSettled: () => setUpdatedCalendar(null),
      })
    },
    [updateCalendar],
  )

  const handleCheckboxChange = useCallback(
    (id: string) => (e: ChangeEvent<HTMLInputElement>) => {
      const newCalendar: UpdateCalendarBodyDto = {
        id,
        selected: e.target.checked,
      }

      if (e.target.checked) {
        handleUpdateCalendar(newCalendar)
      } else {
        setUnselectedCalendar(newCalendar)
      }
    },
    [handleUpdateCalendar],
  )

  const onSuccess = useCallback(() => {
    handleUpdateCalendar(unselectedCalendar)
    setUnselectedCalendar(null)
  }, [handleUpdateCalendar, unselectedCalendar])

  const onClose = () => {
    setUnselectedCalendar(null)
  }

  useEffect(() => {
    if (
      calendarById.data &&
      calendarById.data.include.count_scheduled_reminders === 0
    ) {
      onSuccess()
    }
  }, [calendarById.data, onSuccess])

  return (
    <>
      {isLoading ? (
        <div className={classes.loadingWrapper}>
          <Loader isLoading={isLoading} />
        </div>
      ) : (
        <>
          <ul className={listClassName}>
            {processedCalendars.map((calendar) => {
              const limitReached =
                isCalendarLimitReached(
                  currentPlan,
                  calendar,
                  selectedCalendars,
                ) && !calendar.selected
              const disabled = updateCalendar.isLoading || limitReached

              return (
                <li key={uniqid()} className={classes.item}>
                  <CalendarItem
                    calendar={calendar}
                    selected={
                      updatedCalendar?.id === calendar.id
                        ? updatedCalendar.selected
                        : calendar.selected
                    }
                    disabled={disabled}
                    onCheckboxChange={handleCheckboxChange}
                    className={itemClassName}
                  />
                  {limitReached && withUpgradeButton && (
                    <Typography
                      variant="span"
                      className={classes.textButton}
                      onClick={() =>
                        navigate(`${routeNames.settings.path}?tab=2`)
                      }
                    >
                      <FormattedText id="calendars.dashboard.button" />
                    </Typography>
                  )}
                </li>
              )
            })}
          </ul>
          {unselectedCalendar && (
            <InfoModal
              title={<FormattedText id="calendars.warning.title" />}
              description={
                <FormattedText
                  id="calendars.warning.description"
                  values={{
                    strong: (chunks) => <strong>{chunks}</strong>,
                    count: calendarById.data.include.count_scheduled_reminders,
                  }}
                />
              }
              button={<FormattedText id="common.continue" />}
              onClick={onSuccess}
              onClose={onClose}
            />
          )}
          {children}
        </>
      )}
    </>
  )
}
