import { useCallback, useEffect, useState } from 'react'
// types
import { ChangelessBehaviorSubject } from 'src/modules/core/types/rxjs.types'

type DataType = object | string | number | boolean | undefined | null

function useObservable<T, S>(
  subject: ChangelessBehaviorSubject<T>,
  selector?: (obj: T) => S,
) {
  const select = useCallback(
    (obj: T): S extends DataType ? S : T => {
      // @ts-ignore
      return selector ? selector(obj) : obj
    },
    [selector],
  )

  const [val, setVal] = useState(select(subject.value))

  const updateValue = useCallback(
    (nextValue: T) => {
      const newValue = select(nextValue)

      if (JSON.stringify(val) !== JSON.stringify(newValue)) {
        setVal(newValue)
      }
    },
    [select, val],
  )

  useEffect(() => {
    const subscription = subject.subscribe(updateValue)

    return () => {
      subscription.unsubscribe()
    }
  }, [subject, updateValue])

  return val
}

export { useObservable }
