import { FC, useState } from 'react'
// context
import { ConfigurationStepContext } from 'src/modules/core/context/configuration-step-context'

export const ConfigurationStepProvider: FC = ({ children }) => {
  const [step, setStep] = useState<number>(1)

  const handleNext = () => {
    setStep((prev) => prev + 1)
  }

  return (
    <ConfigurationStepContext.Provider
      value={{
        step,
        handleNext,
      }}
    >
      {children}
    </ConfigurationStepContext.Provider>
  )
}
