import classNames from 'classnames'
import { FC, Fragment, memo, useMemo } from 'react'
// components
import { Typography } from 'src/modules/core/components/typography'
import classes from './stepper.module.scss'

import type { StepperProps } from './stepper.interface'
export const Stepper: FC<StepperProps> = memo(({ className, numberOfSteps = 3, activeStep }) => {
  const array = useMemo(() => Array(numberOfSteps).fill(null), [numberOfSteps])

  return (
    <div className={classNames(classes.root, className)}>
      <div className={classes.wrapper}>
        {array.map((_, index) => (
          <Fragment key={index}>
            <div
              className={classNames(classes.circle, {
                [classes.filled]: index + 1 <= activeStep,
              })}
            >
              <Typography
                variant="body"
                className={classNames(classes.number, {
                  [classes.active]: index + 1 <= activeStep,
                })}
              >
                {index + 1}
              </Typography>
            </div>
            {index !== array.length - 1 && <div className={classes.line} />}
          </Fragment>
        ))}
      </div>
    </div>
  )
})

Stepper.displayName = 'Stepper'
