import classNames from 'classnames'
import {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useNavigate } from 'react-router-dom'
import { useIntl } from 'react-intl'
import type { BillingPlanDto } from '@grouse-software/types/billing/billing.dto'
import type { UpdateCalendarBodyDto } from '@grouse-software/types/calendar'
// components
import {
  FormattedText,
  InfoModal,
  Loader,
  ToggleSwitch,
  Typography,
} from 'src/modules/core/components'
import { SubscriptionPlanCard } from 'src/modules/payment/components'
import { CalendarSelectionModal } from 'src/modules/calendar/components'
// hooks
import { useSubscription } from 'src/modules/payment/hooks/use-subscription'
import { useProfile } from 'src/modules/user/hooks/use-profile'
import { useFreePlan } from 'src/modules/payment/hooks/use-free-plan'
import { useUpdateSubscriptionPreview } from 'src/modules/payment/hooks/use-update-subscription-preview'
import { useChangeSubscription } from 'src/modules/payment/hooks/use-change-subscription'
import { useCalendars } from 'src/modules/calendar/hooks/use-calendars'
import { usePlanData } from 'src/modules/payment/hooks/use-plan-data'
import { useBulkUpdateCalendars } from 'src/modules/calendar/hooks/use-bulk-update-calendars'
// constants
import { routeNames } from 'src/modules/core/constants/routeNames'
import { billingTypes } from 'src/modules/payment/constants/payment.constants'
// utils
import { isPlanNotForSale } from 'src/modules/payment/utils/helpers'
import { isCalendarLimitExceeded } from 'src/modules/calendar/utils/helpers'
// types
import type { Status } from 'src/modules/core/types/status.types'
import { SubscriptionStatus } from 'src/modules/payment/types/payment.types'

import classes from './subscription-plan-tab.module.scss'
import { FreePlanCard } from '../../components/subscription-plan-card/free-plan-card'

type ExtendStatus = Status | 'trialError' | 'canceledError' | 'pastDueError'

export const SubscriptionPlanTab: FC = () => {
  // state
  const [isYearly, setIsYearly] = useState(true)
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)
  const [isCalendarSelectionModalOpen, setIsCalendarSelectionModalOpen] =
    useState(false)
  const [newPlan, setNewPlan] = useState<BillingPlanDto>(null)
  const [status, setStatus] = useState<ExtendStatus>(null)
  // hooks
  const navigate = useNavigate()
  const { formatMessage } = useIntl()
  const calendars = useCalendars()
  const profile = useProfile()
  const subscriptionOptions = {
    enabled: !!profile.data?.billing_subscription_id,
  }
  const subscription = useSubscription({}, subscriptionOptions)
  const freePlan = useFreePlan()
  const updateSubscriptionPreview = useUpdateSubscriptionPreview(
    newPlan?.paddle_id,
    { enabled: isConfirmModalOpen },
  )
  const bulkUpdateCalendars = useBulkUpdateCalendars()
  const changeSubscription = useChangeSubscription()
  const { currentPlan, availablePlans, loading } = usePlanData({
    isYearly,
    subscriptionOptions,
  })

  // memo
  const isLoading = useMemo(
    () =>
      subscription.isLoading ||
      freePlan.isLoading ||
      loading ||
      updateSubscriptionPreview.isLoading ||
      changeSubscription.isLoading ||
      bulkUpdateCalendars.isLoading,
    [
      subscription.isLoading,
      freePlan.isLoading,
      loading,
      updateSubscriptionPreview.isLoading,
      bulkUpdateCalendars.isLoading,
      changeSubscription.isLoading,
    ],
  )

  const isOnFreePlan = currentPlan.name === freePlan?.data?.name

  useEffect(() => {
    setIsYearly(currentPlan?.billing_type === billingTypes.longInterval)
  }, [currentPlan?.billing_type])

  const onSwitchChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setIsYearly(e.target.checked)
  }, [])

  const handleOpenConfirmModal = useCallback(
    (plan: BillingPlanDto) => () => {
      const defaultSelectedCalendars = (calendars.data || []).filter(
        (calendar) => calendar.selected,
      )

      if (isOnFreePlan) {
        navigate(routeNames.subscriptionPlans.path)
      } else if (subscription.data?.state === SubscriptionStatus.trialing) {
        setStatus('trialError')
      } else if (subscription.data?.state === SubscriptionStatus.deleted) {
        setStatus('canceledError')
      } else if (subscription.data?.state === SubscriptionStatus.past_due) {
        setStatus('pastDueError')
      } else if (isCalendarLimitExceeded(plan, defaultSelectedCalendars)) {
        setNewPlan(plan)
        setIsCalendarSelectionModalOpen(true)
      } else {
        // handles isPlanNotForSale(currentPlan)
        setNewPlan(plan)
        setIsConfirmModalOpen(true)
      }
    },
    [calendars.data, subscription.data?.state, navigate, isOnFreePlan],
  )

  const onSelectCalendars = (updatedCalendars: UpdateCalendarBodyDto[]) => {
    setIsCalendarSelectionModalOpen(false)
    bulkUpdateCalendars.mutate(updatedCalendars, {
      onSuccess: () => setIsConfirmModalOpen(true),
    })
  }

  const handleChangeSubscription = useCallback(() => {
    changeSubscription.mutate(
      {
        plan_id: newPlan.paddle_id.toString(),
        bill_immediately: true,
      },
      {
        onSuccess: () => setStatus('success'),
        onError: () => setStatus('error'),
        onSettled: () => setIsConfirmModalOpen(false),
      },
    )
  }, [newPlan?.paddle_id, changeSubscription])

  const handleCancel = useCallback(() => {
    navigate(routeNames.cancelPlan.path)
  }, [navigate])

  const onClose = useCallback(() => setStatus(null), [])

  if (isLoading) return <Loader isLoading={isLoading} />

  return (
    <div>
      {isPlanNotForSale(currentPlan) && (
        <>
          <Typography variant="body" className={classes.subtitle}>
            <FormattedText id="payment.notForSale.title" />
          </Typography>
          <Typography variant="span" className={classes.text}>
            <FormattedText
              id="payment.notForSale.text"
              values={{ br: () => <br /> }}
            />
          </Typography>
          <SubscriptionPlanCard
            plan={{
              ...currentPlan,
              description: formatMessage({
                id: 'payment.notForSale.description',
              }),
              benefits: null,
            }}
            currentPlanId={subscription?.data?.plan_id}
            handleUpgradePlan={handleOpenConfirmModal}
            className={classes.subtitle}
          />
          <Typography variant="h1" className={classes.title}>
            <FormattedText id="payment.title" />
          </Typography>
        </>
      )}
      <div className={classes.switchWrapper}>
        {subscription.data?.state}
        <Typography
          variant="body"
          className={classNames(classes.switchLabel, {
            [classes.selected]: !isYearly,
          })}
        >
          <FormattedText id="payment.switch.monthly" />
        </Typography>
        <ToggleSwitch
          variant="secondary"
          name="periodSwitch"
          checked={isYearly}
          onChange={onSwitchChange}
        />
        <Typography
          variant="body"
          className={classNames(classes.switchLabel, {
            [classes.selected]: isYearly,
          })}
        >
          <FormattedText id="payment.switch.yearly" />
        </Typography>
      </div>
      <div className={classes.plans}>
        {isOnFreePlan && <FreePlanCard plan={freePlan?.data} />}
        {availablePlans.map((plan) => (
          <SubscriptionPlanCard
            key={plan.paddle_id}
            plan={plan}
            currentPlanId={subscription?.data?.plan_id}
            handleUpgradePlan={handleOpenConfirmModal}
            featuresClassName={classes.features}
          />
        ))}
      </div>
      {subscription.data?.state !== SubscriptionStatus.deleted &&
        !isOnFreePlan && (
          <Typography
            variant="span"
            className={classes.cancelButton}
            onClick={handleCancel}
          >
            <FormattedText id="payment.button.cancelPlan" />
          </Typography>
        )}
      {isCalendarSelectionModalOpen && (
        <CalendarSelectionModal
          subscriptionPlan={newPlan}
          title={<FormattedText id="calendars.settings.modal.title" />}
          button={
            <FormattedText id="payment.subscription.change.confirm.button.change" />
          }
          closeButton={<FormattedText id="common.doNotMakeChanges" />}
          onButtonClick={onSelectCalendars}
          onClose={() => setIsCalendarSelectionModalOpen(false)}
          selectFromExistingCalendars
          isNewSubscriptionPlan
        />
      )}
      {isCalendarSelectionModalOpen && (
        <CalendarSelectionModal
          subscriptionPlan={newPlan}
          title={<FormattedText id="calendars.settings.modal.title" />}
          button={
            <FormattedText id="payment.subscription.change.confirm.button.change" />
          }
          closeButton={<FormattedText id="common.doNotMakeChanges" />}
          onButtonClick={onSelectCalendars}
          onClose={() => setIsCalendarSelectionModalOpen(false)}
          selectFromExistingCalendars
          isNewSubscriptionPlan
        />
      )}
      {isConfirmModalOpen && (
        <InfoModal
          title={
            <FormattedText id="payment.subscription.change.confirm.title" />
          }
          description={
            <>
              <FormattedText
                id="payment.subscription.change.confirm.description"
                values={{
                  strong: (chunks) => <strong>{chunks}</strong>,
                  currentPlan: currentPlan.name,
                  newPlan: newPlan.name,
                }}
              />{' '}
              <FormattedText
                id={
                  updateSubscriptionPreview.data?.immediate_payment.amount < 0
                    ? 'payment.subscription.change.confirm.negative'
                    : 'payment.subscription.change.confirm.positive'
                }
                values={{
                  strong: (chunks) => <strong>{chunks}</strong>,
                  amount: Math.abs(
                    updateSubscriptionPreview.data?.immediate_payment.amount,
                  ),
                }}
              />
            </>
          }
          closeButton={<FormattedText id="common.doNotMakeChanges" />}
          button={
            <FormattedText id="payment.subscription.change.confirm.button.change" />
          }
          onClick={handleChangeSubscription}
          onClose={() => setIsConfirmModalOpen(false)}
        />
      )}
      {status === 'success' && (
        <InfoModal
          title={
            <FormattedText id="payment.subscription.change.success.title" />
          }
          description={
            <FormattedText
              id="payment.subscription.change.success.description"
              values={{
                strong: (chunks) => <strong>{chunks}</strong>,
                plan: newPlan.name,
              }}
            />
          }
          button={<FormattedText id="common.ok" />}
          onClick={onClose}
          onClose={onClose}
        />
      )}
      {status === 'error' && (
        <InfoModal
          title={<FormattedText id="payment.subscription.change.error.title" />}
          description={
            <FormattedText id="payment.subscription.change.error.description" />
          }
          button={<FormattedText id="common.ok" />}
          onClick={onClose}
          onClose={onClose}
        />
      )}
      {status === 'trialError' && (
        <InfoModal
          title={
            <FormattedText id="payment.subscription.change.trialError.title" />
          }
          description={
            <FormattedText id="payment.subscription.change.trialError.description" />
          }
          button={<FormattedText id="common.ok" />}
          onClick={onClose}
          onClose={onClose}
        />
      )}
      {status === 'canceledError' && (
        <InfoModal
          title={
            <FormattedText id="payment.subscription.change.trialError.title" />
          }
          description={
            <FormattedText id="payment.subscription.change.canceledError.description" />
          }
          button={<FormattedText id="common.ok" />}
          onClick={onClose}
          onClose={onClose}
        />
      )}
      {status === 'pastDueError' && (
        <InfoModal
          title={
            <FormattedText id="payment.subscription.change.trialError.title" />
          }
          description={
            <FormattedText id="payment.subscription.change.pastDueError.description" />
          }
          button={<FormattedText id="common.ok" />}
          onClick={onClose}
          onClose={onClose}
        />
      )}
    </div>
  )
}
