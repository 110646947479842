import type { UseQueryOptions } from 'react-query'
import type {
  BillingSubscriptionDto,
  BillingPlanDto,
} from '@grouse-software/types/billing/billing.dto'
import { GetSubscriptionPlansDto } from 'src/modules/billing/types/billing.types'

export type UsePlanDataOptions = {
  subscriptionOptions?: UseQueryOptions<BillingSubscriptionDto>
  subscriptionPlansOptions?: UseQueryOptions<{ data: BillingPlanDto[] }>
  isYearly?: boolean
  type?: GetSubscriptionPlansDto['type']
}

export type BillingType = 'month' | 'year' | 'day' | 'week'

export type BillingTypes = {
  shortInterval: BillingType
  longInterval: BillingType
}

export enum SubscriptionStatus {
  active = 'active',
  trialing = 'trialing',
  past_due = 'past_due',
  paused = 'paused',
  deleted = 'deleted',
}

export enum CalendarsLimitType {
  unlimited = 'unlimited',
  primary = 'primary',
  default = 'default',
}
