import { FC } from 'react'
// hooks
import { useCrisp } from 'src/modules/core/hooks/use-crisp'
// constants
import { routeNames } from 'src/modules/core/constants/routeNames'

import classes from './general-layout.module.scss'

const isCrispVisible =
  location.pathname !== routeNames.terms.path &&
  location.pathname !== routeNames.privacy.path &&
  !location.pathname.includes(routeNames.confirmation.path)

export const GeneralLayout: FC = ({ children }) => {
  useCrisp(isCrispVisible)

  return (
    <div className={classes.root}>
      <div className={classes.content}>{children}</div>
    </div>
  )
}
