import Tooltip from 'rc-tooltip'
import { FC, memo } from 'react'
// components
import { FormattedText, Icon, Typography } from 'src/modules/core/components'
import classes from './create-reminder-helper.module.scss'

import type { CreateReminderHelperProps } from './create-reminder-helper.interface'

const offsetRight = 72
const offsetBottom = -20

export const CreateReminderHelper: FC<CreateReminderHelperProps> = memo(({ className }) => {
  return (
    <div className={className}>
      <Tooltip
        placement="top"
        mouseEnterDelay={0.1}
        transitionName={'example'}
        align={{ offset: [offsetRight, offsetBottom] }}
        overlayClassName={classes.tooltipOverlay}
        overlay={
          <Typography variant="body" className={classes.bodyText}>
            <FormattedText id="configuration.step1.body.main" />
            <br />
            <br />
            <span className="notranslate">{`{title}`} - </span>
            <FormattedText id="configuration.step1.body.title" />
            <br />
            <span className="notranslate">{`{date}`} - </span>
            <FormattedText id="configuration.step1.body.date" />
            <br />
            <span className="notranslate">{`{time}`} - </span>
            <FormattedText id="configuration.step1.body.time" />
            <br />
            <span className="notranslate">{`{from}`} - </span>
            <FormattedText id="configuration.step1.body.from" />
            <br />
            <span className="notranslate">{`{location}`} - </span>
            <FormattedText id="configuration.step1.body.location" />
            <br />
            <span className="notranslate">{`{eventlocation}`} - </span>
            <FormattedText id="configuration.step1.body.eventlocation" />
          </Typography>
        }
      >
        <a href="#" className={classes.tooltipLink}>
          <Typography variant="body" className={classes.tooltipText}>
            <FormattedText id="configuration.step1.body.hoverText" />
          </Typography>
          <Icon name="questionMarkPrimary" />
        </a>
      </Tooltip>
    </div>
  )
})

CreateReminderHelper.displayName = 'CreateReminderHelper'
