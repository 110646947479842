import { PaddlePlanData } from '../hooks/use-paddle-plan-data'

/**
 * Processes subscription pricing data and returns the correctly formatted monthly price.
 *
 * The function takes an input data object containing pricing information for a subscription,
 * extracts the recurring price details, and returns the appropriate net price based on the
 * type of subscription.
 *
 * - If the subscription type is "month", the function directly returns the net price as a string.
 * - If the subscription type is "year", it extracts the numerical part of the net price,
 *   divides it by 12 to calculate the monthly equivalent, and then formats the resulting price
 *   while preserving the original currency, spacing, and formatting.
 *
 * @param {InputData} data The input object containing price, quantity, country, and recurring subscription details.
 * @returns {string} The correctly formatted net monthly price for the subscription.
 *
 * @example
 * // Example 1: Yearly Subscription
 * const example1 = {
 *   price: { gross: "US$0.00", net: "US$0.00", tax: "US$0.00", tax_included: true },
 *   quantity: 1,
 *   country: "BR",
 *   recurring: {
 *     price: { gross: "US$276.00", net: "US$276.00", tax: "US$0.00", tax_included: false },
 *     subscription: { trial_days: 14, length: 1, type: "year" }
 *   }
 * };
 * getLocalizedPaddlePlanPrice(example1);
 * // Returns: "US$23.00"
 *
 * @example
 * // Example 2: Monthly Subscription
 * const example2 = {
 *   price: { gross: "US$29.00", net: "US$29.00", tax: "US$0.00", tax_included: true },
 *   quantity: 1,
 *   country: "BR",
 *   recurring: {
 *     price: { gross: "US$29.00", net: "US$29.00", tax: "US$0.00", tax_included: false },
 *     subscription: { trial_days: 0, length: 1, type: "month" }
 *   }
 * };
 * getLocalizedPaddlePlanPrice(example2);
 * // Returns: "US$29.00"
 */
export function getLocalizedPaddlePlanPrice(data: PaddlePlanData): string {
  const recurringPrice = data.recurring.price
  const subscriptionType = data.recurring.subscription.type
  const country = data.country

  // Extract numerical value from the net price string
  const netPrice = recurringPrice.net

  // Use regex to capture the numerical value
  const numberMatch = netPrice.match(/[\d,.]+/)
  if (!numberMatch) {
    return netPrice
  }

  // Convert the numerical value to a number
  const numberString = numberMatch[0].replace(/,/g, '')
  const originalAmount = Number(numberString)

  // Calculate the monthly equivalent of the original price
  const divideBy = subscriptionType === 'year' ? 12 : 1
  const monthlyAmount = originalAmount / divideBy

  // Reformat the monthly price in the original currency format
  return formatPrice(netPrice, monthlyAmount, country)
}

function formatPrice(original: string, amount: number, country: string): string {
  const currencySymbol = getCurrencySymbol(original, country)
  const formattedAmount = formatNumberLocalized(amount)
  return `${currencySymbol}${formattedAmount}`
}

function getCurrencySymbol(original: string, country: string): string {
  const defaultSymbol = '$'
  if (country === 'US') {
    return defaultSymbol
  }
  const currencySymbolMatch = original.match(/^[^\d,.]+/)
  if (currencySymbolMatch) {
    return currencySymbolMatch[0] || defaultSymbol
  }
  return defaultSymbol
}

function formatNumberLocalized(amount: number): string {
  const locale = navigator.language || 'en-US'
  const formatter = new Intl.NumberFormat(locale, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
  let formatted = formatter.format(amount)
  if (formatted.endsWith('00')) {
    formatted = formatted.slice(0, -3)
  }
  return formatted
}
