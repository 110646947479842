import {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import uniqid from 'uniqid'
import classNames from 'classnames'
import type { UpdateCalendarBodyDto } from '@grouse-software/types/calendar'
// components
import {
  Button,
  FormattedText,
  Icon,
  Modal,
  Typography,
} from 'src/modules/core/components'
import { CalendarItem } from 'src/modules/calendar/components'
// hooks
import { useCalendars } from 'src/modules/calendar/hooks/use-calendars'
// constants
import { CalendarsLimitType } from 'src/modules/payment/types/payment.types'
// utils
import { getCalendarsLimitType } from 'src/modules/payment/utils/helpers'
import {
  sortCalendars,
  isCalendarLimitReached,
} from 'src/modules/calendar/utils/helpers'

import type { CalendarSelectionModalProps } from './calendar-selection-modal.interface'
import classes from './calendar-selection-modal.module.scss'

export const CalendarSelectionModal: FC<CalendarSelectionModalProps> = ({
  subscriptionPlan,
  selectFromExistingCalendars,
  isNewSubscriptionPlan,
  onButtonClick,
  title = <FormattedText id="calendars.dashboard.modal.title" />,
  button = <FormattedText id="common.continue" />,
  closeButton,
  onClose,
}) => {
  // hooks
  const calendars = useCalendars()
  // state
  const [updatedCalendars, setUpdatedCalendars] = useState<
    UpdateCalendarBodyDto[]
  >([])
  // memo
  const processedCalendars = useMemo(
    () => sortCalendars(calendars.data),
    [calendars.data],
  )

  const selectedUpdatedCalendars = useMemo(
    () => updatedCalendars.filter((item) => item.selected),
    [updatedCalendars],
  )

  const calendarsLimitType = getCalendarsLimitType(subscriptionPlan)

  /* There are 2 cases when the button is disabled
    1. If calendars are not selected;
    2. If selectFromExistingCalendars is true, the limit allows not only the primary calendar, and the user selected more calendars than their plan allows.
  */
  const isButtonDisabled =
    !selectedUpdatedCalendars.length ||
    (selectFromExistingCalendars &&
      calendarsLimitType !== CalendarsLimitType.primary &&
      selectedUpdatedCalendars.length > subscriptionPlan.calendars_limit)

  useEffect(() => {
    if (updatedCalendars.length || !processedCalendars.length) {
      return
    }

    if (selectFromExistingCalendars) {
      // filter only selected calendars
      const updatedCalendarsData = processedCalendars.reduce(
        (acc, calendar) => {
          if (calendar.selected) {
            // unselect other calendars except the primary one if the current plan only allows the primary calendar
            const selected =
              calendarsLimitType === CalendarsLimitType.primary &&
              !calendar.primary
                ? false
                : calendar.selected

            acc.push({ id: calendar.id, selected })
          }

          return acc
        },
        [],
      )

      setUpdatedCalendars(updatedCalendarsData)

      return
    }

    // select only the primary calendar
    if (calendarsLimitType === CalendarsLimitType.primary) {
      setUpdatedCalendars([{ id: processedCalendars[0].id, selected: true }])
    }
  }, [
    calendarsLimitType,
    processedCalendars,
    selectFromExistingCalendars,
    updatedCalendars,
  ])

  const handleCheckboxChange = useCallback(
    (id: string) => (e: ChangeEvent<HTMLInputElement>) => {
      const selected = e.target.checked

      setUpdatedCalendars((prev) => {
        const itemIndex = prev.findIndex((item) => item.id === id)

        if (itemIndex !== -1) {
          return prev.map((prevItem) =>
            prevItem.id === id ? { ...prevItem, selected } : prevItem,
          )
        }

        return [...prev, { id, selected }]
      })
    },
    [],
  )

  const onClick = () => {
    onButtonClick(
      selectFromExistingCalendars ? updatedCalendars : selectedUpdatedCalendars,
    )
  }

  return (
    <Modal contentClassName={classes.modal} onClose={onClose}>
      <div className={classes.content}>
        <Icon name="error" className={classes.errorIcon} />
        <Typography variant="h2" className={classes.title}>
          {title}
        </Typography>
        <Typography className={classes.subtitle} variant="body">
          <FormattedText id="calendars.title" />
        </Typography>
        <Typography className={classes.description} variant="body">
          <FormattedText
            id="calendars.description"
            values={{
              strong: (chunks) => <strong>{chunks}</strong>,
              limitType: calendarsLimitType,
              limit: subscriptionPlan.calendars_limit,
              isNew: isNewSubscriptionPlan,
            }}
          />
        </Typography>
        <ul className={classes.calendarList}>
          {processedCalendars.map((calendar) => {
            const isSelected = selectedUpdatedCalendars.some(
              (item) => item.id === calendar.id,
            )
            const disabled =
              isCalendarLimitReached(
                subscriptionPlan,
                calendar,
                selectedUpdatedCalendars,
              ) && !isSelected

            return (
              <li key={uniqid()} className={classes.calendarItem}>
                <CalendarItem
                  calendar={calendar}
                  selected={isSelected}
                  disabled={disabled}
                  onCheckboxChange={handleCheckboxChange}
                />
              </li>
            )
          })}
        </ul>
        <div className={classes.line} />
      </div>
      <div className={classes.buttons}>
        {closeButton && onClose && (
          <Button
            className={classes.fullWidthButton}
            onClick={onClose}
            variant="withBorder"
          >
            {closeButton}
          </Button>
        )}
        <Button
          className={classNames(classes.button, {
            [classes.fullWidthButton]: closeButton,
          })}
          disabled={isButtonDisabled}
          onClick={onClick}
        >
          {button}
        </Button>
      </div>
    </Modal>
  )
}
