import classNames from 'classnames';
import { addDays, format } from 'date-fns';
import {
    Dispatch, FC, SetStateAction, useCallback, useMemo, useState
} from 'react';
import {
    ErrorModal, FormattedText, Icon, Loader, Typography
} from 'src/modules/core/components';
import { EventCard } from 'src/modules/event/components';
import { groupEventsByDate } from 'src/modules/event/utils/helpers';
import { useProfile } from 'src/modules/user/hooks/use-profile';
import classes from './event-list.module.scss';

import type { EventData, EventPreview, ExtendEventPreview } from 'src/modules/event/types/event.types'
import type { ErrorMessage } from 'src/modules/core/types/error.types'

export type EventListProps = {
  events: EventPreview[]
  isLoading?: boolean
  eventCardMode?: 'normal' | 'testReminder'
  onCardClick?: (eventPreview: ExtendEventPreview) => void
  onSwitchChange?: (event: EventData) => void
  changeSelectedDate?: Dispatch<SetStateAction<Date>>
}

export const numberPerPage = 5

export const EventList: FC<EventListProps> = ({
  events,
  isLoading,
  eventCardMode = 'normal',
  onCardClick,
  changeSelectedDate,
}) => {
  const [selectedDate, setSelectedDate] = useState<Date>(new Date())
  const [errorMessage, setErrorMessage] = useState<ErrorMessage>(null)
  const [loadingEventId] = useState<string>(null)

  const profile = useProfile()

  const filteredEvents = useMemo(() => {
    return groupEventsByDate(events, addDays(selectedDate, 0), addDays(selectedDate, numberPerPage))
  }, [events, selectedDate])

  const onDateChange = useCallback(
    (number: number) => () => {
      setSelectedDate((prevState) => addDays(prevState, number))
      changeSelectedDate && changeSelectedDate((prevState) => addDays(prevState, number))
    },
    [changeSelectedDate],
  )

  const handleCardClick = useCallback(
    (eventPreview: ExtendEventPreview) => () => {
      onCardClick && onCardClick(eventPreview)
    },
    [onCardClick],
  )

  return (
    <div className={classes.root}>
      <div className={classes.heading}>
        <div className={classes.leftButtons}>
          <div className={classes.arrowWrapper} onClick={onDateChange(-numberPerPage)}>
            <Icon name="arrowLeftRounded" className={classes.arrow} />
          </div>
        </div>
        <Typography variant="h2" className={classes.month}>
          {format(selectedDate, 'MMMM uuuu')}
        </Typography>
        <div className={classes.arrowWrapper} onClick={onDateChange(numberPerPage)}>
          <Icon name="arrowLeftRounded" className={classNames(classes.arrow, classes.arrowRight)} />
        </div>
      </div>
      <div className={classes.remindersList}>
        <Loader className={classes.loader} isLoading={isLoading} />
        {!isLoading &&
          filteredEvents.map((dayEvents) => (
            <div key={dayEvents.date} className={classes.remindersListItem}>
              <Typography className={classes.dayTitle} variant="body">
                {dayEvents.date}
              </Typography>
              <div>
                {dayEvents.events.map((event) => (
                  <div
                    key={`${event.id}-${event.event_date}`}
                    className={classes.reminder}
                    onClick={handleCardClick(event)}
                  >
                    <EventCard
                      showBadge={eventCardMode === 'normal'}
                      showSwitch={eventCardMode === 'normal'}
                      showStatus={eventCardMode === 'normal'}
                      showPhoneInputs={eventCardMode === 'normal'}
                      variant={'current'}
                      event={event}
                      isLoading={loadingEventId === event.id}
                    />
                  </div>
                ))}
                {dayEvents.events.length === 0 && (
                  <Typography variant="body" className={classes.textEmpty}>
                    <FormattedText id="reminder.text.empty" />
                  </Typography>
                )}
              </div>
            </div>
          ))}
      </div>
      {errorMessage && (
        <ErrorModal
          title={
            <Typography variant="body" className={classes.errorTitle}>
              {errorMessage.title}
            </Typography>
          }
          description={
            <Typography variant="span" className={classes.errorDescription}>
              {errorMessage.description}
            </Typography>
          }
          onClose={() => setErrorMessage(null)}
        />
      )}
    </div>
  )
}
