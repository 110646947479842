import { FC, useCallback, useState } from 'react'
import { useLocation } from 'react-router-dom'
// context
import { LoadingWrapperContext } from 'src/modules/core/context'
// constants
import { routeNames } from 'src/modules/core/constants/routeNames'

export const LoadingWrapperProvider: FC = ({ children }) => {
  // state
  const location = useLocation()
  const [isLoading, setIsLoading] = useState<boolean>(
    location.pathname !== routeNames.login.path,
  )

  const [loadingMessage, setLoadingMessage] = useState<string>('')

  const toggleLoading = useCallback(() => setIsLoading((prev) => !prev), [])

  const enableLoading = useCallback(() => setIsLoading(true), [])

  const disableLoading = useCallback(() => {
    setIsLoading(false)
    setLoadingMessage('')
  }, [])

  return (
    <LoadingWrapperContext.Provider
      value={{
        isLoading,
        loadingMessage,
        setLoadingMessage,
        toggleLoading,
        enableLoading,
        disableLoading,
      }}
    >
      {children}
    </LoadingWrapperContext.Provider>
  )
}
