// types
import type {
  AppointmentConfirmationStep,
  AppointmentConfirmationFormValues,
} from 'src/modules/confirmation/types/confirmation.types'

import step1Image from 'src/assets/images/confirmation-step1.png'
import step2Image from 'src/assets/images/confirmation-step2.png'
import step3Image from 'src/assets/images/confirmation-step3.png'

export const appointmentConfirmationSteps: AppointmentConfirmationStep[] = [
  {
    image: step1Image,
    text: 'confirmation.step1',
    width: 261,
    height: 66,
  },
  {
    image: step2Image,
    text: 'confirmation.step2',
    width: 277,
    height: 199,
  },
  {
    image: step3Image,
    text: 'confirmation.step3',
    width: 254,
    height: 113,
  },
]

export const appointmentConfirmationFormValues: Record<
  AppointmentConfirmationFormValues,
  AppointmentConfirmationFormValues
> = {
  yes: 'yes',
  no: 'no',
}
