import { createContext } from 'react'

import type { AuthContextValue } from './auth-context.interface'

export const AuthContext = createContext<AuthContextValue>({
  isAuthenticated: true,
  authenticateUser: () => {},
  logoutUser: () => {},

  isReauthorizingForOfflineSync: false,
  setIsReauthorizingForOfflineSync: () => {},
})
