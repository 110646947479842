import { createQueryParams, makeRequest } from 'src/helpers/request'

import type { IDataOptions } from '@grouse-software/types/common'
import type { BulkUpdateCalendarsBodyDto, CalendarDto, UpdateCalendarBodyDto } from '@grouse-software/types/calendar'

export const CalendarsService = {
  synchronizeCalendars: async (): Promise<void> => {
    const timeMin = new Date()
    timeMin.setDate(timeMin.getDate() - 90) // 90 days ago
    const formattedTimeMin = timeMin.toISOString().split('T')[0] // only date
    return makeRequest(
      `${process.env.REACT_APP_API_URL}/calendars/syncronize-calendars?timeMin=${formattedTimeMin}`,
      'post',
    )
  },

  getCalendars: async (): Promise<CalendarDto[]> => {
    const response = await makeRequest(`${process.env.REACT_APP_API_URL}/calendars`, 'get')
    return response.data
  },

  getCalendarById: (id: string, params: IDataOptions): Promise<CalendarDto> => {
    const queryParams = createQueryParams(params)
    return makeRequest(`${process.env.REACT_APP_API_URL}/calendars/${id}${queryParams}`, 'get')
  },

  bulkUpdateCalendars: async (params: UpdateCalendarBodyDto[]): Promise<CalendarDto[]> => {
    const response = await makeRequest<BulkUpdateCalendarsBodyDto>(
      `${process.env.REACT_APP_API_URL}/calendars/bulk`,
      'patch',
      { data: params },
    )
    return response.data
  },

  updateCalendar: async (data: UpdateCalendarBodyDto): Promise<CalendarDto[]> => {
    const response = await makeRequest<BulkUpdateCalendarsBodyDto>(
      `${process.env.REACT_APP_API_URL}/calendars/bulk`,
      'patch',
      { data: [data] },
    )
    return response.data
  },

  syncCalendars: async (): Promise<void> => {
    return makeRequest(`${process.env.REACT_APP_API_URL}/calendars/sync`, 'post')
  },
}
