import React, { FC } from 'react'
import { IntlProvider } from 'react-intl'
// messages
import EnglishMessages from 'src/lang/en.json'

export const I18nProvider: FC = ({ children }) => (
  <IntlProvider locale="en" defaultLocale="en" messages={EnglishMessages}>
    {children}
  </IntlProvider>
)
