import { Button, Icon } from 'src/modules/core/components'
import { Box, Card, CardContent, Typography } from '@material-ui/core'
import classes from './choose-mode-card.module.scss'

export interface ChooseModeCardProps {
  image: React.ReactNode
  title: React.ReactNode
  description: React.ReactNode
  howItWorks: React.ReactNode
  buttonText: React.ReactNode
  onClick: () => void
  isLoading: boolean
}

export default function ChooseModeCard({
  image,
  title,
  description,
  howItWorks,
  buttonText,
  onClick,
  isLoading = false,
}: ChooseModeCardProps) {
  return (
    <Card variant="outlined">
      <CardContent>
        <Box display="flex" flexDirection="column" alignItems="center" className={classes.cardContent}>
          <Box marginBottom={1}>{image}</Box>
          <Typography component="h3" variant="h5" gutterBottom>
            {title}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {description}
          </Typography>
          <Box marginY={3} display="flex" alignItems="center">
            <Icon name="successCheckmark" className={classes.checkIcon} />
            <Typography variant="body1" gutterBottom align="left">
              {howItWorks}
            </Typography>
          </Box>
        </Box>
        <Button
          className="notranslate"
          type="submit"
          onClick={() => onClick?.()}
          widthType="full"
          isLoading={isLoading}
        >
          <span className="translate">{buttonText}</span>
        </Button>
      </CardContent>
    </Card>
  )
}
