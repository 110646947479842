import { useContext, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { FormattedText } from 'src/modules/core/components'
import { LoadingWrapperContext } from 'src/modules/core/context'
import { SubscriptionScreen } from 'src/modules/user/components'

export const SubscriptionPlans = () => {
  const { disableLoading } = useContext(LoadingWrapperContext)
  const [searchParams] = useSearchParams()

  const isPlanLimitReached = searchParams.get('type') === 'limit'

  useEffect(() => {
    disableLoading()
  }, [disableLoading])

  return (
    <SubscriptionScreen
      type={isPlanLimitReached ? 'upgrade' : undefined}
      title={
        isPlanLimitReached ? (
          <FormattedText id="configuration.subscription.limitReached.title" />
        ) : (
          <FormattedText id="configuration.subscription.title.plan" />
        )
      }
      description={
        isPlanLimitReached ? (
          <FormattedText id="configuration.subscription.limitReached.description" />
        ) : (
          <FormattedText id="configuration.subscription.description" />
        )
      }
    />
  )
}
