import { CalendarsTab } from 'src/modules/calendar/containers';
// containers
import { BillingTab, SubscriptionPlanTab } from 'src/modules/payment/containers';
import { SettingsMyDetailsTab } from 'src/modules/user/containers';

// types
import type { SettingsTab } from 'src/modules/user/types/settings.types'

export const settingsTabs: SettingsTab[] = [
  {
    title: 'Template',
    component: SettingsMyDetailsTab,
  },
  {
    title: 'Plan',
    component: SubscriptionPlanTab,
  },
  {
    title: 'Billing',
    component: BillingTab,
  },
  {
    title: 'Calendars',
    component: CalendarsTab,
  },
]
