import { maxMessageLength, maxMessageLengthWithConfirmation } from 'src/modules/event/constants/event.constants'
import * as yup from 'yup'

export const maxLength = 160
export const isOnlySpaces = /\S/

export const configurationSchema = yup
  .object()
  .shape({
    from: yup
      .string()
      .transform((value) => value?.trim() ?? '')
      .max(50)
      .required(),
    meetingLocation: yup
      .string()
      .transform((value) => (value?.trim() === '' ? null : value.trim()))
      .nullable()
      .max(maxLength),
    defaultReminder: yup
      .string()
      .transform((value) => value?.trim() ?? '')
      .max(maxMessageLength, 'Default SMS Reminder message is too long')
      .required(),
    timing: yup.array().min(1, 'Select at least 1 timing').required(),
  })
  .required()

export const configurationFieldNames = {
  from: 'From',
  phoneNumers: 'Phone Numbers',
  meetingLocation: 'Meeting Location',
  defaultReminder: 'Default SMS Reminder',
  timing: 'Timing',
}
