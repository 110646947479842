import { format } from 'date-fns'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
// components
import { Button, FormattedText, InfoModal, Loader, Typography } from 'src/modules/core/components'
// constants
import { routeNames } from 'src/modules/core/constants/routeNames'
// context
import { LoadingWrapperContext } from 'src/modules/core/context'
import { usePlanData } from 'src/modules/payment/hooks/use-plan-data'
// hooks
import { useSubscription } from 'src/modules/payment/hooks/use-subscription'
// types
import { SubscriptionStatus } from 'src/modules/payment/types/payment.types'
import { ProfileInfo } from 'src/modules/user/components'
import { useProfile } from 'src/modules/user/hooks/use-profile'
import classes from './paused-subscription.module.scss'

export const PausedSubscription = () => {
  // state
  const [isSuccess, setIsSuccess] = useState(false)
  // hooks
  const navigate = useNavigate()
  const profile = useProfile()
  const subscriptionOptions = {
    enabled: !!profile.data?.billing_subscription_id,
  }
  const subscription = useSubscription({}, subscriptionOptions)
  const { currentPlan, loading } = usePlanData()
  // contexts
  const { disableLoading } = useContext(LoadingWrapperContext)
  // memo
  const isLoading = useMemo(
    () => subscription.isLoading || subscription.isRefetching || loading,
    [subscription.isLoading, subscription.isRefetching, loading],
  )

  const pausedDate = useMemo(
    () => subscription.data?.paused_at && new Date(subscription.data?.paused_at),
    [subscription.data?.paused_at],
  )

  useEffect(() => {
    disableLoading()
  }, [disableLoading])

  useEffect(() => {
    if (subscription.data?.state !== SubscriptionStatus.paused && !subscription.data?.paused_at && !isSuccess) {
      navigate(routeNames.dashboard.path, { replace: true })
    }
  }, [navigate, isSuccess, subscription.data?.paused_at, subscription.data?.state])

  const handleUpdateCard = useCallback(() => {
    window.Paddle.Checkout.open({
      override: subscription.data?.update_url,
      successCallback: () => setIsSuccess(true),
    })
  }, [subscription.data?.update_url])

  const onClose = useCallback(() => setIsSuccess(false), [])

  const onSuccess = useCallback(async () => {
    await subscription.refetch()
    navigate(routeNames.dashboard.path, { replace: true })
  }, [navigate, subscription])

  if (isLoading) return <Loader isLoading={isLoading} />

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <Typography variant="h1" className={classes.title}>
          <FormattedText id="payment.subscription.paused.title" />
        </Typography>
        <Typography variant="body" className={classes.description}>
          <FormattedText
            id="payment.subscription.paused.description"
            values={{
              br: () => <br />,
              strong: (chunks) => <strong>{chunks}</strong>,
              date: pausedDate && format(pausedDate, 'M/d/yyyy'),
              name: currentPlan.name,
              price: currentPlan.price,
            }}
          />
        </Typography>
        <div className={classes.buttonWrapper}>
          <Button onClick={handleUpdateCard}>
            <FormattedText id="payment.subscription.paused.button.update" />
          </Button>
        </div>
      </div>
      <ProfileInfo rootClassName={classes.absoluteWrapper} />
      {isSuccess && (
        <InfoModal
          title={<FormattedText id="payment.subscription.paused.success.title" />}
          description={<FormattedText id="payment.subscription.paused.success.description" />}
          button={<FormattedText id="payment.subscription.success.button" />}
          onClick={onSuccess}
          onClose={onClose}
        />
      )}
    </div>
  )
}
