import { useMutation, useQueryClient } from 'react-query'
import { DEFAULT_GET_PROFILE_CACHE_KEY } from 'src/modules/user/hooks/use-profile'
import { ApiService } from 'src/services/api.service'
import { UpdateUserBodyDto } from '../types/user-api.types'

export function useOptimisticUpdateProfile() {
  const queryClient = useQueryClient()
  return useMutation((data: UpdateUserBodyDto) => ApiService.Profile.updateProfile(data), {
    onMutate: async (newData) => {
      await queryClient.cancelQueries(DEFAULT_GET_PROFILE_CACHE_KEY, {
        exact: true,
      })

      queryClient.setQueryData(DEFAULT_GET_PROFILE_CACHE_KEY, (prev: object) => ({
        ...prev,
        ...newData,
      }))
    },
    onSettled: () => {
      queryClient.invalidateQueries(DEFAULT_GET_PROFILE_CACHE_KEY, {
        exact: true,
      })
    },
  })
}
