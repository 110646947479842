// constants
import { isOnlySpaces, maxLength } from 'src/modules/user/constants/configuration-form'
import * as yup from 'yup'

export const settingsFormSchema = yup.object().shape({
  from: yup
    .string()
    .nullable()
    .max(maxLength, 'Business name must be shorter than 160 characters')
    .matches(isOnlySpaces, {
      excludeEmptyString: true,
      message: 'Business name cannot contain only blank spaces',
    }),
  meetingLocation: yup
    .string()
    .nullable()
    .max(maxLength, 'Meeting location must be shorter than 160 characters')
    .matches(isOnlySpaces, {
      excludeEmptyString: true,
      message: 'Meeting location cannot contain only blank spaces',
    }),
  defaultReminder: yup.string().nullable().matches(isOnlySpaces, {
    excludeEmptyString: true,
    message: 'Default SMS Reminder cannot contain only blank spaces',
  }),
  timing: yup.array().typeError('Timing is a required field').required('Timing is a required field'),
})
