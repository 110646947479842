import classNames from 'classnames'
import { FC, useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
// components
import { Button, FormattedText, Icon, Loader, Message, Typography } from 'src/modules/core/components'
// constants
import { routeNames } from 'src/modules/core/constants/routeNames'
import { useCancelSubscription } from 'src/modules/payment/hooks/use-cancel-subscription'
// hooks
import { useSubscription } from 'src/modules/payment/hooks/use-subscription'
// types
import { SubscriptionStatus } from 'src/modules/payment/types/payment.types'
import { useProfile } from 'src/modules/user/hooks/use-profile'
import { Widget } from '@typeform/embed-react'
import classes from './cancel-plan-wrapper.module.scss'

import type { MessageType } from './cancel-plan-wrapper.interface'
export const CancelPlanWrapper: FC = () => {
  // state
  const [message, setMessage] = useState<MessageType>(null)
  const [isSubmitted, setIsSubmitted] = useState(false)
  // hooks
  const navigate = useNavigate()
  const profile = useProfile()
  const subscriptionOptions = {
    enabled: Boolean(profile.data?.billing_subscription_id),
  }
  const subscription = useSubscription({}, subscriptionOptions)
  const cancelSubscription = useCancelSubscription()

  useEffect(() => {
    if (subscription.data?.state === SubscriptionStatus.deleted && !message) {
      navigate(routeNames.dashboard.path, { replace: true })
    }
  }, [message, navigate, subscription.data?.state])

  const onClose = useCallback(() => {
    navigate(-1)
  }, [navigate])

  const handleCancelPlan = useCallback(() => {
    cancelSubscription.mutate(null, {
      onSuccess: () => {
        setMessage({
          status: 'success',
          text: <FormattedText id="payment.subscription.cancel.success" />,
        })
      },
      onError: () => {
        setMessage({
          status: 'error',
          text: <FormattedText id="payment.subscription.cancel.error" />,
        })
      },
    })
  }, [cancelSubscription])

  const onSubmit = useCallback(() => {
    setIsSubmitted(true)
  }, [])

  return (
    <div className={classes.root}>
      <Loader isLoading={cancelSubscription.isLoading} className={classes.loader} full />
      {message ? (
        <Message variant={message.status} message={message.text} onClose={onClose} />
      ) : (
        <>
          <div className={classes.header}>
            <Typography variant="h1" className={classes.title}>
              <FormattedText id="payment.subscription.cancel.title" />
            </Typography>
            <Icon name="close" className={classes.close} onClick={onClose} />
          </div>
          <Typography variant="body">
            <FormattedText id="payment.subscription.cancel.description" />
          </Typography>
          <Widget
            id={process.env.REACT_APP_TYPEFORM_FORM_ID}
            className={classNames('my-form', classes.form)}
            onSubmit={onSubmit}
          />
          <div className={classes.buttonWrapper}>
            <Button variant="danger" className={classes.button} onClick={handleCancelPlan} disabled={!isSubmitted}>
              <FormattedText id="payment.subscription.cancel.button" />
            </Button>
          </div>
        </>
      )}
    </div>
  )
}
