import { makeRequest } from 'src/helpers/request'
import { UserEvent } from 'src/modules/user-events/user-event.interface'
import { UserEventType } from 'src/modules/user-events/user-events.constants'

export const UserEventsService = {
  addUserEvent: (event: UserEventType, once?: boolean): Promise<void> => {
    return makeRequest(`${process.env.REACT_APP_API_URL}/user-events/${event}`, 'post', { once })
  },

  hasUserEvent: (event: UserEventType): Promise<boolean> => {
    return makeRequest(`${process.env.REACT_APP_API_URL}/user-events/has/${event}`, 'get')
  },

  listUserEvents: (filter?: UserEventType[]): Promise<UserEvent[]> => {
    if (!Array.isArray(filter)) {
      filter = [filter].filter(Boolean)
    }
    const param = filter.length ? `filter=${filter.join(',')}` : ''
    return makeRequest(`${process.env.REACT_APP_API_URL}/user-events?${param}`, 'get')
  },
}
