import classNames from 'classnames'
import { ChangeEvent, useMemo, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useFlag } from 'react-tggl-client'
import { createYupResolver } from 'src/helpers/custom-yup-resolver'
import generateRandomId from 'src/helpers/generate-random-id'
import {
  Button,
  FormattedText,
  FormControl,
  Icon,
  PhoneNumberField,
  SecondaryHeader,
  SelectField,
  TextField,
  ToggleSwitch,
  Typography,
} from 'src/modules/core/components'
import { maxMessageLength, onReminderMessageInput } from 'src/modules/event/constants/event.constants'
import { generateEstimatedEventMessage } from 'src/modules/event/utils/helpers'
import { ConsumedReminderSegments, CreateReminderHelper } from 'src/modules/reminder/components'
import { configurationFieldNames, configurationSchema } from 'src/modules/user/constants/configuration-form'
import { timingOptions } from 'src/modules/user/constants/profile.constants'
import { useOptimisticUpdateProfile } from 'src/modules/user/hooks/use-optimistic-update-profile'
import { useProfile } from 'src/modules/user/hooks/use-profile'
import * as amplitude from '@amplitude/analytics-browser'
import { Box } from '@material-ui/core'
import { SendTestOrFinishScreen } from '../send-test-or-finish'
import classes from './configuration-form.module.scss'

import type { ProfileFormData } from 'src/modules/user/types/profile.types'

const MAX_PHONE_NUMBERS = 10

export function ConfigurationFormPersonalMode() {
  const { value, loading } = useFlag('calendarEventXNewOnboarding')
  if (loading) return null

  const useNewOnboarding = !!value?.includes('test')
  const blankFields = !value?.includes('populated')

  return <ConfigurationFormInternal newWebAppOnboarding={useNewOnboarding} blankFields={blankFields} />
}

type ConfigurationFormInternalProps = {
  newWebAppOnboarding: boolean
  blankFields: boolean
}

function ConfigurationFormInternal({ newWebAppOnboarding, blankFields }: ConfigurationFormInternalProps) {
  const profile = useProfile()
  const optimisticUpdateProfile = useOptimisticUpdateProfile()

  const { formatMessage } = useIntl()

  const [sendTestReminder, setSendTestReminder] = useState(false)
  const [showSendTestOrFinish, setShowSendTestOrFinish] = useState(false)
  const [noPhoneNumbersError, setNoPhoneNumbersError] = useState<string>(null)

  const defaultCountryCode = localStorage.getItem('defaultCountryCode') || 'us'
  const existingPhoneNumbers = (profile.data.phone_numbers?.map((n) => n?.toString() || '') ?? []).filter(Boolean)
  if (existingPhoneNumbers.length === 0) {
    existingPhoneNumbers.push('')
  }

  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors, isSubmitted },
  } = useForm<ProfileFormData>({
    resolver: createYupResolver(configurationSchema, configurationFieldNames),
    defaultValues: {
      from: blankFields
        ? ''
        : formatMessage({
            id: 'configuration.step1.personalMode.from.default',
          }),
      phoneNumbers: existingPhoneNumbers.map((phone) => ({
        id: generateRandomId(),
        countryCode: null,
        phoneNumber: phone,
      })),
      meetingLocation: blankFields
        ? ''
        : formatMessage({
            id: 'configuration.step1.personalMode.location.default',
          }),
      defaultReminder: blankFields
        ? ''
        : formatMessage({
            id: 'configuration.step1.personalMode.reminder.default',
          }),
      timing: [],
      personalRemindersForAllAppointments: true,
    },
  })

  const {
    fields: phoneNumbers,
    append: addPhoneNumber,
    remove: removePhoneNumber,
  } = useFieldArray({
    control,
    name: 'phoneNumbers',
  })

  function handleAddPhoneNumber() {
    if (phoneNumbers.length < MAX_PHONE_NUMBERS) {
      addPhoneNumber({
        id: generateRandomId(),
        countryCode: defaultCountryCode,
        phoneNumber: '',
      })
    }
  }

  const { from, meetingLocation, defaultReminder, personalRemindersForAllAppointments } = watch()

  const eventMessage = useMemo(
    () =>
      generateEstimatedEventMessage({
        ...profile.data,
        sender_name: from,
        default_location: meetingLocation,
        default_message_text: defaultReminder,
        appointment_confirmation: false,
      }),
    [defaultReminder, from, meetingLocation, profile.data],
  )

  function handleSendTestReminderClick() {
    amplitude.track('Onboarding_SendTestReminder_button')
    setSendTestReminder(true)
  }

  function handleSkipAndFinishClick() {
    amplitude.track('Onboarding_SkipandFinish_button')
    setSendTestReminder(false)
  }

  const onSubmit = (data: ProfileFormData) => {
    // Ensure unique phone numbers and save last country code
    const seenNumbers = new Set()
    const phoneNumbers = []
    for (const receiver of data.phoneNumbers) {
      const phoneNumber = `+${receiver.phoneNumber.replace(/\D/g, '')}`
      if (!seenNumbers.has(phoneNumber) && phoneNumber.length >= 10) {
        seenNumbers.add(phoneNumber)
        phoneNumbers.push(phoneNumber)
        if (receiver.countryCode) localStorage.setItem('defaultCountryCode', receiver.countryCode)
      }
    }

    setNoPhoneNumbersError(null)
    if (!phoneNumbers.length) {
      setNoPhoneNumbersError('Provide at least one valid phone number')
      return
    }

    optimisticUpdateProfile.mutate(
      {
        sender_name: data.from,
        phone_numbers: phoneNumbers,
        default_location: data.meetingLocation,
        default_message_text: data.defaultReminder,
        default_time_before: data.timing.map((time) => +time),
        personal_reminders_for_all_appointments: data.personalRemindersForAllAppointments,
      },
      {
        onSuccess: () => setShowSendTestOrFinish(true),
      },
    )
  }

  return (
    <>
      <Box className="translate">
        <SecondaryHeader
          title={<FormattedText id="configuration.step1.personalMode.title" />}
          description={<FormattedText id="configuration.step1.personalMode.description" />}
          className={classes.text}
        />
        <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
          <div className={classes.wrapper}>
            <TextField
              wrapperClassName={classes.textFieldWrapper}
              label={formatMessage({ id: 'configuration.step1.label.from' })}
              placeholder={formatMessage({
                id: 'configuration.step1.personalMode.from.placeholder',
              })}
              {...register('from')}
              error={errors.from?.message}
            />
            <Box marginBottom={3}>
              {phoneNumbers.map((field, index) => (
                <Box marginBottom={1} key={field.id}>
                  <FormControl
                    labelClassName={classes.label}
                    label={`${formatMessage({
                      id: 'configuration.step1.personalMode.phone.label',
                    })} ${index + 1}`}
                    error={(errors.phoneNumbers && errors.phoneNumbers[index]?.message) || noPhoneNumbersError}
                  >
                    <Controller
                      control={control}
                      name={`phoneNumbers.${index}.phoneNumber`}
                      defaultValue={field.phoneNumber}
                      render={(props) => (
                        <Box display="flex" alignItems="center" gridColumnGap={10}>
                          <PhoneNumberField
                            country={defaultCountryCode}
                            inputClass={classes.phoneNumberInput}
                            value={props.field.value}
                            onChange={props.field.onChange}
                            error={(errors.phoneNumbers && errors.phoneNumbers[index]?.message) || noPhoneNumbersError}
                          />
                          {index > 0 && (
                            <span onClick={() => removePhoneNumber(index)}>
                              <Icon name="close" className={classes.removeButton} />
                            </span>
                          )}
                        </Box>
                      )}
                    />
                  </FormControl>
                </Box>
              ))}
              {phoneNumbers.length < MAX_PHONE_NUMBERS && (
                <Typography variant="body" className={classes.textButton} onClick={handleAddPhoneNumber}>
                  + <FormattedText id="configuration.step1.personalMode.addPhone" />
                </Typography>
              )}
            </Box>
            <TextField
              wrapperClassName={classes.textFieldWrapper}
              label={formatMessage({
                id: 'configuration.step1.personalMode.location.label',
              })}
              placeholder={formatMessage({
                id: 'configuration.step1.placeholder.location',
              })}
              rows={3}
              Element="textarea"
              {...register('meetingLocation')}
              error={errors.meetingLocation?.message}
            />
            <TextField
              label={formatMessage({
                id: 'configuration.step1.personalMode.reminder.label',
              })}
              placeholder={formatMessage({
                id: 'configuration.step1.personalMode.reminder.placeholder',
              })}
              Element="textarea"
              rows={4}
              {...register('defaultReminder')}
              onInput={(event: ChangeEvent<HTMLTextAreaElement>) => onReminderMessageInput(event, maxMessageLength)}
              error={errors.defaultReminder?.message}
              className={classNames({
                [classes.error]: eventMessage.length > maxMessageLength,
              })}
            >
              <ConsumedReminderSegments appointmentConfirmation={false} message={eventMessage} isEstimate />
            </TextField>
            <div className={classNames(classes.textWrapper, classes.textFieldWrapper)}>
              <CreateReminderHelper />
              {!!(eventMessage && eventMessage.length) && (
                <Typography
                  className={classNames(classes.sublabel, {
                    [classes.error]: eventMessage.length > maxMessageLength,
                    [classes.larger]: eventMessage.length > maxMessageLength,
                  })}
                  variant="h5"
                >
                  <FormattedText
                    id="configuration.step1.sublabel"
                    values={{
                      current: eventMessage.length,
                      max: maxMessageLength,
                      strong: (chunks) => <strong>{chunks}</strong>,
                    }}
                  />
                </Typography>
              )}
            </div>
            <FormControl
              label={formatMessage({
                id: 'configuration.step1.personalMode.timing.label',
              })}
              error={errors.timing?.message}
            >
              <Controller
                control={control}
                defaultValue={[]}
                name="timing"
                render={({ field: { onChange, value, ref } }) => (
                  <SelectField
                    ref={ref}
                    value={timingOptions.filter((c) => value.includes(c.value))}
                    onChange={(val) => onChange(val.map((c) => c.value))}
                    options={timingOptions}
                    isMulti
                    placeholder={<FormattedText id="configuration.step1.personalMode.timing.placeholder" />}
                  />
                )}
              />
            </FormControl>

            <Box>
              {eventMessage && (
                <>
                  <Typography variant="body" className={classes.bodyText}>
                    <FormattedText id="configuration.step1.personalMode.reminderExample.title" />
                  </Typography>

                  <Box className={classes.messageContainer}>
                    <Typography variant="body" className={classes.message}>
                      {eventMessage}
                    </Typography>
                  </Box>
                </>
              )}

              <Box display="flex" alignItems="center" marginTop={5}>
                <Box marginRight={2}>
                  <ToggleSwitch
                    checked={personalRemindersForAllAppointments}
                    labelClassName={classes.switchLabel}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setValue('personalRemindersForAllAppointments', e.target.checked)
                    }
                  />
                </Box>
                <Typography variant="body">
                  <FormattedText id="configuration.step1.personalMode.autoReminders.title" />
                </Typography>
              </Box>
            </Box>
          </div>

          {isSubmitted && (Object.keys(errors).length > 0 || noPhoneNumbersError) && (
            <Box marginTop={5} className={classes.error}>
              Review the errors above to proceed
            </Box>
          )}

          {newWebAppOnboarding ? (
            <Box display="flex" gridGap={20} className="notranslate">
              <Button
                type="submit"
                className={classes.button}
                widthType="content"
                isLoading={optimisticUpdateProfile.isLoading}
                onClick={handleSendTestReminderClick}
              >
                <span className="translate">
                  <FormattedText id="Send a Test Reminder" />
                </span>
              </Button>
              <Button
                type="submit"
                widthType="content"
                isLoading={optimisticUpdateProfile.isLoading}
                onClick={handleSkipAndFinishClick}
                variant="secondary"
              >
                <span className="translate">
                  <FormattedText id="Skip & Finish My Account" />
                </span>
              </Button>
            </Box>
          ) : (
            <Button
              type="submit"
              className={classNames(classes.button)}
              widthType="content"
              isLoading={optimisticUpdateProfile.isLoading}
            >
              <FormattedText id="common.saveAndContinue" />
            </Button>
          )}
        </form>
      </Box>
      <SendTestOrFinishScreen active={showSendTestOrFinish} sendTestReminder={sendTestReminder} />
    </>
  )
}
