import type {
  BillingType,
  BillingTypes,
} from 'src/modules/payment/types/payment.types'

export const SHORT_BILLING_INTERVAL: boolean = JSON.parse(
  process.env.REACT_APP_SHORT_BILLING_INTERVAL || 'false',
)

export const billingTypes: BillingTypes = SHORT_BILLING_INTERVAL
  ? { shortInterval: 'day', longInterval: 'week' }
  : { shortInterval: 'month', longInterval: 'year' }

export const billingTypeSet: Record<BillingType, string> = {
  month: 'monthly',
  year: 'annually',
  day: 'daily',
  week: 'weekly',
}
