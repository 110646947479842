import { useMutation, useQueryClient } from 'react-query'
import { useHandleRetry } from 'src/modules/core/hooks/use-handle-retry'
import { DEFAULT_GET_PROFILE_CACHE_KEY } from 'src/modules/user/hooks/use-profile'
import { ApiService } from 'src/services/api.service'

export const SYNC_PROFILE_CACHE_KEY = 'sync-profile'

export function useSyncProfile() {
  const queryClient = useQueryClient()
  const handleRetry = useHandleRetry({
    numberOfRetries: -1,
  })

  return useMutation(SYNC_PROFILE_CACHE_KEY, () => ApiService.Profile.syncProfile(), {
    retry: handleRetry,
    onSuccess: () => {
      queryClient.invalidateQueries(DEFAULT_GET_PROFILE_CACHE_KEY, {
        exact: true,
      })
    },
  })
}
