import { FC } from 'react'
import classNames from 'classnames'
// components
import { Typography } from 'src/modules/core/components'

import type { SecondaryHeaderProps } from './secondary-header.interface'
import classes from './secondary-header.module.scss'

export const SecondaryHeader: FC<SecondaryHeaderProps> = ({
  title,
  description,
  className,
}) => {
  return (
    <div className={classNames(classes.root, className)}>
      {title && (
        <Typography className={classes.title} variant="h1">
          {title}
        </Typography>
      )}
      {description && (
        <Typography className={classes.description} variant="body">
          {description}
        </Typography>
      )}
    </div>
  )
}
