import 'reflect-metadata'
import 'src/styles/index.scss'
import ReactDOM from 'react-dom'
import App from 'src/app'
import { datadogLogs } from '@datadog/browser-logs'

// Show app deployment metadata
console.log(`App version: ${process.env.HEROKU_SLUG_COMMIT}, deployed on ${process.env.HEROKU_RELEASE_CREATED_AT}`)

if (process.env.REACT_APP_DD_ENABLED === 'true') {
  datadogLogs.init({
    service: 'frontend',
    env: process.env.REACT_APP_ENVIRONMENT || 'development',
    clientToken: process.env.REACT_APP_DD_CLIENT_TOKEN,
    site: process.env.REACT_APP_DD_SITE,
    forwardConsoleLogs: [/*'debug', 'log', 'info',*/ 'warn', 'error'],
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
  })
}

ReactDOM.render(<App />, document.getElementById('root'))

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
