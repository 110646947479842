import { RefObject, useCallback, useEffect } from 'react'

export const useOutsideClick = (
  refs: RefObject<any>[],
  callbackFunc: () => void,
) => {
  const handleClickOutside = useCallback(
    (event) => {
      let outsideClick = true

      refs.forEach((ref) => {
        if (!(ref.current && !ref.current.contains(event.target))) {
          outsideClick = false
        }
      })

      if (outsideClick) {
        callbackFunc()
      }
    },
    [callbackFunc, refs],
  )

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [refs, handleClickOutside])
}
