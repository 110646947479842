import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useBulkUpdateCalendars } from 'src/modules/calendar/hooks/use-bulk-update-calendars'
// components
import { FormattedText, InfoModal, Loader, Typography } from 'src/modules/core/components'
// constants
import { routeNames } from 'src/modules/core/constants/routeNames'
// context
import { LoadingWrapperContext } from 'src/modules/core/context'
import { SubscriptionSelector } from 'src/modules/payment/components'
import { billingTypes, billingTypeSet } from 'src/modules/payment/constants/payment.constants'
import { useSubscriptionPlans } from 'src/modules/payment/hooks/use-subscription-plans'
// hooks
import { useProfile } from 'src/modules/user/hooks/use-profile'
import classes from './beta-users-subscription.module.scss'

import type { BillingPlanDto } from '@grouse-software/types/billing/billing.dto'
export const BetaUsersSubscription = () => {
  // state
  const [selectedPlan, setSelectedPlan] = useState<BillingPlanDto>(null)
  const [isTimeoutLoading, setIsTimeoutLoading] = useState(false)
  // hooks
  const navigate = useNavigate()
  const profile = useProfile()
  const subscriptionPlans = useSubscriptionPlans()
  const bulkUpdateCalendars = useBulkUpdateCalendars()
  // contexts
  const { disableLoading } = useContext(LoadingWrapperContext)
  // memo
  const isLoading = useMemo(
    () => profile.isLoading || subscriptionPlans.isLoading || isTimeoutLoading,
    [isTimeoutLoading, profile.isLoading, subscriptionPlans.isLoading],
  )

  useEffect(() => {
    disableLoading()
  }, [disableLoading])

  useEffect(() => {
    if (!profile.data?.has_benefits || !!profile.data?.billing_subscription_id) {
      navigate(routeNames.dashboard.path, { replace: true })
    }
  }, [navigate, profile.data?.has_benefits, profile.data?.billing_subscription_id])

  const onSelectPlan = useCallback(
    (plan: BillingPlanDto, selectedCalendarIds: string[]) => () => {
      window.Paddle.Checkout.open({
        product: plan.paddle_id,
        email: profile.data?.email,
        passthrough: JSON.stringify({ user_id: profile.data?.id }),
        successCallback: () => {
          setIsTimeoutLoading(true)
          const timeout = 2000

          setTimeout(() => {
            bulkUpdateCalendars.mutate(
              selectedCalendarIds.map((id) => ({ id, selected: true })),
              {
                onSuccess: () => {
                  setSelectedPlan(plan)
                  setIsTimeoutLoading(false)
                },
              },
            )
          }, timeout)
        },
      })
    },
    [bulkUpdateCalendars, profile.data?.email, profile.data?.id],
  )

  const onSuccess = useCallback(() => {
    window.location.reload()
  }, [])

  if (isLoading) return <Loader isLoading={isLoading} />

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <Typography variant="h1" className={classes.title}>
          <FormattedText id="payment.subscription.beta.title" />
        </Typography>
        <Typography variant="body" className={classes.description}>
          <FormattedText
            id="payment.subscription.beta.description"
            values={{
              br: () => <br />,
              strong: (chunks) => <strong>{chunks}</strong>,
              sup: (chunks) => <sup>{chunks}</sup>,
            }}
          />
        </Typography>
      </div>
      <SubscriptionSelector
        onSelectPlan={onSelectPlan}
        subscriptionOptions={{ enabled: false }}
        buttonClassName={classes.button}
        description={<FormattedText id="configuration.subscription.description.beta" />}
      />
      {selectedPlan && (
        <InfoModal
          title={<FormattedText id="payment.subscription.success.title" />}
          description={
            <FormattedText
              id="payment.subscription.success.description"
              values={{
                strong: (chunks) => <strong>{chunks}</strong>,
                plan: selectedPlan.name,
                billingType:
                  billingTypeSet[
                    selectedPlan.billing_type === billingTypes.longInterval
                      ? billingTypes.longInterval
                      : billingTypes.shortInterval
                  ],
              }}
            />
          }
          button={<FormattedText id="payment.subscription.success.button" />}
          onClick={onSuccess}
          onClose={() => setSelectedPlan(null)}
        />
      )}
    </div>
  )
}
