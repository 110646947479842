import { addDays } from 'date-fns'
import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSyncCalendars } from 'src/modules/calendar/hooks/use-sync-calendars'
// components
import {
  Button,
  Footer,
  FormattedText,
  Icon,
  Loader,
  PageWrapper,
  Pagination,
  SecondaryHeader,
  Typography,
} from 'src/modules/core/components'
// constants
import { routeNames } from 'src/modules/core/constants/routeNames'
// contexts
import { ConfigurationStepContext, PaginationContext } from 'src/modules/core/context'
// utils
import { changeArrayElementValues } from 'src/modules/core/utils/helpers'
import { EditEventModal, UpcomingMeetingsList } from 'src/modules/event/components'
import { eventDetailsParams, eventPreviewFields } from 'src/modules/event/constants/event-params'
import { EventsContext } from 'src/modules/event/context'
import { useEventById } from 'src/modules/event/hooks/use-event-by-id'
// hooks
import { useEventList } from 'src/modules/event/hooks/use-event-list'
import { useSyncEvents } from 'src/modules/event/hooks/use-sync-events'
import { useUpdateEvents } from 'src/modules/event/hooks/use-update-events'
import { ProfileInfo } from 'src/modules/user/components/profile-info'
import { useOptimisticUpdateProfile } from 'src/modules/user/hooks/use-optimistic-update-profile'
import { useProfile } from 'src/modules/user/hooks/use-profile'
import { useSyncProfile } from 'src/modules/user/hooks/use-sync-profile'
import classes from './setup-reminder-screen.module.scss'

// types
import type {
  EventData,
  EventDetails,
  EventPreview,
  ExtendEventPreview,
  UpdateExtendEventBodyDto,
} from 'src/modules/event/types/event.types'

type EventInfo = ExtendEventPreview & Partial<EventDetails>

const numberOfPages = 6
const numberOfDays = 5
const today = new Date()

export const SetupReminderScreen: FC = () => {
  // context
  const { currentPage, setNumberOfPages } = useContext(PaginationContext)
  const { handleNext } = useContext(ConfigurationStepContext)
  const { setActiveEvents } = useContext(EventsContext)
  const updateProfile = useOptimisticUpdateProfile()
  // hooks
  const syncCalendars = useSyncCalendars()
  const syncEvents = useSyncEvents()
  const syncProfile = useSyncProfile()

  useEffect(() => {
    syncCalendars.mutate()
    syncProfile.mutate()
    syncEvents.mutate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const navigate = useNavigate()
  const updateEvents = useUpdateEvents()
  const eventList = useEventList<EventPreview>(
    {
      fields: eventPreviewFields,
      start_date: addDays(today, numberOfDays * (currentPage - 1) - 1).getTime(),
      end_date: addDays(today, numberOfDays * (currentPage - 1) + numberOfDays).getTime(),
    },
    { enabled: syncEvents.isSuccess || syncEvents.isError },
  )
  const profile = useProfile()
  // state
  const [events, setEvents] = useState<EventInfo[]>([])
  const [editEventData, setEditEventData] = useState<UpdateExtendEventBodyDto[]>([])
  const [editableEventPreview, setEditableEventPreview] = useState<EventInfo>(null)

  const eventDetails = useEventById<EventDetails>(
    editableEventPreview?.parentRecurringId ?? editableEventPreview?.id,
    eventDetailsParams,
  )
  // memo
  const isLoading = useMemo(
    () => syncEvents.isLoading || eventList.isLoading || eventList.isRefetching,
    [syncEvents.isLoading, eventList.isLoading, eventList.isRefetching],
  )

  const isEventDetailsLoading = eventDetails.isLoading || eventDetails.isRefetching

  const editableEvent = useMemo<EventData>(() => {
    if (!editableEventPreview || !eventDetails.data) return null

    return { ...eventDetails.data, ...editableEventPreview }
  }, [editableEventPreview, eventDetails.data])

  useEffect(() => {
    if (eventList.isFetched && Array.isArray(eventList.data) && eventList.data.length !== 0) {
      const existingIds = events.map((reminder) => reminder.id)
      const newData = eventList.data.filter((reminder) => !existingIds.includes(reminder.id))

      if (newData.length > 0) {
        setEvents([...events, ...newData])
      }
    }
  }, [eventList.isFetched, eventList.data, events])

  useEffect(() => {
    setNumberOfPages(numberOfPages)
  }, [setNumberOfPages])

  const handleSkipConfiguration = useCallback(() => {
    updateProfile.mutate({ completed_onboarding: true })
    navigate(routeNames.dashboard.path)
  }, [navigate, updateProfile])

  const handleSave = (id: string, data: UpdateExtendEventBodyDto) => {
    setEvents(
      changeArrayElementValues<EventInfo>(id, (event) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { time_before, confirmed, ...rest } = data
        const eventData = { ...event, ...rest }

        if (time_before) {
          eventData.reminders = {
            data: time_before?.map((t) => ({ time_before: t })) || [],
          }
        }

        return eventData
      }),
    )

    setEditEventData((prev) => {
      const newDataIndex = prev.findIndex((item) => item.id === data.id)

      if (newDataIndex !== -1) {
        const updatedPrev = [...prev]

        updatedPrev[newDataIndex] = { ...updatedPrev[newDataIndex], ...data }

        return updatedPrev
      }

      return [...prev, data]
    })
    setEditableEventPreview(null)
  }

  const onEventClick = (eventPreview: EventInfo) => {
    setEditableEventPreview(eventPreview)
  }

  const onSwitchChange = useCallback(
    (event: EventData) => {
      const id = event.parentRecurringId ?? event.id

      setEvents(
        changeArrayElementValues<EventInfo>(id, (reminder) => ({
          ...reminder,
          active: event.active,
        })),
      )

      let time_before = profile.data.default_time_before

      if (event?.reminders?.data?.length > 0) {
        time_before = event.reminders.data.map((r) => r.time_before)
      }

      setEditEventData((prev) => [...prev, { id, active: event.active, time_before }])
    },
    [profile.data],
  )

  const handleFinishSetting = async () => {
    setActiveEvents(events.filter((r) => r.active))
    updateEvents.mutate(editEventData)

    handleNext()
  }

  return (
    <div className={classes.rootWrapper}>
      <SecondaryHeader
        title={<FormattedText id={'configuration.upcomingMeetings.title'} />}
        description={<FormattedText id={'configuration.upcomingMeetings.description'} />}
        className={classes.text}
      />
      <div className={classes.line} />
      {/* TODO: add auto height */}
      <PageWrapper className={classes.content}>
        <Loader isLoading={isLoading} accent full />
        <UpcomingMeetingsList
          isLoading={isLoading}
          events={events}
          onSwitchChange={onSwitchChange}
          onCardClick={onEventClick}
        />
      </PageWrapper>
      <div className={classes.line} />
      <Footer>
        <Pagination />
        <div className={classes.controls}>
          <div className={classes.buttonWrapper}>
            <Button variant="primary" onClick={handleFinishSetting}>
              <FormattedText id={'configuration.upcomingMeetings.button.done'} />
            </Button>
            <div className={classes.skip} onClick={handleSkipConfiguration}>
              <Typography variant="body" className={classes.skipText}>
                <FormattedText id={'configuration.upcomingMeetings.skip'} />
              </Typography>
              <Icon name="arrowRightMedium" />
            </div>
          </div>
          <Typography variant="body">
            <FormattedText id={'configuration.upcomingMeetings.helper'} />
          </Typography>
        </div>
      </Footer>
      {(editableEvent || isEventDetailsLoading) && (
        <EditEventModal
          profileData={profile.data}
          event={editableEvent}
          onClose={() => setEditableEventPreview(null)}
          handleSave={handleSave}
          isLoading={isEventDetailsLoading}
        />
      )}
      <ProfileInfo rootClassName={classes.absoluteWrapper} />
    </div>
  )
}
