import { useCallback } from 'react'
import { NO_SIGN_UP_ERROR, TOKEN_ERROR } from '../constants/errorCodes'

type UseHandleRetryProps = {
  numberOfRetries?: number
  errorCodes?: number[]
}

const DEFAULT_PARAMS = {
  numberOfRetries: 2,
  errorCodes: [TOKEN_ERROR, NO_SIGN_UP_ERROR],
}

export const useHandleRetry = ({
  numberOfRetries = DEFAULT_PARAMS.numberOfRetries,
  errorCodes = DEFAULT_PARAMS.errorCodes,
}: UseHandleRetryProps = DEFAULT_PARAMS) => {
  return useCallback(
    (count, error) => count <= numberOfRetries && !errorCodes.includes(error?.response?.status),
    [numberOfRetries, errorCodes],
  )
}
