// types
import type { BillingPlanDto } from '@grouse-software/types/billing/billing.dto'
import {
  CalendarsLimitType,
  SubscriptionStatus,
} from 'src/modules/payment/types/payment.types'

export const isPlanNotForSale = (plan: BillingPlanDto): boolean =>
  plan?.group_name === 'not_for_sale'

export const formatSubscriptionCancellationDate = (date: string): Date => {
  const cancellationDate = new Date(parseInt(date, 10))

  return new Date(
    cancellationDate.getUTCFullYear(),
    cancellationDate.getUTCMonth(),
    cancellationDate.getUTCDate(),
  )
}

export const isSubscriptionActive = (
  state: string,
  billing_cancellation_effective_date: string,
): boolean =>
  state === SubscriptionStatus.active ||
  state === SubscriptionStatus.trialing ||
  state === SubscriptionStatus.past_due ||
  (state === SubscriptionStatus.deleted &&
    billing_cancellation_effective_date &&
    Date.now() < parseInt(billing_cancellation_effective_date, 10))

export const getCalendarsLimitType = (
  plan: BillingPlanDto,
): CalendarsLimitType => {
  if (!plan) return null

  if (plan.calendars_limit === null && !plan.only_primary_calendar) {
    return CalendarsLimitType.unlimited
  }

  if (plan.only_primary_calendar) {
    return CalendarsLimitType.primary
  }

  return CalendarsLimitType.default
}
