import { useEffect } from 'react'

export const useCrisp = (enabled = true) => {
  useEffect(() => {
    if (enabled) {
      window.$crisp = []
      window.CRISP_WEBSITE_ID = process.env.REACT_APP_CRISP_WEBSITE_ID
      ;(() => {
        const d = document
        const s = d.createElement('script')

        s.src = 'https://client.crisp.chat/l.js'
        s.async = true

        const crispElement = document.querySelector(
          "script[src='https://client.crisp.chat/l.js']",
        )

        if (crispElement === null) {
          d.getElementsByTagName('body')[0].appendChild(s)
        }
      })()
    }
  }, [enabled])
}
