import { FC, useContext, useEffect } from 'react'
// containers
import { SettingsInfoWrapper } from 'src/modules/user/containers'
// context
import { LoadingWrapperContext } from 'src/modules/core/context'

export const Settings: FC = () => {
  // context
  const { disableLoading } = useContext(LoadingWrapperContext)

  useEffect(() => {
    disableLoading()
  }, [disableLoading])

  return <SettingsInfoWrapper />
}
