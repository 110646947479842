import 'react-phone-input-2/lib/style.css'
import classNames from 'classnames'
import { format } from 'date-fns'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { CountryData } from 'react-phone-input-2'
import generateRandomId from 'src/helpers/generate-random-id'
import { confirmationVariants } from 'src/modules/confirmation/constants/confirmation-variants'
import {
  Accordion,
  Button,
  FormattedText,
  FormControl,
  Icon,
  Loader,
  Modal,
  PhoneNumberField,
  SelectField,
  TextField,
  Typography,
} from 'src/modules/core/components'
import { maxMessageLength, maxMessageLengthWithConfirmation } from 'src/modules/event/constants/event.constants'
import { eventEditSchema } from 'src/modules/event/schemas/event-edit.schema'
import {
  filterWithinTimingValues,
  formatEventDate,
  generateEventMessage,
  generateTimingWarningMessage,
} from 'src/modules/event/utils/helpers'
import { ConsumedReminderSegments, CreateReminderHelper, PreviewReminderModal } from 'src/modules/reminder/components'
import { timingOptions } from 'src/modules/user/constants/profile.constants'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box } from '@material-ui/core'
import { EventSuggestedNumbersHelper } from '../../utils/EventSuggestedNumbersHelper'
import classes from './edit-event-modal.module.scss'

import type { UpdateExtendEventBodyDto, EventData } from 'src/modules/event/types/event.types'
import type { EditEventForm, EditEventFormData, EditEventModalProps } from './edit-event-modal.interface'

const MAX_RECEIVER_NUMBERS = 10

export function EditEventModal({
  onClose,
  modalTitle = <FormattedText id="configuration.editReminderModal.title" />,
  isLoading,
  ...props
}: EditEventModalProps) {
  if (isLoading) {
    return (
      <Modal
        onClose={onClose}
        title={
          <Typography variant="h3" className={classes.modalTitle}>
            {modalTitle}
          </Typography>
        }
      >
        <Loader isLoading={isLoading} />
      </Modal>
    )
  }

  return <EditEventModalContent onClose={onClose} modalTitle={modalTitle} {...props} />
}

export function EditEventModalContent({ profileData, event, onClose, handleSave, modalTitle }) {
  let defaultCountryCode = localStorage.getItem('defaultCountryCode') || 'US'
  const eventPhoneNumbers = Array.from(
    new Set([...(event?.receiver_numbers ?? []), ...EventSuggestedNumbersHelper.getPendingSuggestions(event)]),
  ).filter(Boolean)
  const defaultReceiverNumbers = useMemo(
    () => (profileData.usage_mode === 'personal' ? profileData.phone_numbers : eventPhoneNumbers),
    [profileData.usage_mode, profileData.phone_numbers, eventPhoneNumbers],
  )
  if (defaultReceiverNumbers.length === 0) {
    defaultReceiverNumbers.push('')
  }

  // hooks
  const {
    register,
    handleSubmit,
    control,
    watch,
    setError,
    formState: { isDirty, errors, dirtyFields },
  } = useForm<EditEventForm>({
    defaultValues: {
      sender_name: event?.default_sender_name ? profileData?.sender_name : event?.sender_name || '',
      text: event?.default_message_text ? profileData?.default_message_text : event?.text || '',
      location: event?.default_location ? profileData?.default_location : event?.location || '',
      receiver_numbers: defaultReceiverNumbers.map((n) => ({
        id: generateRandomId(),
        countryCode: null,
        phoneNumber: n,
      })),
      time_before: event.reminders?.data?.length
        ? event.reminders?.data?.map((t) => t.time_before.toString())
        : profileData?.default_time_before?.map((t) => t.toString()),
    },
    // @ts-ignore
    resolver: yupResolver(eventEditSchema),
  })
  const [isPreview, setIsPreview] = useState<boolean>(false)
  const [previewReminder, setPreviewReminder] = useState<EventData>(event)

  const { formatMessage } = useIntl()
  const { text } = watch()

  const eventDate = useMemo(() => new Date(parseInt(event.event_date, 10)), [event.event_date])

  const isPastEvent = useMemo(() => new Date() > eventDate, [eventDate])

  const amountOfReceiverNumbers = defaultReceiverNumbers.length || 1

  const maxTextLength = profileData?.appointment_confirmation ? maxMessageLengthWithConfirmation : maxMessageLength

  const eventMessage = useMemo(
    () => generateEventMessage({ ...profileData, appointment_confirmation: false }, { ...previewReminder, text }),
    [previewReminder, profileData, text],
  )

  const handleCloseModal = useCallback(() => {
    setIsPreview(false)
  }, [])

  const {
    fields: phoneNumbers,
    append: addPhoneNumber,
    remove: removePhoneNumber,
  } = useFieldArray({
    control,
    name: 'receiver_numbers',
  })

  function handleAddPhoneNumber() {
    if (phoneNumbers.length < MAX_RECEIVER_NUMBERS) {
      addPhoneNumber({
        id: generateRandomId(),
        countryCode: defaultCountryCode,
        phoneNumber: '',
      })
    }
  }

  useEffect(() => {
    const subscription = watch((value) =>
      setPreviewReminder((prevState) => ({
        ...prevState,
        ...value,
        receiver_numbers: value.receiver_numbers.map((pn) => pn.phoneNumber),
      })),
    )
    return () => subscription.unsubscribe()
  }, [watch])

  const onSubmit = (data: EditEventFormData) => {
    if (isDirty || !data.active) {
      const timingValues = data.time_before?.length
        ? data.time_before.map((time) => +time)
        : profileData?.default_time_before ?? []

      const withinTimingValues = filterWithinTimingValues(eventDate, timingValues)

      if (isPastEvent || withinTimingValues.length) {
        return setError('time_before', {
          type: 'custom',
          message: generateTimingWarningMessage(withinTimingValues),
        })
      }

      if (profileData.appointment_confirmation && eventMessage.length > maxMessageLengthWithConfirmation) {
        return setError('text', {
          type: 'custom',
          message: 'Default SMS Reminder must be shorter than 239 characters',
        })
      }

      if (eventMessage.length > maxMessageLength) {
        return setError('text', {
          type: 'custom',
          message: 'Default SMS Reminder must be shorter than 320 characters',
        })
      }

      const newData: EditEventFormData = {
        id: event.id, // Will either refer to individual event or parent recurring event
      }

      const dirtyKeys = Object.keys(dirtyFields).filter((key) => dirtyFields[key])
      for (const [key, value] of Object.entries(data)) {
        if (dirtyKeys.includes(key)) {
          newData[key] = value
        }
      }

      newData.receiver_numbers = data.receiver_numbers
      newData.time_before = data.time_before.map(Number)
      if (!event.active) newData.active = true

      const { receiver_numbers, ...rest } = newData
      const updateData: UpdateExtendEventBodyDto = rest

      if (newData.receiver_numbers) {
        const receiverNumbers = newData.receiver_numbers.filter((n) => n.phoneNumber && n.phoneNumber !== '+')
        if (receiverNumbers.length) {
          const countryCode = receiverNumbers[receiverNumbers.length - 1].countryCode
          if (countryCode) {
            localStorage.setItem('defaultCountryCode', countryCode)
          }
        }
        updateData.receiver_numbers = receiverNumbers.length ? receiverNumbers.map((rn) => rn.phoneNumber) : null
        updateData.suggestions_reviewed = true
      }

      handleSave(updateData.id, updateData)
      return
    }

    onClose()
  }

  const acceptanceVariant = useMemo(() => {
    if (
      !profileData?.appointment_confirmation ||
      !event.active ||
      event.confirmed === null ||
      (event.confirmed === 'pending' && isPastEvent)
    ) {
      return null
    }

    if (event.confirmed === 'pending') {
      return confirmationVariants.waiting
    }

    if (event.confirmed === 'confirmed') {
      return confirmationVariants.accept
    }

    if (event.confirmed === 'cancelled') {
      return confirmationVariants.reject
    }

    return null
  }, [profileData?.appointment_confirmation, event.active, event.confirmed, isPastEvent])

  return (
    <>
      <Modal
        onClose={onClose}
        title={
          <Typography variant="h3" className={classes.modalTitle}>
            {modalTitle}
          </Typography>
        }
      >
        <form
          className={classNames(classes.root, { [classes.hidden]: isPreview })}
          // @ts-ignore
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className={classes.header}>
            <div>
              <Typography variant="h2" className={classes.title}>
                {event.title || <FormattedText id="reminder.noTitle" />}
              </Typography>
              <Typography variant="body" className={classes.regular}>
                {formatEventDate(eventDate, profileData.google_calendar_date_order)}
                {', '}
                {event.all_day ? <FormattedText id="reminder.allDay" /> : format(eventDate, 'h:mma')}
              </Typography>
              {event.event_location ? (
                <Typography variant="body" className={classes.eventLocation}>
                  {event.event_location}
                </Typography>
              ) : null}
            </div>
            <div className={classes.statusWrapper}>
              {acceptanceVariant && (
                <div
                  className={classNames(classes.statusBadge, {
                    [classes.statusBadgeAccept]: acceptanceVariant === confirmationVariants.accept,
                    [classes.statusBadgeWaiting]: acceptanceVariant === confirmationVariants.waiting,
                    [classes.statusBadgeRejected]: acceptanceVariant === confirmationVariants.reject,
                  })}
                >
                  {acceptanceVariant === 'accept' && (
                    <Typography variant="body" className={classes.statusText}>
                      <FormattedText id="configuration.editReminder.status.accepted" />
                    </Typography>
                  )}
                  {acceptanceVariant === 'waiting' && (
                    <Typography variant="body" className={classes.statusText}>
                      <FormattedText id="configuration.editReminder.status.waiting" />
                    </Typography>
                  )}
                  {acceptanceVariant === 'reject' && (
                    <Typography variant="body" className={classes.statusText}>
                      <FormattedText id="configuration.editReminder.status.rejected" />
                    </Typography>
                  )}
                </div>
              )}
            </div>
          </div>
          {event.errors.length > 0 && (
            <Box display={'flex'} flexDirection={'row'} alignItems={'center'} gridGap={6} marginBottom={2}>
              <Icon name="eventCardError" />
              <span className={classes.error}>{event.errors.join(', ')}</span>
            </Box>
          )}
          {phoneNumbers.map((field, index) => (
            <FormControl
              label={`${formatMessage({
                id: 'configuration.editReminderModal.label.phoneNumber',
              })} ${index + 1}`}
              error={errors.receiver_numbers && errors.receiver_numbers[index]?.phoneNumber?.message}
              className={classes.textFieldWrapper}
              key={field.id}
            >
              <Controller
                control={control}
                name={`receiver_numbers.${index}`}
                defaultValue={field}
                render={(props) => (
                  <Box display="flex" alignItems="center" gridColumnGap={10}>
                    <PhoneNumberField
                      disableDropdown={isPastEvent}
                      disabled={isPastEvent}
                      value={props.field.value.phoneNumber}
                      onChange={(value, country: CountryData) => {
                        props.field.onChange({
                          countryCode: country.countryCode,
                          phoneNumber: `+${value.replace(/\D/g, '')}`,
                        })
                      }}
                      country={defaultCountryCode}
                      error={errors.receiver_numbers && errors.receiver_numbers[index]?.message}
                      inputClass={classNames(classes.phoneNumberInput, {
                        [classes.suggestedPhoneInput]: EventSuggestedNumbersHelper.isSuggestedNumber(
                          event,
                          field.phoneNumber,
                        ),
                      })}
                    />
                    {index > 0 && (
                      <span onClick={() => removePhoneNumber(index)}>
                        <Icon name="close" className={classes.removeButton} />
                      </span>
                    )}
                  </Box>
                )}
              />
            </FormControl>
          ))}
          {amountOfReceiverNumbers < MAX_RECEIVER_NUMBERS && (
            <Typography variant="body" className={classes.textButton} onClick={handleAddPhoneNumber}>
              + <FormattedText id="configuration.editReminderModal.textButton.addPhone" />
            </Typography>
          )}
          <Accordion
            Title={({ isActive }) => (
              <Typography variant="body" className={classes.accordionTitle}>
                {isActive ? (
                  <FormattedText id="configuration.editReminderModal.accordion.active" />
                ) : (
                  <FormattedText id="configuration.editReminderModal.accordion.notActive" />
                )}
                <Icon
                  name="arrowLeft"
                  className={classNames(classes.accordionIcon, {
                    [classes.accordionIconActive]: isActive,
                  })}
                />
              </Typography>
            )}
            body={
              <>
                <TextField
                  disabled={isPastEvent}
                  wrapperClassName={classes.textFieldWrapper}
                  label={formatMessage({
                    id: 'configuration.step1.label.from',
                  })}
                  {...register('sender_name')}
                  error={errors.sender_name?.message}
                />
                <TextField
                  disabled={isPastEvent}
                  wrapperClassName={classes.textFieldWrapper}
                  label="Meeting Location"
                  Element="textarea"
                  {...register('location')}
                  error={errors.location?.message}
                />
                <TextField
                  wrapperClassName={classNames({
                    [classes.textFieldWrapper]: isPastEvent,
                  })}
                  disabled={isPastEvent}
                  label={formatMessage({
                    id: 'configuration.editReminderModal.label.message',
                  })}
                  Element="textarea"
                  rows={3}
                  {...register('text')}
                  error={errors.text?.message}
                >
                  {!isPastEvent && (
                    <ConsumedReminderSegments
                      appointmentConfirmation={profileData.appointment_confirmation}
                      message={eventMessage}
                    />
                  )}
                </TextField>
                <div className={classNames(classes.textWrapper, classes.textFieldWrapper)}>
                  {!isPastEvent && <CreateReminderHelper />}
                  {!!(eventMessage && eventMessage.length) && !isPastEvent && (
                    <Typography
                      className={classNames(classes.sublabel, {
                        [classes.error]: eventMessage.length > maxTextLength,
                      })}
                      variant="span"
                    >
                      <FormattedText
                        id="configuration.step1.sublabel"
                        values={{
                          current: eventMessage.length,
                          max: maxTextLength,
                          strong: (chunks) => <strong>{chunks}</strong>,
                        }}
                      />
                    </Typography>
                  )}
                </div>
                <FormControl
                  label={formatMessage({
                    id: 'configuration.step1.timing.title',
                  })}
                  error={errors?.time_before?.message}
                  className={classNames(classes.textFieldWrapper, {
                    [classes.disabled]: isPastEvent,
                  })}
                >
                  <Controller
                    control={control}
                    defaultValue={[]}
                    name="time_before"
                    render={({ field: { onChange, value, ref } }) => (
                      <SelectField
                        isDisabled={isPastEvent}
                        ref={ref}
                        value={timingOptions?.filter((c) => value.includes(c.value))}
                        onChange={(val) => onChange(val.map((c) => c.value))}
                        options={timingOptions}
                        isMulti
                      />
                    )}
                  />
                </FormControl>
              </>
            }
          />
          <Button type="submit" className={classes.button} widthType="content" disabled={isPastEvent}>
            <FormattedText id="configuration.editReminderModal.button" />
          </Button>
          <Typography variant="body" className={classes.clickableText} onClick={() => setIsPreview(true)}>
            <Icon name="eye" className={classes.eyeIcon} />
            <FormattedText id="configuration.editReminderModal.clickableButton.previewMessage" />
          </Typography>
        </form>
      </Modal>
      {previewReminder && isPreview && (
        <PreviewReminderModal
          message={generateEventMessage(profileData, previewReminder)}
          onClose={handleCloseModal}
          onBack={handleCloseModal}
        />
      )}
    </>
  )
}
