import { createContext } from 'react'

import { PaginationContextValue } from './pagination-context.inerface'

export const PaginationContext = createContext<PaginationContextValue>({
  currentPage: 1,
  numberOfPages: 1,
  nextPage: () => {},
  prevPage: () => {},
  setPage: () => {},
  setNumberOfPages: () => {},
})
