import { oauthScopes } from '../core/constants/oauthScopes'

export function redirectToGoogleAuth(completed_onboarding?: boolean) {
  const codeClient = window.google.accounts.oauth2.initCodeClient({
    client_id: process.env.REACT_APP_CLIENT_ID,
    scope: oauthScopes,
    ux_mode: 'redirect',
    redirect_uri: process.env.REACT_APP_REDIRECT_URL,
    state: encodeURIComponent(JSON.stringify({ completed_onboarding })),
  })
  codeClient.requestCode()
}
