import classNames from 'classnames'
import { FC } from 'react'
import { Typography } from '../typography'

import type { TableProps } from './table.interface'
import classes from './table.module.scss'

export const Table: FC<TableProps> = ({ columns, data }) => {
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        {columns.map((column, i) => (
          <Typography
            key={`${column.value}${i}`}
            variant="span"
            className={classes.column}
            style={{ width: column.width }}
          >
            {column.value}
          </Typography>
        ))}
      </div>
      {data.map((row, i) => (
        <div
          key={`${row}${i}`}
          className={classNames(classes.row, {
            [classes.lastRow]: i === data.length - 1,
          })}
        >
          {Object.keys(row).map((item, index) => (
            <div
              key={`${row[item]}${index}`}
              className={classes.rowItem}
              style={{ width: columns[index].width }}
            >
              <Typography variant="span">{row[item]}</Typography>
            </div>
          ))}
        </div>
      ))}
    </div>
  )
}
