import { useMutation, useQueryClient } from 'react-query'
import { GET_SUBSCRIPTION_CACHE_KEY } from 'src/modules/payment/hooks/use-subscription'
import { GET_PROFILE_CACHE_KEY } from 'src/modules/user/hooks/use-profile'
import { ApiService } from 'src/services/api.service'

export const CANCEL_SUBSCRIPTION_CACHE_KEY = 'cancel-subscription'

export function useCancelSubscription() {
  const queryClient = useQueryClient()
  return useMutation(CANCEL_SUBSCRIPTION_CACHE_KEY, () => ApiService.Billing.cancelSubscription(), {
    onSettled: () => {
      queryClient.invalidateQueries(GET_SUBSCRIPTION_CACHE_KEY)
      queryClient.invalidateQueries(GET_PROFILE_CACHE_KEY)
    },
  })
}
