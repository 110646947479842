import classNames from 'classnames'
import { BaseSyntheticEvent, ChangeEvent, FC, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useFlag } from 'react-tggl-client'
import { createYupResolver } from 'src/helpers/custom-yup-resolver'
import { AppointmentConfirmationSteps } from 'src/modules/confirmation/components'
import { appointmentConfirmationSteps } from 'src/modules/confirmation/constants/confirmation.constants'
import {
  Button,
  FormattedText,
  FormControl,
  SecondaryHeader,
  SelectField,
  TextField,
  ToggleSwitch,
  Typography,
} from 'src/modules/core/components'
import {
  maxMessageLength,
  maxMessageLengthWithConfirmation,
  onReminderMessageInput,
} from 'src/modules/event/constants/event.constants'
import { generateEstimatedEventMessage } from 'src/modules/event/utils/helpers'
import { ConsumedReminderSegments, CreateReminderHelper } from 'src/modules/reminder/components'
import { configurationFieldNames, configurationSchema } from 'src/modules/user/constants/configuration-form'
import { timingOptions } from 'src/modules/user/constants/profile.constants'
import { useOptimisticUpdateProfile } from 'src/modules/user/hooks/use-optimistic-update-profile'
import { useProfile } from 'src/modules/user/hooks/use-profile'
import * as amplitude from '@amplitude/analytics-browser'
import { Box } from '@material-ui/core'
import { SendTestOrFinishScreen } from '../send-test-or-finish'
import classes from './configuration-form.module.scss'

import type { ProfileFormData } from 'src/modules/user/types/profile.types'

export function ConfigurationFormCustomerMode() {
  const { value, loading } = useFlag('calendarEventXNewOnboarding')
  if (loading) return null

  const useNewOnboarding = !!value?.includes('test')
  const blankFields = !value?.includes('populated')

  return <ConfigurationFormInternal newWebAppOnboarding={useNewOnboarding} blankFields={blankFields} />
}

type ConfigurationFormInternalProps = {
  newWebAppOnboarding: boolean
  blankFields: boolean
}

const ConfigurationFormInternal: FC<ConfigurationFormInternalProps> = ({ newWebAppOnboarding, blankFields }) => {
  const profile = useProfile()
  const optimisticUpdateProfile = useOptimisticUpdateProfile()

  const { formatMessage } = useIntl()

  const [sendTestReminder, setSendTestReminder] = useState(false)
  const [showSendTestOrFinish, setShowSendTestOrFinish] = useState(false)

  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors, isSubmitted },
  } = useForm<ProfileFormData>({
    resolver: createYupResolver(configurationSchema, configurationFieldNames),
    defaultValues: {
      from: blankFields ? '' : formatMessage({ id: 'configuration.step1.default.from' }),
      meetingLocation: blankFields
        ? ''
        : formatMessage({
            id: 'configuration.step1.default.location',
          }),
      defaultReminder: blankFields
        ? ''
        : formatMessage({
            id: 'configuration.step1.default.reminder',
          }),
      timing: [],
      appointmentConfirmation: true,
    },
  })

  const { defaultReminder, meetingLocation, from, appointmentConfirmation } = watch()

  const maxDefaultReminderLength = appointmentConfirmation ? maxMessageLengthWithConfirmation : maxMessageLength

  const eventMessage = useMemo(
    () =>
      generateEstimatedEventMessage({
        ...profile.data,
        appointment_confirmation: false,
        sender_name: from,
        default_location: meetingLocation,
        default_message_text: defaultReminder,
      }),
    [defaultReminder, from, meetingLocation, profile.data],
  )

  function handleSendTestReminderClick() {
    amplitude.track('Onboarding_SendTestReminder_button')
    setSendTestReminder(true)
  }

  function handleSkipAndFinishClick() {
    amplitude.track('Onboarding_SkipandFinish_button')
    setSendTestReminder(false)
  }

  const onSubmit = (data: ProfileFormData, event: BaseSyntheticEvent) => {
    event.preventDefault()

    optimisticUpdateProfile.mutate(
      {
        default_location: data.meetingLocation,
        default_message_text: data.defaultReminder,
        sender_name: data.from,
        appointment_confirmation: data.appointmentConfirmation,
        default_time_before: data.timing.map((time) => +time),
      },
      {
        onSuccess: () => setShowSendTestOrFinish(true),
      },
    )
  }

  return (
    <>
      <Box className="translate">
        <SecondaryHeader
          className={classes.text}
          title={<FormattedText id="configuration.step1.customerMode.title" />}
          description={<FormattedText id="configuration.step1.customerMode.description" />}
        />
        <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
          <div className={classes.wrapper}>
            <TextField
              wrapperClassName={classes.textFieldWrapper}
              label={formatMessage({ id: 'configuration.step1.label.from' })}
              placeholder={formatMessage({
                id: 'configuration.step1.placeholder.from',
              })}
              {...register('from')}
              error={errors.from?.message}
            />
            <TextField
              wrapperClassName={classes.textFieldWrapper}
              label={formatMessage({
                id: 'configuration.step1.label.location',
              })}
              Element="textarea"
              rows={3}
              placeholder={formatMessage({
                id: 'configuration.step1.placeholder.location',
              })}
              {...register('meetingLocation')}
              error={errors.meetingLocation?.message}
            />
            <TextField
              label={formatMessage({
                id: 'configuration.step1.label.reminder',
              })}
              Element="textarea"
              rows={4}
              placeholder={formatMessage({
                id: 'configuration.step1.placeholder.reminder',
              })}
              {...register('defaultReminder')}
              onInput={(event: ChangeEvent<HTMLTextAreaElement>) =>
                onReminderMessageInput(event, maxDefaultReminderLength)
              }
              error={errors.defaultReminder?.message}
              className={classNames({
                [classes.error]: eventMessage.length > maxDefaultReminderLength,
              })}
            >
              <ConsumedReminderSegments
                appointmentConfirmation={appointmentConfirmation}
                message={eventMessage}
                isEstimate
              />
            </TextField>
            <div className={classNames(classes.textWrapper, classes.textFieldWrapper)}>
              <CreateReminderHelper />
              {!!(eventMessage && eventMessage.length) && (
                <Typography
                  className={classNames(classes.sublabel, {
                    [classes.error]: eventMessage.length > maxDefaultReminderLength,
                    [classes.larger]: eventMessage.length > maxDefaultReminderLength,
                  })}
                  variant="h5"
                >
                  <FormattedText
                    id="configuration.step1.sublabel"
                    values={{
                      current: eventMessage.length,
                      max: maxDefaultReminderLength,
                      strong: (chunks) => <strong>{chunks}</strong>,
                    }}
                  />
                </Typography>
              )}
            </div>
            <FormControl
              label={formatMessage({ id: 'configuration.step1.timing.title' })}
              error={errors.timing?.message}
            >
              <Controller
                control={control}
                defaultValue={[]}
                name="timing"
                render={({ field: { onChange, value, ref } }) => (
                  <SelectField
                    ref={ref}
                    value={timingOptions.filter((c) => value.includes(c.value))}
                    onChange={(val) => onChange(val.map((c) => c.value))}
                    options={timingOptions}
                    isMulti
                  />
                )}
              />
            </FormControl>

            <div className={classes.confirmationWrapper}>
              <Typography variant="body">
                <FormattedText id="configuration.step1.confirmation.title" />
              </Typography>
              <AppointmentConfirmationSteps steps={appointmentConfirmationSteps} />
              <Box display="flex" alignItems="center" marginTop={5}>
                <Box marginRight={2}>
                  <ToggleSwitch
                    checked={appointmentConfirmation}
                    labelClassName={classes.switchLabel}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setValue('appointmentConfirmation', e.target.checked)
                    }
                  />
                </Box>
                <Typography variant="body">
                  <FormattedText id="configuration.step1.confirmation.label" />
                </Typography>
              </Box>
            </div>
          </div>

          {isSubmitted && Object.keys(errors).length > 0 && (
            <Box marginTop={5} className={classes.error}>
              Review the errors above to proceed
            </Box>
          )}

          {newWebAppOnboarding ? (
            <Box display="flex" gridGap={20} className="notranslate">
              <Button
                type="submit"
                className={classes.button}
                widthType="content"
                isLoading={optimisticUpdateProfile.isLoading}
                onClick={handleSendTestReminderClick}
              >
                <div className="translate">
                  <FormattedText id="Send a Test Reminder" />
                </div>
              </Button>
              <Button
                type="submit"
                widthType="content"
                isLoading={optimisticUpdateProfile.isLoading}
                onClick={handleSkipAndFinishClick}
                variant="secondary"
              >
                <div className="translate">
                  <FormattedText id="Skip & Finish My Account" />
                </div>
              </Button>
            </Box>
          ) : (
            <Button
              type="submit"
              className={classes.button}
              widthType="content"
              isLoading={optimisticUpdateProfile.isLoading}
            >
              <FormattedText id="common.saveAndContinue" />
            </Button>
          )}
        </form>
      </Box>
      <SendTestOrFinishScreen active={showSendTestOrFinish} sendTestReminder={sendTestReminder} />
    </>
  )
}
