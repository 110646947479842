import { makeRequest } from 'src/helpers/request'

import type { ConfirmEventBodyDto } from '@grouse-software/types/event/event.api'

export const ConfirmationService = {
  confirmEvent: (params: ConfirmEventBodyDto) => {
    return makeRequest<ConfirmEventBodyDto>(`${process.env.REACT_APP_API_URL}/events/confirmation`, 'post', params)
  },

  getEventByUrlCode: (urlCode: string) => {
    return makeRequest<ConfirmEventBodyDto>(`${process.env.REACT_APP_API_URL}/confirmation_details/${urlCode}`, 'get')
  },
}
