import { AnalyticsService } from './analytics.service'
import { AuthService } from './auth.service'
import { BillingService } from './billing.service'
import { CalendarsService } from './calendars.service'
import { ConfirmationService } from './confirmation.service'
import { EventsService } from './events.service'
import { ProfileService } from './profile.service'
import { RemindersService } from './reminders.service'
import { TestRemindersService } from './test-reminders.service'
import { UserEventsService } from './user-events.service'

export type ServerResponse<T> = {
  data: T
}

export const ApiService = {
  Analytics: AnalyticsService,
  Auth: AuthService,
  Billing: BillingService,
  Calendars: CalendarsService,
  Confirmation: ConfirmationService,
  Events: EventsService,
  Profile: ProfileService,
  Reminders: RemindersService,
  TestReminders: TestRemindersService,
  UserEvents: UserEventsService,
}
