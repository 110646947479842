import { AxiosInstance } from 'axios'
import { TokenService } from 'src/services/token.service'

export function axiosRefreshToken(client: AxiosInstance) {
  const tokenService = TokenService.getInstance()

  let isRefreshing = false
  let refreshSubscribers: Array<(token: string) => void> = []
  let _400count = 0
  const max400count = 5

  function onTokenRefreshed(token: string) {
    refreshSubscribers.forEach((callback) => callback(token))
    refreshSubscribers = []
  }

  function addRefreshSubscriber(callback: (token: string) => void) {
    refreshSubscribers.push(callback)
  }

  client.interceptors.response.use(
    (response) => {
      _400count = 0 // Reset count on success
      return response
    },
    async (error) => {
      if (error.response?.status === 400) {
        _400count++
        if (_400count < max400count) {
          return Promise.reject(error)
        }
      }

      if (error.response?.status === 401 || (error.response?.status === 400 && _400count >= max400count)) {
        if (!isRefreshing) {
          isRefreshing = true
          try {
            await tokenService.refreshAccessToken()
            const newToken = tokenService.getToken().value
            isRefreshing = false

            if (newToken) {
              onTokenRefreshed(newToken)
              error.config.headers.authorization = `Bearer ${newToken}`
              return client.request(error.config)
            }
          } catch (refreshError) {
            isRefreshing = false
            tokenService.removeToken()
            return Promise.reject(refreshError)
          }
        }

        return new Promise((resolve, _reject) => {
          addRefreshSubscriber((newToken) => {
            error.config.headers.authorization = `Bearer ${newToken}`
            resolve(client.request(error.config))
          })
        })
      }

      return Promise.reject(error)
    },
  )
}
