import { FC, useContext, useMemo } from 'react'
import { Stepper } from 'src/modules/core/components'
import { ConfigurationStepContext } from 'src/modules/core/context/configuration-step-context'
import { FirstConfigurationScreen } from '../../components'
import { ChooseModeScreen } from '../../components/choose-mode'
import classes from './configuration-wrapper.module.scss'

export const ConfigurationWrapper: FC = () => {
  const steps = useMemo(
    () => [
      ChooseModeScreen,
      FirstConfigurationScreen,
      // SetupReminderScreen,
      // CongratulationsScreen,
    ],
    [],
  )

  const { step: currentStep } = useContext(ConfigurationStepContext)
  const Step = useMemo(() => steps[currentStep - 1], [steps, currentStep])

  return (
    <div className="notranslate">
      <Stepper className={classes.stepper} numberOfSteps={steps.length} activeStep={currentStep} />
      <Step />
    </div>
  )
}
