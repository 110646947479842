import { FC } from 'react'
import type { SettingsProfileInfoFormProps } from './settings-profile-info-form.interface'
import { SettingsProfileInfoFormPersonalMode } from './settings-profile-info-form-personal-mode'
import { SettingsProfileInfoFormCustomerMode } from './settings-profile-info-form-customer-mode'
import { useProfile } from '../../hooks/use-profile'

export const SettingsProfileInfoForm: FC<SettingsProfileInfoFormProps> = ({
  profileInfo,
  setIsProfileInfoEditing,
}) => {
  const { isLoading, data } = useProfile()

  if (isLoading) return null

  return data.usage_mode === 'personal' ? (
    <SettingsProfileInfoFormPersonalMode
      profileInfo={profileInfo}
      setIsProfileInfoEditing={setIsProfileInfoEditing}
    />
  ) : (
    <SettingsProfileInfoFormCustomerMode
      profileInfo={profileInfo}
      setIsProfileInfoEditing={setIsProfileInfoEditing}
    />
  )
}
