import { useEffect, useState } from 'react'
import { FormattedText, Loader, SecondaryHeader } from 'src/modules/core/components'
import { ProfileInfo } from 'src/modules/user/components'
import { useProfile } from 'src/modules/user/hooks/use-profile'
import { Box, Container } from '@material-ui/core'
import { useTrack } from '../../hooks/use-track'
import { ChooseModeForm } from './choose-mode-form'
import classes from './choose-mode-screen.module.scss'

export function ChooseModeScreen() {
  const profile = useProfile()
  const track = useTrack()
  const [hasTrackedEvent, setHasTrackedEvent] = useState(false)

  useEffect(() => {
    if (!profile.isLoading && profile.data) {
      if (!hasTrackedEvent) {
        track.mutate('onboarding_start')
        setHasTrackedEvent(true)
      }
    }
  }, [profile.isLoading, profile.data, hasTrackedEvent, track])

  if (profile.isLoading) {
    return <Loader isLoading={profile.isLoading} text={<FormattedText id="configuration.profileLoadingText" />} full />
  }

  return (
    <Container maxWidth="lg">
      <Box marginTop={6}>
        <SecondaryHeader
          className={classes.text}
          title={<FormattedText id="chooseMode.title" />}
          description={<FormattedText id="chooseMode.description" />}
        />
        <ChooseModeForm />
        <ProfileInfo rootClassName={classes.absoluteWrapper} />
      </Box>
    </Container>
  )
}
