import { FC, memo } from 'react'
import classNames from 'classnames'

import type { ButtonProps } from './button.interface'
import classes from './button.module.scss'

const Loader = memo(() => (
  <div className={classes.loaderWrapper}>
    <div className={classes.loader}>Loading...</div>
  </div>
))

Loader.displayName = 'Loader'

export const Button: FC<ButtonProps> = memo(
  ({
    variant = 'primary',
    widthType,
    children,
    className,
    isLoading,
    ...props
  }) => {
    return (
      <button
        className={classNames(
          classes.root,
          {
            [classes.primary]: variant === 'primary',
            [classes.secondary]: variant === 'secondary',
            [classes.danger]: variant === 'danger',
            [classes.withBorder]: variant === 'withBorder',
            [classes.content]: widthType === 'content',
            [classes.contentCenter]: isLoading,
          },
          className,
        )}
        {...props}
      >
        {isLoading ? <Loader /> : children}
      </button>
    )
  },
)

Button.displayName = 'Button'
