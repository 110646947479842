import classNames from 'classnames'
import { FC } from 'react'
// components
import { Button, Modal, Typography } from 'src/modules/core/components'

import type { InfoModalProps } from './info-modal.interface'
import classes from './info-modal.module.scss'

export const InfoModal: FC<InfoModalProps> = ({
  title,
  description,
  buttonClassName,
  closeButton,
  button,
  onClick,
  onClose,
}) => {
  return (
    <Modal
      onClose={onClose}
      title={
        <Typography variant="h3" className={classes.title}>
          {title}
        </Typography>
      }
      isCentered
      className={classes.modal}
      contentClassName={classes.content}
    >
      <Typography variant="body">{description}</Typography>
      <div className={classNames(classes.buttons, buttonClassName)}>
        {closeButton && (
          <Button variant="withBorder" onClick={onClose}>
            {closeButton}
          </Button>
        )}
        <Button onClick={onClick}>{button}</Button>
      </div>
    </Modal>
  )
}
