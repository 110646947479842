import { BehaviorSubject } from 'rxjs'
import { AuthService } from './auth.service'

import type { ChangelessBehaviorSubject } from 'src/modules/core/types/rxjs.types'

export class TokenService {
  private static instance: TokenService

  private readonly token = new BehaviorSubject<string | null>(null)
  private refreshToken: string | null = null

  private constructor() {
    const token = localStorage.getItem('token')
    if (token) this.token.next(token)

    const refreshToken = localStorage.getItem('refresh_token')
    if (refreshToken) this.refreshToken = refreshToken
  }

  public static getInstance(): TokenService {
    if (!TokenService.instance) {
      TokenService.instance = new TokenService()
    }
    return TokenService.instance
  }

  public getToken(): ChangelessBehaviorSubject<string | null> {
    return this.token as ChangelessBehaviorSubject<string | null>
  }

  public setToken(token: string, refreshToken?: string): void {
    console.debug('TokenService.setToken', token.slice(-10))
    localStorage.setItem('token', token)
    this.token.next(token)

    if (refreshToken) {
      localStorage.setItem('refresh_token', refreshToken)
      this.refreshToken = refreshToken
    } else {
      localStorage.removeItem('refresh_token')
      this.refreshToken = null
    }
  }

  public async refreshAccessToken(): Promise<void> {
    if (!this.refreshToken) {
      console.error('TokenService.refreshAccessToken: refreshToken is not set')
      this.removeToken()
      return
    }

    try {
      const tokens = await AuthService.refreshToken(this.refreshToken)
      this.setToken(tokens.access_token, tokens.refresh_token)
    } catch (error) {
      console.error('Failed to refresh access token: ', error)
      this.removeToken()
    }
  }

  public removeToken(): void {
    debugger
    localStorage.removeItem('refresh_token')
    localStorage.removeItem('token')
    this.refreshToken = null
    this.token.next(null)
  }
}
