import { FC } from 'react'
import classNames from 'classnames'

import type { LoaderProps } from './loader.interface'
import classes from './loader.module.scss'

export const Loader: FC<LoaderProps> = ({
  isLoading,
  className,
  text,
  full = false,
  accent = false,
}) => {
  return (
    <>
      <div
        className={classNames(classes.loaderWrapper, className, {
          [classes.loaderWrapperFull]: full,
          [classes.loaderWrapperAccent]: accent,
          [classes.hidden]: !isLoading,
        })}
      >
        <div className={classes.text}>{text}</div>
        <div className={classes.loader} />
      </div>
    </>
  )
}
