import { FC, useMemo } from 'react'
import Tooltip from 'rc-tooltip'
import { SegmentedMessage } from 'sms-segments-calculator'
import classNames from 'classnames'
// components
import { FormattedText, Icon, Typography } from 'src/modules/core/components'
// constants
import { exampleConfirmationMessage } from 'src/modules/event/constants/event.constants'

import classes from './consumed-reminder-segments.module.scss'
import type { ConsumedReminderSegmentsProps } from './consumed-reminder-segments.interface'

const offsetRight = 0
const offsetBottom = -20

export const ConsumedReminderSegments: FC<ConsumedReminderSegmentsProps> = ({
  message,
  isEstimate,
  appointmentConfirmation,
  textClassName,
}) => {
  // memo
  const segmentsCount = useMemo(
    () =>
      new SegmentedMessage(
        appointmentConfirmation
          ? message.concat(exampleConfirmationMessage)
          : message,
      ).segmentsCount,
    [appointmentConfirmation, message],
  )

  return (
    <div className={classes.root}>
      <Typography
        className={classNames(classes.text, textClassName)}
        variant="span"
      >
        <FormattedText
          id="configuration.editReminderModal.text.remindersConsumed"
          values={{
            isEstimate,
            segmentsCount,
            strong: (chunks) => <strong>{chunks}</strong>,
          }}
        />
      </Typography>
      <Tooltip
        placement="top"
        mouseEnterDelay={0.1}
        transitionName={'example'}
        align={{ offset: [offsetRight, offsetBottom] }}
        overlayClassName={classes.overlay}
        overlay={
          <Typography variant="body" className={classes.bodyText}>
            <FormattedText
              id={
                isEstimate
                  ? 'configuration.editReminderModal.tooltip.estimateRemindersConsumed'
                  : 'configuration.editReminderModal.tooltip.remindersConsumed'
              }
              values={{
                link: (chunks) => (
                  <a
                    href="https://smsreminder.app/#calculate-reminders"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {chunks}
                  </a>
                ),
              }}
            />
          </Typography>
        }
      >
        <Icon name="questionMarkPrimary" />
      </Tooltip>
    </div>
  )
}
