import { FC } from 'react'
// components
import { Icon, Typography } from 'src/modules/core/components'

import classes from './message.module.scss'
import type { MessageProps } from './message.interface'

// TODO: delete canceled variant
export const Message: FC<MessageProps> = ({ variant, message, onClose }) => {
  return (
    <div>
      <div className={classes.messageWrapper}>
        {onClose && (
          <Icon name="close" className={classes.close} onClick={onClose} />
        )}
        <div className={classes.successMark}>
          {variant === 'success' && (
            <Icon name="checkmark" className={classes.checkIcon} />
          )}
          {(variant === 'canceled' || variant === 'error') && (
            <Icon name="calendarCanceled" />
          )}
        </div>
        <Typography variant="body" className={classes.text}>
          {message}
        </Typography>
      </div>
    </div>
  )
}
