import { FC, useContext, useEffect } from 'react'
// containers
import { ConfigurationWrapper } from 'src/modules/user/containers'
// providers
import {
  ConfigurationStepProvider,
  PaginationProvider,
} from 'src/modules/core/providers'
import { EventsProvider } from 'src/modules/event/providers'
// context
import { LoadingWrapperContext } from 'src/modules/core/context'

export const Configuration: FC = () => {
  // context
  const { disableLoading } = useContext(LoadingWrapperContext)

  useEffect(() => {
    disableLoading()
  }, [disableLoading])

  return (
    <ConfigurationStepProvider>
      <PaginationProvider>
        <EventsProvider>
          <ConfigurationWrapper />
        </EventsProvider>
      </PaginationProvider>
    </ConfigurationStepProvider>
  )
}
