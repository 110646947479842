import { createQueryParams, makeRequest } from 'src/helpers/request'
import { UserDto } from 'src/helpers/user.dto'

import type { UpdateUserBodyDto } from '@grouse-software/types/user/user.api'
import type { IDataOptions } from '@grouse-software/types/common'
import type { GoogleProfileInfo } from 'src/modules/user/types/profile.types'

export const ProfileService = {
  syncProfile: async (): Promise<void> => {
    return makeRequest(`${process.env.REACT_APP_API_URL}/profile/sync`, 'post')
  },

  updateProfile: (params: UpdateUserBodyDto): Promise<UserDto> => {
    return makeRequest<UpdateUserBodyDto>(`${process.env.REACT_APP_API_URL}/profile`, 'patch', params)
  },

  getProfile: (params: IDataOptions): Promise<UserDto> => {
    const queryParams = createQueryParams(params)
    return makeRequest(`${process.env.REACT_APP_API_URL}/profile${queryParams}`, 'get')
  },

  getGoogleProfile: (): Promise<GoogleProfileInfo> => {
    return makeRequest('https://www.googleapis.com/oauth2/v1/userinfo?alt=json', 'get')
  },
}
